import {Injectable, isDevMode} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

import {Observable, ReplaySubject, Subject} from 'rxjs';

import {ApiService} from 'app/_service/backend/api.service';
import {UserService} from '../user.service';
import {DataService} from '../backend/data.service';
import {catchError, map} from 'rxjs/operators';
import { FileService } from '../file.service';


@Injectable()
export class MusicianProfileService {

  private userProfile: any;
  private _cacheObject = {};
  private _repertoire: object;
  private _profession: any[];
  private userProfileSubj = new Subject();
  public profile$ = this.userProfileSubj.asObservable();
  private progressSubj = new Subject();
  public progressUpdate$ = this.progressSubj.asObservable();

  constructor(
    private router: Router,
    private http: HttpClient,
    private api: ApiService,
    private fileService: FileService,
    private userService: UserService,
    private _ds: DataService
  ) {
    this.userService.loginLogout$.subscribe(() => {
      this.resetCache();
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
  }

  public getValueProgressbar() {
    this.progressSubj.next(true);
  }

  public getProfileId(): Observable<any> {
    let subject = new ReplaySubject(1);
    if (!this.userProfile) {
      this.getProfile().subscribe((profile) => {
        subject.next(profile.id);
        subject.complete();
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      });
    } else {
      subject.next(this.userProfile.id);
    }
    return subject;
  }

  public resetCache() {
    this.userProfile = null;
    this._cacheObject = {};
    this._repertoire = null;
    this._profession = null;
  }

  public getProfile(force: boolean = false): Observable<any> {
    if (!this.userProfile || force) {
      this.api.get(`/api/profile`).subscribe(data => {
          this.userProfile = data;
          this.userProfileSubj.next(this.userProfile);
        },
        err => {
          this.userProfileSubj.error(err);
          if (err.status === 401) {
            this.userService.logout();
          } else {
            this.userService.reset();
          }
        });
    } else {
      setTimeout(() => {
        this.userProfileSubj.next(this.userProfile);
      }, 5);
    }
    return this.userProfileSubj;
  }

  // -----------
  public patchFieldsProfile(profileField): Observable<any> {
    let subject = new ReplaySubject(1);
    this.api.patch('/api/profile', profileField, {responseType: 'text'})
      .subscribe(value => {
          Object.assign(this.userProfile, profileField);
          subject.next(value);
          subject.complete();
          this.getValueProgressbar();
        },
        err => {
          subject.error(err);
          subject.complete();
          if (err.status === 401) {
            this.userService.logout();
          }
        });
    return subject;
  }

// ----- Professional data ----
  public getData(objectName, parentId?, cached = true): Observable<any> {
    const cacheObjectName = !!parentId ? `${objectName}_${parentId}` : objectName;
    let cacheObject = this._cacheObject[cacheObjectName];
    if (Array.isArray(cacheObject) && cached) {
      return Observable.create(observer => {
        observer.next(cacheObject);
        observer.complete();
      });
    }
    return Observable.create(observer => {
      const path = !!parentId ? `/api/object/${objectName}/${parentId}` : `/api/object/${objectName}`;
      this.api.get(path)
        .subscribe((data: any) => {
            if (!Array.isArray(data)) {
              return observer.error({});
            }
            //const cacheObjectName = !!parentId ? `${objectName}_${parentId}` : objectName;
            if (!Array.isArray(this._cacheObject[cacheObjectName])) {
              this._cacheObject[cacheObjectName] = [];
            }
            this._cacheObject[cacheObjectName] = data;
            observer.next(this._cacheObject[cacheObjectName]);
            observer.complete();
          },
          err => {
            observer.error(err);
            if (isDevMode()) {
              console.log(err);
            }
            if (err.status === 401) {
              this.userService.logout();
            }
          });
    });
  }

  public createData(objectName, reqParams, parentId?): Observable<any> {
    const cacheObjectName = !!parentId ? `${objectName}_${parentId}` : objectName;
    if (!Array.isArray(this._cacheObject[cacheObjectName])) {
      this._cacheObject[cacheObjectName] = [];
    }
    const path = !!parentId ? `/api/object/${objectName}/${parentId}` : `/api/object/${objectName}`;
    return this.api.post(path, reqParams)
      .pipe(
        map((response: any) => {
          this.getValueProgressbar();
          const profession = {...reqParams, id: response.id};
          this._cacheObject[cacheObjectName].push(profession);
          this.getValueProgressbar();
          return profession;
        }),
        catchError(err => {
          if (err.status === 401) {
            this.userService.logout();
          }
          throw err;
        }));
  }

  public updateData(objectName, reqParams, parentId?): Observable<any> {
    return this.api.patch(`/api/object/${objectName}`, reqParams)
      .pipe(
        map(() => {
          this.getValueProgressbar();
          const cacheObjectName = !!parentId ? `${objectName}_${parentId}` : objectName;
          if (!Array.isArray(this._cacheObject[cacheObjectName])) {
            this._cacheObject[cacheObjectName] = [];
          }
          this._cacheObject[cacheObjectName] = this._cacheObject[cacheObjectName].map(item => {
            if (item.id === reqParams.id) {
              return reqParams;
            }
            ;
            return item;
          });
          this.getValueProgressbar();
          return true;
        }),
        catchError(err => {
          if (err.status === 401) {
            this.userService.logout();
          }
          throw err;
        })
      );
  }

  public deleteData(objectName, id, parentId?): Observable<any> {
    return this.api.delete(`/api/object/${objectName}/${id}`)
      .pipe(
        map(() => {
          this.getValueProgressbar();
          const cacheObjectName = !!parentId ? `${objectName}_${parentId}` : objectName;
          const index = this._cacheObject[cacheObjectName].findIndex(item => {
            return item.id === id;
          });
          this._cacheObject[cacheObjectName].splice(index, 1);
          this.getValueProgressbar();
          return this._cacheObject[cacheObjectName];
        }),
        catchError(err => {
          if (err.status === 401) {
            this.userService.logout();
          }
          throw err;
        })
      );
  }

  public getAllOffers(status): Observable<any> {
    return this.userService.action(`offersall/${status}`)
      .pipe(
        map((offers) => {
          this._cacheObject['offers'] = offers.musician;
          this._cacheObject['coll_offers'] = offers.collective;
          return offers;
        })
      );
  }

  public getOffer(id, force?): Observable<any> {
    return Observable.create(observer => {
      if (!Array.isArray(this._cacheObject['offers'])) {
        this._cacheObject['offers'] = [];
      }
      const offers = this._cacheObject['offers'];
      const offer = offers.find(item => {
        return +item.id === id
      });
      if (offer && !force) {
        observer.next(offer);
        observer.complete();
        return;
      } else {
        this.api.get(`api/object/offer/${id}`).subscribe((offerRes: any) => {
          offers.length > 0 ? offers.map(item => {
            return item.id === offerRes.id ? offerRes : item
          }) : offers.push(offerRes);
          observer.next(offerRes);
          observer.complete();
          return;
        })
      }
    });
  }

  public getCollOffer(id, force?): Observable<any> {
    return Observable.create(observer => {
      if (!Array.isArray(this._cacheObject['coll_offers'])) {
        this._cacheObject['coll_offers'] = [];
      }
      const offers = this._cacheObject['coll_offers'];
      const offer = offers.find(item => {
        return +item.id === id
      });
      if (offer && !force) {
        observer.next(offer);
        observer.complete();
        return;
      } else {
        this.api.get(`api/object/coll_offers/${id}`).subscribe((offerRes: any) => {
          offers.length > 0 ? offers.map(item => {
            return item.id === offerRes.id ? offerRes : item
          }) : offers.push(offerRes);
          observer.next(offerRes);
          observer.complete();
          return;
        })
      }
    });
  }

}
