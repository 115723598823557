<div class="content" [hidden]="!initialized">
<section class="preview"><img src="../../assets/images/homepage_min.jpg" (load)="loaded()">
  <div class="container preview_container_position">
    <div class="row">
      <div class="col-xl-6 preview__text ml-auto text-right">
        <h1>{{sos}}</h1>
        <p>{{mainText}}</p>
        <div class="preview__text-btns"  *ngIf="!auth">
          <a class="preview__text-link"  [routerLink]="['/how-it-works']">{{'MENU.HOW-IT-WORKS' | translate }}</a>
          <a class="preview__text-registr" [routerLink]="['/signup']">{{'SIGN_IN.GET_STARTED' | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="subinfo container">
  <div class="row justify-content-center">
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center subinfo__item">
      <div class="subinfo__img"><img src="assets/img/main_image/subtext_image.jpg"/>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 subinfo__item">
      <div class="subinfo__caption">
        <h2>{{secondLabel}}</h2>
        <p>{{secondText}}</p>
      </div>
    </div>
  </div>
</section>
<section class="user-roles">
  <div class="row row_user-roles">
    <div class="col-md-12 col-xl-4 user-roles__item">
      <div class="user-roles__img"><img src="assets/img/role/artist.png"/>
      </div>
      <div class="user-roles__text">
        <h3>{{artistText}}</h3>
        <p *ngFor="let artistText of artist">{{artistText}}</p>
      </div>
    </div>
    <div class="col-lg-12 col-xl-4 user-roles__item">
      <div class="user-roles__img"><img src="assets/img/role/organiser.png"/>
      </div>
      <div class="user-roles__text">
        <h3>{{organizationText}}</h3>
        <p *ngFor="let organizationText of organization">{{organizationText}}</p>
      </div>
    </div>
    <div class="col-lg-12 col-xl-4 user-roles__item">
      <div class="user-roles__img"><img src="assets/img/role/music_service.png"/>
      </div>
      <div class="user-roles__text">
        <h3>{{musicServiceText}}</h3>
        <p *ngFor="let musicServiceText of musicService">{{musicServiceText}}</p>
      </div>
    </div>
  </div>
</section>
<app-contacts [isMap]="false"></app-contacts>
</div>
