import { Component, OnInit, Input, Output, EventEmitter,
  ChangeDetectionStrategy, AfterContentInit, ElementRef, ViewChild} from '@angular/core';
import {  DomSanitizer , SafeResourceUrl } from '@angular/platform-browser';

import {CommonService} from 'app/_service/common.service';
import {VideoService} from './video.service';
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit, AfterContentInit {
  @Input('videoId') videoId: string;
  @Input('onlyRead') onlyRead: boolean = false;
  @Output() close = new EventEmitter();
  /*@internal*/
  @ViewChild('YPC', { static: true }) public ytPlayerContainer: ElementRef;
  player: any;
  constructor(
    private  sanitizer: DomSanitizer,
    private elementRef: ElementRef,
    private cm: CommonService,
    public vs: VideoService
  ) { }

  ngOnInit() {
    this.vs.conectYTapi();
  }
  getId(url: string): string {
    let returnUrl = '';
    if (typeof url !== 'string' ) {
      return returnUrl;
    }
    if (url.indexOf('https://www.youtube.com/watch?v=') > -1) {
      return url.replace('https://www.youtube.com/watch?v=', '');
    }
    if (url.indexOf('https://youtu.be/') > -1) {
      return url.replace('https://youtu.be/', '');
    }
  }

  ngAfterContentInit () {
    const htmlId = this.cm.guid();
    this.ytPlayerContainer.nativeElement.setAttribute('id', htmlId);
    this.vs.createPlayer(htmlId, this.getId(this.videoId)).subscribe(player => {
      this.player = player;
    });
  }
  stop() {
    if (this.player.B) {
      this.player.stopVideo();
    }
  }
  play() {
    if (this.player.B) {
      this.player.playVideo();
    }
  }
  pause() {
    if (this.player.B) {
      this.player.pauseVideo();
    }
  }
  closeEvent() {
    this.close.emit();
  }
}
