import { Component, OnInit, isDevMode, ViewChild } from '@angular/core';
import { CommonService, isString } from 'app/_service/common.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { concat, Observable, ReplaySubject} from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DictionaryService } from 'app/_service/dictionary.service';
import { TITLE_DICT } from 'app/constants';
import { TranslateService } from '@ngx-translate/core';
import { AlertComponent } from '../shared/shared.module';
import { CanComponentDeactivate } from 'app/app-routing/guard-save';
import { UserService } from 'app/_service/user.service';
import { ToursSearchService } from './tours-search.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
@Component({
  selector: 'app-tour-calendar',
  templateUrl: './tour-calendar.component.html',
  styleUrls: ['./tour-calendar.component.scss']
})
export class TourCalendarComponent implements OnInit{
  @ViewChild('alert', { static: true }) private alert: AlertComponent;
  isCollapsed = false;
  toursArray = [];
  isButtonPushed = false;
  shouldCheck = false;
  searchFrom: FormControl = this.fb.control('', Validators.required);
  searchTo: FormControl = this.fb.control('', Validators.required);
  distance: FormControl = this.fb.control(200);
  professionsNames: {};
  specializationsNames: {};
  titleDict = TITLE_DICT;
  stylesNames: {};
  collectiveTypeNames: {};
  categoryDicNames: {};
  categoryDic: [any];
  currentLang = this.ts.currentLang;

  filterForm: FormGroup = this.fb.group({
    searchFrom: this.searchFrom,
    searchTo: this.searchTo,
    distance: this.distance
  });

  minDate = this.cs.getCurrentDate();
  time = { hour: 23, minute: 59, second: 0 };

  constructor(
    public cs: CommonService,
    private ds: DictionaryService,
    private tss: ToursSearchService,
    public fb: FormBuilder,
    private router: Router,
    private ts: TranslateService,
    private us:UserService
  ) { }

  ngOnInit() {
    this.cs.togleSpiter(false);
    this.tss.searchToursResultList$.subscribe((users: any) => {

      if (!users) {
        return;
      }
      this.cs.togleSpiter(false);
      if (users.data.length === 0) {
        this.toursArray = [];
        if (isDevMode()) {
          console.log(users.message);
        }
        this.alert.showMessage('SEARCH.SAVE-ERROR', 'warning');
      } else {
        this.toursArray = users.data.slice();
        for (let i in this.toursArray) {
          this.toursArray[i].dateFrom = this.parseDate(this.toursArray[i].from);
          this.toursArray[i].dateTo = this.parseDate(this.toursArray[i].to);
          this.toursArray[i].styles = this.toursArray[i].styles ? isString(this.toursArray[i].styles) ? this.toursArray[i].styles.split(',') : this.toursArray[i].styles : '';
          this.toursArray[i].categories = this.toursArray[i].categories ? isString(this.toursArray[i].categories) ? this.toursArray[i].categories.split(',') : this.toursArray[i].categories : '';
        }
      }

    }, err => {

      this.cs.togleSpiter(false);
      if (isDevMode()) {
        console.log(err);
      }
    });

    this.initDictionaries().subscribe(() => {
    },
      err => console.error(err),
      () => {
        let oldParam = this.tss.getToursParams();
        if (oldParam) {
          this.filterForm.patchValue(oldParam);
          this.search();
        } else {
          this.fromModel();
          this.filterForm.patchValue({ distance: "200" });
          this.search();
        }
      });

    this.ts.onLangChange.subscribe(langEvent => {
      this.currentLang = langEvent.lang;
      this.initDictionaries().subscribe();
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });

    this.searchFrom.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.minDate = value;

          if (new Date(value.day, value.month - 1, value.year) > new Date(+this.searchTo.value.day, this.searchTo.value.month - 1, this.searchTo.value.year)) {
            this.searchTo.setValue(this.searchTo);
          }
        }
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      }
    );
  }

  fromModel(dateTime?: string) {
    try {
      let time = dateTime ? moment(dateTime) : moment();
      this.searchFrom.setValue({
        year: time.year(),
        month: time.month() + 1,
        day: time.date()
      });
      let searchToMonth = time.month() + 2 > 12 ? 1 : time.month() + 2;
      let searchToYear =  time.month() + 2 > 12 ? time.year() + 1 : time.year();
      this.searchTo.setValue(
        {
          year: searchToYear,
          month: searchToMonth,
          day: time.date()
        }
      );
    } catch (e) {
      if (isDevMode()) {
        console.log(e)
      }
    }
  }

  private initDictionaries(): Observable<any> {
    return concat(
      this.ds.getDictionaryNames('dic_professions')
        .pipe(
          map(
            dic => {
              this.professionsNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_specializations')
        .pipe(
          map(
            dic => {
              this.specializationsNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_styles')
        .pipe(
          map(
            dic => {
              this.stylesNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_collective_types')
        .pipe(
          map(
            dic => {
              this.collectiveTypeNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_collective_categories')
        .pipe(
          map(
            dic => {
              this.categoryDicNames = dic;
            }
          )
        )

    );
  }

  search() {
    this.alert.resetMessage();
    if (this.filterForm.invalid) {
      this.shouldCheck = true;
      return;
    }
    this.shouldCheck = false;
    this.tss.searchTours(this.filterForm.value);
  }
  
  openProfile(userRole, id) {
    switch (userRole) {
      case "Musician": this.router.navigate([`offer/search-details/${id}/0/info`]);
        break;
      case "Collective": this.router.navigate([`offer/search-details-collective/${id}/0/info`]);
        break;
    }
  }

  parseDate(dateTime: string) {
    return this.cs.parseDate(dateTime);
  }
}
