<agm-map [latitude]="latitude" [longitude]="longitude" #map  [gestureHandling]="'cooperative'" [style.width]="width"   [style.height]="height">
  <agm-marker [latitude]="latitude"
              [longitude]="longitude"
              [markerDraggable]="draggable"
              (dragEnd)="markerDragEnd($event)"
  ></agm-marker>
</agm-map>
<p>
  <ngb-progressbar
    type="warning"
    [value]="100"
    [striped]="true"
    [animated]="true"
    *ngIf="show">
    {{'PROFILE.LOCATION.LOADING' | translate}}
  </ngb-progressbar>
</p>




