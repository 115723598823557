<div class="input-block">
  <input type="text"
         #elem
         autocomplete="off"
         class="form-control"
         [class.is-invalid]="!elem.validity.valid && dirty"
         required
         (focus)="focus()"
         (blur)="blur()"
         [class.readonly]="disabledCustom"
         (click)="onEvent($event)"
         (keydown.arrowup)="decrementLink()"
         (keydown.arrowdown)="incrementLink()"
         (keyup.enter)="setValue($event)"
         [formControl]="searchDict">
  <div class="form-control drop_suggest"
      [hidden]="!(showList && lists.length && firstOpen)"
      #list>
    <div
      *ngFor="let item of lists; let i = index"
      [class.active]="activeItemIndex == i"
      tabindex="0"
      (mouseover)="activeItemIndex = i"
      (mousedown)="setValue($event, item)"
      (click)="onEvent($event)">
      {{item[lang]}}
    </div>
  </div>
  <i class="fas fa-spinner fa-pulse spinner_suggest fa-fw" style="color:gray;" [hidden]="!showSpinner"></i>
  <button class="close" aria-label="Close" type="button"  [hidden]="showSpinner" (click)="clearInput()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
</div>
