import {Injectable, isDevMode} from '@angular/core';

import {MapsAPILoader, GoogleMapsAPIWrapper} from '@agm/core';
import {Subject} from 'rxjs';

declare let google: any;

@Injectable()
export class LocationService {
  private language = "en";
  private region = "CH";
  private coordinatesSubj = new Subject();
  public coordinates$ = this.coordinatesSubj.asObservable();
  private placeSubj = new Subject();
  public place$ = this.placeSubj.asObservable();
  private isLoadSubj = new Subject();
  public isLoad$ = this.isLoadSubj.asObservable();
  service: any;

  constructor(private __loader: MapsAPILoader,
              private mapWrapper: GoogleMapsAPIWrapper) {
  }

  searchPlaces() {
    navigator.geolocation.getCurrentPosition((position) => {
      const latitude = position.coords.latitude,
        longitude = position.coords.longitude;
      this.coordinatesSubj.next({lat: latitude, lng: longitude});
      this.getCity({lat: latitude, lng: longitude}, true);
    }, err => {
      console.log(err);
    });
  }

  getCity(location: { lat: number, lng: number }, noCoordinateEvent?): Promise<String> {
    return new Promise( (resolve, reject) => {
      try {
        this.isLoadSubj.next(true);
        this.__loader.load().then(() => {
          let geoCoder = new google.maps.Geocoder();
          geoCoder.geocode({location: location, language: this.language, region: this.region}, (results, status) => {
            this.isLoadSubj.next(false);
            if (status === google.maps.GeocoderStatus.OK && Array.isArray(results)) {
              let response = results.filter((item: any) => {
                return item.types[0] === 'locality' || item.types[0] === 'postal_code';
              }).sort((a: any, b: any) => {
                if (a.types.indexOf('locality')) {
                  return 1;
                }
                if (b.types.indexOf('locality')) {
                  return -1;
                }
                if (a.types.indexOf('postal_code')) {
                  return 1;
                }
                if (b.types.indexOf('postal_code')) {
                  return -1;
                }
                return 0;
              });
              let address = response.length ? response[0] : results[0];
              let formattedAddress = address.formatted_address;
              let res = typeof formattedAddress === 'string' || formattedAddress.trim().match(/(\W{1,},)(\W{1,},)/);
              formattedAddress = res && res.length > 3 && res[1] === res[2] ? formattedAddress.replace(res[2], '') : formattedAddress;
              this.placeSubj.next(formattedAddress);
              resolve(formattedAddress);
              if (!noCoordinateEvent) {
                this.coordinatesSubj.next(location);
              }
            } else {
              if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                // this.placeSubj.error('ZERO_RESULTS');
              } else {
                // this.placeSubj.error(status);
                console.log(status);
              }
            }
          });
        });
      } catch (error) {
        this.isLoadSubj.next(false);
        reject(error);
        // this.placeSubj.error(error);
      }
    });
  }

  getPlaces(input, onlyCities?: boolean) {
    try {
      this.__loader.load().then(() => {
        // Create the search box and link it to the UI element.
        let options = onlyCities ? {types: ['(cities)'], language: 'en'} : {language: 'en'};
        const autocomplete = new google.maps.places.Autocomplete(input, options);
        // const searchBox = new google.maps.places.SearchBox(input);
        // Bias the SearchBox results towards current map's viewport.
        // this.markerManager.addMarker(this.marker);
        this.mapWrapper.subscribeToMapEvent('bounds_changed')
          .subscribe(() => {
            autocomplete.setBounds(this.mapWrapper.getBounds());
          }, err => {
            if (isDevMode()) {
              console.log(err);
            }
          });
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        autocomplete.addListener('place_changed', () => {
          let place = autocomplete.getPlace();

          if (place.length === 0) {
            return;
          }
          // For each place, get the icon, name and location.
          let bounds = new google.maps.LatLngBounds();
          if (!place.geometry) {
            console.log('Returned place contains no geometry');
            return;
          }
          this.coordinatesSubj.next({
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
          });
          let formattedAddress: string = `${place.name}, ${place.formatted_address}`;
          if ( typeof formattedAddress === 'string') {
            let res = formattedAddress.trim().match(/^([^,]+,){1}([^,]+,){1}/);
            formattedAddress =
              res && res.length > 2 && res[1].trim() === res[2].trim() ?
                formattedAddress.replace(res[2], '') : formattedAddress;
          }
          this.placeSubj.next(formattedAddress);
          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
          this.mapWrapper.fitBounds(bounds);
          input.click();
        });
      });
    } catch (error) {
      // this.placeSubj.error(error);
    }
  }

  getGeoCoding(address) {
    this.isLoadSubj.next(true);
    try {
      this.__loader.load().then(() => {
        let geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({address: address, language: this.language, region: this.region}, (results, status) => {
          this.isLoadSubj.next(false);
          if (status === google.maps.GeocoderStatus.OK) {
            const place = results[0];
            const reqObj = {
              lat: place.geometry.location.lat(),
              lng: place.geometry.location.lng()
            };
            this.coordinatesSubj.next(reqObj);
          } else {
            console.log('Error - ', results, ' & Status - ', status);
            if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
              // this.placeSubj.error('ZERO_RESULTS');
            } else {
              // this.placeSubj.error(status);
              console.log(status);
            }
          }
        });
      });
    } catch (error) {
      this.isLoadSubj.next(false);
      // this.placeSubj.error(error);
    }
  }
}
