import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  EventEmitter,
  Renderer2,
  Input,
  Output
} from '@angular/core';
@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit {
  @ViewChild('select', { static: true }) search: ElementRef;
  @ViewChild('addButton', { static: true }) addButton: ElementRef;
  @Input() dictionary = {};
  @Input() dictArray: any[] = [];
  @Input() list: any[];
  @Input() disabledCustom: boolean = false;
  @Input() key: string;
  @Input() tooltip: string = '';
  @Input() i18n = false;
  @Input() title: string;
  @Input() required :boolean = false;
  @Input() invalid :boolean = false;
  @Output() setData = new EventEmitter(true);
  @Output() delete = new EventEmitter(true);
  keyName: string;
  constructor(private _render: Renderer2) {}
  ngOnInit() {
    this.keyName = this.key ? this.key : 'id';
  }

  deleteFromList(index): void {
    this.delete.emit(index);
  }

  setList(value): void {
    this.setData.emit(value.target.value);
    this.search.nativeElement.selectedIndex = -1;
  }
  addActive() {
    this._render.addClass(this.addButton.nativeElement, 'active');
  }
  removeActive() {
    this._render.removeClass(this.addButton.nativeElement, 'active');
  }
  getName (name) {
    if (name && typeof name === 'object') {
      return name.name;
    }
    if (name && typeof name === 'string' && typeof this.dictionary === 'object') {
      const dictName =  this.dictionary[name.trim()];
      return dictName;
    }
  }
  isListAdded(code) {
    let flag = false;
    if (Array.isArray(this.list)) {
      this.list.forEach(item => {
        /* tslint:disable */
        if (item == code) {
          flag = true;
        };
      })
    }
    return flag;
  }
}
