<section class="completion-resume">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h2><span class="ml-3">{{'COMMON.NOTIFICATIONS' | translate | uppercase}}</span></h2>
      </div>
    </div>
  </div>
</section>
<article>
  <div class="container main-container" *ngIf="initialized">
    <article *ngIf="!haveItems()" class="profile-content">
      <div class="empty-text">
        {{'NOTIFICATIONS.NOITEMS' | translate}}
      </div>
    </article>
    <article class="offers-table">
      <div class="row card" [ngClass]="item.isNew ? 'selected-card' : ''" *ngFor="let item of notifications" (click)="goTo($event, item)">


        <div class="col-12 d-flex">
          <div class="w-75 message-date">
            {{prepareDate(item.createdAt)}}
          </div>
          <div class="w-25 justify-content-end text-right">
            <div class="cancel-png p-2" (click)="deleteItem($event, item)"></div>
            <!--<i class="fas fa-trash fa-lg" aria-hidden="true" (click)="deleteItem($event, item)"></i>-->
          </div>
        </div>
        <div class="col-12 message-title">
          <span>{{item.title}}</span>
        </div>
        <div class="col-12 message-body">
          <span>{{item.message}}</span>
        </div>
        <div class="col-12 pane_navigation_btn">
          <span class="pane_navigation_btn_text" (click)="goTo($event, item)">
            {{'COMMON.OPEN' | translate}} ...
          </span>
        </div>

      </div>
      <app-alert #alert></app-alert>
    </article>
  </div>
</article>