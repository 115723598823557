import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {MusicianProfileService} from 'app/_service/musician-profile/musician-profile.service';
import {CommonService} from 'app/_service/common.service';
import {take} from 'rxjs/operators';

@Injectable()
export class ProfileGuard implements CanActivate {
  constructor(private ms: MusicianProfileService, private cs: CommonService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.cs.togleSpiter(true);
    return Observable.create(observable => {
      this.ms.getProfile()
        .pipe(
          take(1)
        ).subscribe(() => {
        this.cs.togleSpiter(false);
        observable.next(true);
      }, () => {
        this.cs.togleSpiter(false);
        observable.next(false);
      });
    })
  }
}
