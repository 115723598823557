<section class="completion-resume">
  <div class="container">
    <div class="row">
      <div class="col-xl-3">
        <h2>{{profile.firstName}} {{profile.lastName}}</h2>
        <p>{{userService.getLocalNameRole() | translate}}</p>
      </div>
      <div class="col-xl-9 d-none d-md-block">
        <app-progressbar *ngIf="showProgressBar()"></app-progressbar>
      </div>
    </div>
  </div>
</section>
<div class="container main-container">
  <div class="row">
    <div class="col-12 col-md-4 col-lg-3">
      <aside class="border-sm-none">
        <div class="aside__photo">
          <article class="avatar" (click)="avatarClick($event)" (mouseenter)="showEditLabel = 1" (mouseleave)="showEditLabel = 0">
            <img [src]="avatarUrl" *ngIf="avatarUrl" class="aside__avatar" alt="avatar">
            <div class="alt-avatar" *ngIf="!avatarUrl" ngbPopover="{{'TOOLTIP.AVATAR' | translate}}" triggers="mouseenter:mouseleave">
              <div style="padding: 0 10px 0 10px; text-decoration: underline;">
                {{'PROFILE.DOWNLOAD_PHOTO' | translate}}
              </div>
            </div>
            <a class="aside__btn-photo-change" (click)="avatarClick($event)" [style.opacity]="showEditLabel">
              <img src="assets/img/icons/change.png">
              {{'PROFILE.CHANGE' | translate}}
            </a>
          </article>
          <app-profile-menu *ngIf="router.isActive('profile/musician', false)"></app-profile-menu>
          <app-organizer-menu *ngIf="router.isActive('profile/organizer', false)"></app-organizer-menu>
          <app-collective-menu *ngIf="router.isActive('profile/collective', false)"></app-collective-menu>
          <app-agency-menu *ngIf="router.isActive('profile/agency', false)"></app-agency-menu>
        </div>
      </aside>
    </div>
    <div class="col-12 col-md-8 col-lg-9">
      <article class="profile-content main-content border-sm-none container" [@routeAnimations]="prepareRoute(outlet)">
        <router-outlet #outlet="outlet"></router-outlet>
      </article>
    </div>
  </div>
</div>
<input type="file" #file (change)="upload($event)" [style.display]="'none'" accept="image/jpeg,image/png,image/gif">