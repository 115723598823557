import { Component, OnInit, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {
  @Input() type;
  @Input() size;
  @Input() title: string = 'MODAL.SAVE_CONFIRM.TITLE';
  @Input() message: string = 'MODAL.SAVE_CONFIRM.CONTENT';
  @Input() cancel: string  = 'MODAL.SAVE_CONFIRM.CANCEL';
  @Input() save: string  = 'MODAL.SAVE_CONFIRM.SAVE';
  content: string;
  onlyCancel = true;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    switch (this.type) {
      case 'maxSize':
        this.content = 'MODAL.MAX_SIZE.CONTENT';
        this.cancel = 'MODAL.MAX_SIZE.CANCEL';
        break;
      case 'plainModal':
        this.content = this.message;
        this.cancel = 'MODAL.MAX_SIZE.CANCEL';
        break;
      case 'message':
        this.content = this.message;
        this.cancel = "COMMON.CLOSE";
        break;
      default:
        this.content = this.message;
        this.onlyCancel = false;
    }
  }
}
