<section class="preview-image preview-image__sign-up">
  <div class="frame row align-items-center text-center">
    <section class="col-11 col-sm-10">
      <h5><b>&nbsp;&nbsp;{{'PROFILE.ACCOUNT.DELETED' | translate}}<br/>{{'PROFILE.ACCOUNT.DELETED_2' | translate}}</b></h5>
    </section>
    <section class="col-1 col-sm-2 img-section">
      <img class="pop-up__modal-close" src="assets/img/icons/modal_close.png" [routerLink]="['/']">
    </section>
  </div>
</section>
