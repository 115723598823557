import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-agency-menu',
  templateUrl: './agency-menu.component.html',
  styleUrls: ['./agency-menu.component.css'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({
        height:'0',
        overflow:'hidden',
        opacity:'0'
      })),
      state('final', style({
        overflow:'hidden',
        opacity:'1'
      })),
      transition('initial=>final', animate('.1s')),
      transition('final=>initial', animate('.1s'))
    ]),
  ]
})
export class AgencyMenuComponent implements OnInit {
  listShowActive: boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
