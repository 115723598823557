import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {UserService} from 'app/_service/user.service';
import {AlertComponent} from 'app/user/shared/shared.module';
import { EMAIL_PATTERN } from 'app/constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  doSubmit: boolean = false;

  @ViewChild('alert', { static: true }) private alert: AlertComponent;
  email: FormControl = this.fb.control('', [Validators.pattern(EMAIL_PATTERN)]);

  constructor(public fb: FormBuilder,
              private router: Router,
              public userService: UserService) {
  }

  ngOnInit() {
  }

  save() {
    console.log(this.email)
    if(this.email.invalid) {
      return;
    }
    this.doSubmit = true;
    this.userService.resetPasswordEmail(this.email.value).subscribe(() => {
        this.doSubmit = false;
        this.router.navigate(['signin'], {queryParams: {'resetSendEmail': this.email.value}});
      },
      err => {
        this.doSubmit = false;
        if (err.error.code == 1 || err.error.code == 2) {
          this.alert.showMessage('SIGN_IN.NOT_REGISTERED', 'danger');  
          return;
        }
        this.alert.showMessage(err.error.message, 'danger', true);
        //this.alert.showMessage('COMMON.INVALID_EMAIL', 'danger');
      });
  }

}
