import {Component, OnInit, OnDestroy} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import {UserService} from 'app/_service/user.service';
import {JsonService} from 'app/_service/json/json.service'
import { Unsubscribable } from 'rxjs';

@Component({
  moduleId: module.id,
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  initialized: boolean = false;
  currentLang  = this.ts.currentLang;
  auth = true;
  sos: string;
  mainText: string;
  secondLabel: string;
  secondText: string;
  artistText: string;
  organizationText: string;
  musicServiceText: string;
  artist: [any];
  organization: [any];
  musicService: [any];

  private subscriptions: Unsubscribable[] = [];

  constructor(    public us: UserService,
                  private jService: JsonService,
                  private ts: TranslateService) {
  }

  ngOnInit() {
    this.updateData(this.currentLang);
    
    let sub = this.us.getUser$.subscribe((user: any) => {
      this.auth = !!user;
    }, () => {
    });
    this.subscriptions.push(sub);
    
    sub = this.ts.onLangChange.subscribe(langEvent => {
      this.currentLang = langEvent.lang;
      this.updateData(this.currentLang);
    }, () => {
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  loaded() {
    this.initialized = true;
  }

  updateData(currentLang) {
    this.jService.getJSON(`i18n/home/${currentLang}.json`)
      .subscribe(
        data => {
          this.sos = data.sos;
          this.mainText = data.mainText;
          this.secondLabel = data.secondLabel;
          this.secondText = data.secondText;
          this.artist = data.artist;
          this.organization = data.organization;
          this.musicService = data.musicService;
          this.artistText = data.artistText;
          this.organizationText = data.organizationText;
          this.musicServiceText = data.musicServiceText;
        },
        err => {
          console.log(err);
        });
  }
}

