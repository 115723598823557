import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { ApiService } from './backend/api.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  public readonly STORAGE_URL = this.api.geFulltUrl('/api/files');

  constructor(private api: ApiService,
    private us: UserService) { }

  public uploadFile(name, result): Observable<any> {
    const subject = new Subject();
    let url = `/api/files/${name}`;
    let headers = new HttpHeaders();
    headers = headers.set('accept', 'application/octet-stream *');
    const upload = this.api.post(url, result, { headers: headers });
    upload.subscribe((data: any) => {
      subject.next(data);
      subject.complete();
    }, err => {
      if (err.status === 401) {
        this.us.logout();
      }
      // console.log(err);
    });
    return subject;
  };

  public removeFile(name): Observable<any> {
    let subject = new Subject();
    this.api.delete(`/api/files/${name}`).subscribe(
      () => {
        subject.next(true);
        subject.complete();
      },
      err => {
        if (err.status === 401) {
          this.us.logout();
        }
        subject.complete();
      });
    return subject;
  };

  public getFileUrl(path: string, fileName: string = '', inline: boolean = false, id?: number): string {
    const token = sessionStorage.getItem('access_token') || localStorage.getItem('access_token');
    if (!path) {
      return '';
    }
    let resultPath;
    if (id) {
      resultPath = `${this.STORAGE_URL}/${path}/${id}?filename=${encodeURIComponent(fileName)}`;
    } else {
      resultPath = `${this.STORAGE_URL}/${path}?filename=${encodeURIComponent(fileName)}`;
    }
    if (inline) {
      resultPath = `${resultPath}&type=img`
    }
    if (typeof (token) === 'string') {
      resultPath = `${resultPath}&access_token=${token}`
    }
    return resultPath;
  }

}
