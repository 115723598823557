<section class="completion-resume">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 (click)="openInvitation($event)" class="point_button"> <<< <span>{{backLinkTitle | translate}}</span></h2>
      </div>
    </div>
  </div>
</section>
<div class="container main-container">
  <article class="contract" [hidden]='!initialization'>
    <div class="mb-5">
      <img class="pop-up__modal-close  pull-right" src="assets/img/icons/modal_close.png" (click)="openInvitation($event)">
    </div>
    <div [innerHTML]="http" [class]="hideOtherLang"></div>
    <div class="offer-content__btn row">
      <app-button class="btn-component" (buttonComponentEvent)="signIn()" [btnTitle]="'INVITATIONS.BUTTON_STATE.3' | translate" [isButtonPushed]="isButtonPushed" [hidden]="hideButton(state)"></app-button>
    </div>
  </article>
</div>