<section class="completion-resume">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h2 class="text-md-left text-center m-3">{{'PROFILE.TOURS.TOUR_CALENDAR' | translate | uppercase}}</h2>
      </div>
    </div>
  </div>
</section>
<div class="container main-container">
  <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
        <p class="font-italic">{{'PROFILE.TOURS.CALENDAR_TOOLTIP' | translate}}</p>
      </div>
  <form class="form-group form-user-data__item row justify-content-around align-items-end group-border m-0"
    [formGroup]="filterForm">
    <div class="col-10 col-lg-5  p-3 m-2 m-lg-0">
      <label>{{'PROFILE.TOURS.DATES' | translate}}</label>
      <div class="row justify-content-around align-items-center">
        <label class="control-label  col-1">{{'PROFILE.SKILLS.YEARS_FROM' | translate}}</label>
        <div class="input-group col-10 col-lg-4 p-0">
          <input class="form-control datepicker-input" placeholder="dd/mm/yyyy" [class.is-invalid]="searchFrom.invalid && shouldCheck"
            name="dpFrom" [formControl]="searchFrom" ngbDatepicker #d1="ngbDatepicker" [readonly]="true">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="d1.toggle(); $event.stopPropagation();" type="button">
              <i class="fas fa-calendar-alt" aria-hidden="true" style="width: 1.2rem; height: 1rem; cursor: pointer;"></i>
            </button>
          </div>
        </div>
        <label class="control-label col-1">{{'PROFILE.SKILLS.YEARS_TO' | translate}}</label>
        <div class="input-group col-10 col-lg-4 p-0">
          <input class="form-control datepicker-input" placeholder="dd/mm/yyyy" [class.is-invalid]="searchTo.invalid && shouldCheck"
            [minDate]="minDate" name="dpTo" [formControl]="searchTo" ngbDatepicker #d2="ngbDatepicker" [readonly]="true">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="d2.toggle(); $event.stopPropagation();" type="button">
              <i class="fas fa-calendar-alt" aria-hidden="true" style="width: 1.2rem; height: 1rem; cursor: pointer;"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 col-lg-4 mb-3 ml-4">
      <label for="distance">{{'ORGANIZER.OFFER.DISTANCE' | translate}}</label>
      <input class="form-control" id="distance" name="distance" (keyup.enter)="search()" [placeholder]=""
        formControlName="distance" />
    </div>
    <app-button class="btn_component mb-3" (buttonComponentEvent)="search()" [btnTitle]="'SEARCH.TITLE' | translate"
      [isButtonPushed]="isButtonPushed"></app-button>
  </form>
  <div class="invalid-feedback" [style.display]="shouldCheck ? 'block' : 'none'">
    {{"COMMON.REQUIRE_FIELD" | translate}}</div>

  <app-alert #alert></app-alert>

  <div class="form-user-data__item mt-5">
    <div class="row align-items-center mb-4">
      <div class="col-2 col-md-1 repertoire__drop up-down align-items-center" (click)="isCollapsed = !isCollapsed">
        <i class="fas fa-chevron-right" aria-hidden="true" [hidden]="!isCollapsed"></i>
        <i class="fas fa-chevron-down" aria-hidden="true" [hidden]="isCollapsed"></i>
      </div>
      <div class="col-8 pl-0 col-md-11 repertoire__line">
        <hr>
      </div>
    </div>

    <div class="row education-edit__item" *ngFor="let item of toursArray let i=index" [hidden]="isCollapsed">
      <div class="col-10 point_row col-lg-12 row" (click)="openProfile(item.userRole, item.id)">
        <div class="col-12 col-sm-2 education-edit__name">
          <p>{{item.firstName}} {{item.lastName}} {{item.name}}</p>
        </div>
        <!-- <div class="col-12 col-sm-1 education-edit__name">
          <p>{{item.userRole | translate}}</p>
        </div> -->
        <div class="col-12 col-sm-1 education-edit__name">
          <p>{{professionsNames[item.profId]}} {{collectiveTypeNames[item.type]}}</p>
        </div>
        <div class="col-12 col-sm-2 education-edit__name">
          <p *ngIf="item.specializationId">{{specializationsNames[item.specializationId]}}</p>
          <p *ngFor="let category of item.categories">{{categoryDicNames[+category]}}; </p>
        </div>
        <div class="col-12 col-sm-2 education-edit__name">
          <p *ngFor="let style of item.styles">{{stylesNames[+style]}}; </p>
        </div>
        <!-- <div class="col-12 col-sm-1 education-edit__name">
          <p>{{item.title | translate}}</p>
        </div>
        <div class="col-12 col-sm-1 education-edit__name">
          <p>{{item.yearOfBirth}}</p>
        </div> -->
        <div class="col-12 col-sm-3 education-edit__name">
          <p>{{item.venue}}</p>
        </div>
        <div class="col-12 col-sm-2 text-sm-right education-edit__place">
          <p> {{item.dateFrom}} - {{item.dateTo}}</p>
        </div>
      </div>
      <div class="col-12 education-edit__hr">
        <hr>
      </div>
    </div>
  </div>
</div>