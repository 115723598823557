import {Component, isDevMode, OnInit} from '@angular/core';
import {MusicianProfileService} from 'app/_service/musician-profile/musician-profile.service';
import {DataService} from 'app/_service/backend/data.service';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.css']
})
export class ProgressbarComponent implements OnInit {
  currentValue: number = 100;
  info = 'info';

  constructor(private ds: DataService,
              private ms: MusicianProfileService) {
  }

  ngOnInit() {
    this.setValue();
    this.ms.progressUpdate$.subscribe(() => {
      this.setValue();
    }, () => {
    })
  }

  setValue(): void {
    try {
      this.ds.get_mus_profile_stat().subscribe(item => {
          this.currentValue = item.count;
          this.sevInfo();
        }, err => {
          if (isDevMode()) {
            console.log(err);
          }
        }
      )
    } catch (err) {
      if (isDevMode()) {
        console.log(err);
      }
    }
  }

  sevInfo(): void {
    if (this.currentValue > 70) {
      this.info = 'success';
    } else if (this.currentValue > 49) {
      this.info = 'warning';
    } else {
      this.info = 'danger';
    }
  }
}
