<div class="line-horizontal">
  <div style="width: 100%">
    <h3 class="text-center label-text-secondary">{{title | translate}}</h3>
  </div>
  <div class="cross-modal">
    <div class="cancel-png" style="width: 15px; height: 15px" (click)="activeModal.dismiss()"></div>
  </div>
</div>
<div class="card-block margin-20">
  <div class="text-content-modal">
    <p>{{content |  translate}} {{size}}</p>
  </div>
</div>
<div class="row justify-content-center margin-20">
  <app-button class="mr-lg-4 mr-md-4" (buttonComponentEvent)="activeModal.close(true)" [btnTitle]="save | translate" [isButtonPushed]="false" [hidden]="onlyCancel"></app-button>
  <app-button (buttonComponentEvent)="activeModal.close(false)" [btnTitle]="cancel | translate" [isButtonPushed]="false"></app-button>
</div>