import {Injectable, isDevMode} from '@angular/core';
import {ReplaySubject, Observable, Subject} from 'rxjs';
import {ApiService} from 'app/_service/backend/api.service';
import {ROLES} from 'app/constants';

export type SearchParameters = {
  location: [any],
  distance: Number
};

@Injectable()
export class SearchService {
  private _searchCandidatesResultList = new Subject();
  private _place: Object;
  private _offerId: Number;
  private _role: string;
  private _entity: string;
  public searchCandidatesResultList$ = this._searchCandidatesResultList.asObservable();

  constructor(private api: ApiService) {
  }

  getOfferId() {
    return this._offerId;
  }

  getPlace() {
    return {...this._place};
  }

  getRole() {
    return this._role;
  }
  getEntity() {
    return this._entity;
  }
  setOfferInfo(offerId, place, entity) {
    if (offerId) {
      this._offerId = offerId;
    }
    if (place) {
      this._place = place;
    }
    if (entity) {
      this._entity = entity;
    }
  }

  searchCandidates(offerId, role?): void {
    this._role = role;
    this._offerId = offerId;
    let path: string;
    switch (role) {
      case ROLES[0][0]:
        path = 'find_musicians';
        break;
      case ROLES[2][0]:
        path = 'find_collectives';
        break;
      default:{
        this._searchCandidatesResultList.next({data:[], dataTours:[], offer: {}});
        return;
      }

    }
    this.find_musicians({offerId}, path)
      .subscribe(resp => {
        this._searchCandidatesResultList.next(resp);
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      });
  }

  private  find_musicians(offerId, path, searchParam?): Observable<any> {
    return Observable.create((observer) => {
      const find = searchParam ? this.api.post(`/api/action/${path}`, searchParam) : this.api.post(`/api/action/${path}`, offerId);
      find.subscribe(res => {
        console.log(res);
        observer.next(res);
        observer.complete();
      }, error => {
        observer.error(error);
      })
    });
  };

}
