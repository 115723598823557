import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {AgmMarker} from '@agm/core';
import {FormBuilder, FormControl} from '@angular/forms';
import {ProgressbarComponent} from 'app/user/profile/progressbar/progressbar.component';
import {LocationService} from './location.service';
declare let google: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})

export class LocationComponent implements OnInit {
  @Input ('height') height: string = '300px';
  @Input ('width') width: string = '100%';
  show: boolean = false;
  title: string = '';
  latitude: number;
  longitude: number;
  draggable = true;
  styles = [
    {elementType: 'geometry', stylers: [{color: '#e8e8e8'}]},
    {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
    {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{color: '#C7D0CC'}]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [{color: '#9E9E9E'}]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{color: '#f8f8f8'}]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [{color: '#CECECE'}]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{color: '#38414e'}]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{color: '#ffffff'}]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{color: '#9ca5b3'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{color: '#e7e7e7'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{color: '#cfcfcf'}]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{color: '#AFAFAF'}]
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{color: '#bfbfbf'}]
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [{color: '#BFBFBF'}]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{color: '#FFFFFF'}]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{color: '#464646'}]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{color: '#708090'}]
    }
  ];
  place: FormControl = this.fb.control('');
  @ViewChild(ProgressbarComponent)
  private progressbarComponent: ProgressbarComponent;
  @ViewChild(AgmMarker, { static: true })
  private marker: AgmMarker;
  constructor(public fb: FormBuilder,
              public locationService: LocationService) {
  }
  ngOnInit() {
    this.locationService.coordinates$.subscribe((coordinates: any) => {
      this.latitude = coordinates.lat;
      this.longitude = coordinates.lng;
    });
    this.locationService.place$.subscribe((places: any) => {
      this.place.patchValue(places);
    });
    this.locationService.isLoad$.subscribe((isLoad: boolean) => {this.show = isLoad; } );
  }
  markerDragEnd($event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.locationService.getCity({lat: this.latitude, lng: this.longitude});
  }
}
