<div class="form_reset-password">
  <div class="form_reset-password__head">
    <h1>{{'RESET.TITLE' | translate}}</h1>
  </div>
  <div class="form_reset-password__content">
    <form>
      <div class="form-group mb-0">
        <input class="form-control rounded-0" type="email"
               id="exampleInputEmail1"
               placeholder="Email"
               [formControl]="email"
               [class.is-invalid]="email.invalid">
        <div class="invalid-feedback" *ngIf="email.errors && email.errors['pattern']">
                {{"COMMON.INVALID_EMAIL" | translate}}</div>
      </div>
      <div class="mt-4">
        <app-alert #alert></app-alert>
      </div>
      <button class="btn rounded-0 form_reset-password__btn" (click)="save()"
              [disabled]="email.invalid || doSubmit">{{'RESET.SUBMIT' | translate}}
              <i [hidden]='!doSubmit' class="fas fa-spinner fa-pulse fa-fw"></i>
      </button>
    </form>
  </div>
</div>
