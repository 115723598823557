import { Component, OnInit, isDevMode } from '@angular/core';
import { NavigationEnd, Router, NavigationStart, RouterOutlet, GuardsCheckStart, GuardsCheckEnd, NavigationCancel } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/_service/user.service';
import { JsonService } from 'app/_service/json/json.service';
import { StorageService } from 'app/_service/storage.service';
import { CommonService } from 'app/_service/common.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateRusParserFormatter } from './provider/date-parser';
import { trigger, transition, style, animate, query, animateChild, group, stagger } from '@angular/animations';
@Component({
  moduleId: module.id,
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
  providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateRusParserFormatter }],
  animations: [
    trigger('routeAnimations', [
      transition('* <=> fade', [
        query(':enter', [
          style({ opacity: 0})
        ]),
        query(':leave', animateChild(), { optional: true }),
        group([
          query(':leave', [animate(300, style({opacity: 0}))], { optional: true }),
          query(':enter', [animate(300, style({opacity: 1}))])
        ]),
        query(':enter', animateChild())
      ])
    ])
/*
    trigger('routeAnimations', [
      state('in', style({opacity: 1})),
      transition(':enter', [
        style({opacity: 0}),
        animate(600)
      ]),
      transition(':leave',
        animate(600, style({opacity: 0})))
    ])
*/
  ]
})
export class AppComponent implements OnInit {
  public isCollapsed: boolean = true;
  appName = 'QArtist';
  version: string = '';
  stateSpinner = false;
  title = this.appName;
  users: User;

  constructor(private router: Router,
    public userService: UserService,
    private translate: TranslateService,
    private js: JsonService,
    private cs: CommonService,
    private ss: StorageService) {
    translate.addLangs(['it', 'en', 'ru']);
    translate.setDefaultLang('en');
    let browserLang = translate.getBrowserLang();
    let selectedLang = browserLang.match(/it|en|ru/) ? browserLang : 'en';
    translate.use(selectedLang);
    let htmlTag = document.querySelector('html');
    htmlTag.setAttribute('lang', selectedLang);

    this.router.events.subscribe((evt) => {
      if (isDevMode()) {
        console.log(evt);
      }
      if (evt instanceof NavigationStart || evt instanceof GuardsCheckEnd ) {
        this.cs.togleSpiter(true);
      } else if (evt instanceof NavigationEnd || evt instanceof GuardsCheckStart || evt instanceof NavigationCancel ) {
        this.cs.togleSpiter(false);
      }
      if (!(evt instanceof NavigationEnd) || evt.urlAfterRedirects.indexOf('/profile/musician') !== -1) {
        return;
      }
      window.scrollTo(0, 0);
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
  }

  ngOnInit(): void {
    this.cs.spinner$.subscribe(state => {
      this.stateSpinner = state;
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });

    this.userService.getUserDetails().subscribe(() => { }, () => { });
    this.comparatorVersion();
    setInterval(() => { this.comparatorVersion() }, 900000);
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

  comparatorVersion() {
    const antiCache = new Date().getTime();
    this.js.getJSON(`version.json?${antiCache}`).subscribe(item => {
      const currentVersionWeb = item.version,
        currentVersionClient: string = this.ss.read('version');
      console.log(currentVersionWeb);
      if (!currentVersionClient) {
        this.ss.write('version', currentVersionWeb);
        this.version = currentVersionWeb;
        return;
      }
      if (currentVersionWeb !== currentVersionClient) {
        this.ss.write('version', currentVersionWeb);
        this.ss.sessionRemove('version');
        window.location.reload(true);
      } else {
        this.version = currentVersionClient;
      }
    }, () => { });
  }
}
