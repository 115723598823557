import { Component, OnInit, ViewChild, Input } from '@angular/core';

import {CommonService} from 'app/_service/common.service';

@Component({
  selector: 'app-invitation-alert',
  templateUrl: './invitation-alert.component.html',
  styleUrls: ['./invitation-alert.component.css']
})
export class InvitationAlertComponent implements OnInit {
  @Input() invitation: any;  
  @Input() userRole: any;  
  constructor(
    public cs: CommonService
  ) { }

  ngOnInit() {
  }

}
