<section class="preview-image preview-image__sign-up">
  <div class="form form_sign-in"  [ngClass]="resetSendEmail && 'small-height'">
    <h1>{{'SIGN_IN.TITLE' | translate}}</h1>
    <div class="alert alert-warning" *ngIf="newUser">
      {{'SIGN_IN.NEED_CONFIRM' | translate}} <a href="mailto:{{newUser}}">{{newUser}}</a>
    </div>
    <div class="alert alert-success" *ngIf="reset">
      {{'SIGN_IN.RESET' | translate}}
    </div>
    <div class="alert alert-success" *ngIf="resetSendEmail">
      {{'SIGN_IN.RESET_SEND_EMAIL' | translate}} <a href="mailto:{{resetSendEmail}}">{{resetSendEmail}}</a>
    </div>
    <div class="alert alert-success text-center" *ngIf="confirmSuccess">
      {{'SIGN_IN.SUCCESS_SING_UP' | translate}}
    </div>
    <div class="alert alert-warning text-center" *ngIf="errorResetLink">
      {{'SIGN_IN.ERROR_RESET_LINK' | translate}}
    </div>
    <div [ngClass]="buttonHide()">
      <div class="form__links sos__cursor" (click)="social('linkedin')">
        <div class="form__icon"><img src="assets/img/icons/jlid.png" alt="linkedin"/></div>
        <div class="form__link"><a class="social-text">{{'SIGN_IN.SIGN_IN_WITH' | translate}} Linkedin</a></div>
        </div>
      <div class="form__links sos__cursor"  (click)="social('facebook')">
        <div class="form__icon"><img src="assets/img/icons/form_f.png" alt="facebook"/></div>
        <div class="form__link"><a class="social-text">{{'SIGN_IN.SIGN_IN_WITH' | translate}} Facebook</a></div>
      </div>
      <div class="form__links sos__cursor" (click)="social('google')">
        <div class="form__icon"><img src="assets/img/icons/form_g.png" alt="google"/></div>
        <div class="form__link"><a class="social-text">{{'SIGN_IN.SIGN_IN_WITH' | translate}} Google</a></div>
      </div>
      <p [ngClass]="buttonHideForm()">{{'SIGN_IN.SIGN_IN_USING_EMAIL' | translate}}</p>
    </div>
      <form [ngClass]="buttonHideForm()" [formGroup]="signInForm">
        <div class="form-group form__inputs">
          <input type="email" class="form-control"
                 [class.is-invalid]="username.invalid && shouldCheck"
                 placeholder="Email"  formControlName="username" required>
        </div>
        <div class="form-group form__inputs">
          <input class="form-control" type="password"
                 placeholder="{{'PROFILE.ACCOUNT.PASSWORD' | translate}}"
                 [class.is-invalid]="password.invalid && shouldCheck"
                 formControlName="password" (keyup.enter)="signIn(signInForm.value)" required>
        </div>
      </form>
      <div
        [ngClass]="buttonHideForm()"
        class="form__already_acc"><a [routerLink]="['/forgot']">{{"SIGN_IN.FORGOT" | translate}}</a></div>
      <app-alert #alert [hidden]="signInForm.dirty"></app-alert>
      <button class="btn btn-outline-black-invert"
              [ngClass]="buttonHideForm()"
              [disabled]="isButtonPushed"
              (keypress.enter)="signIn(signInForm.value)"
              (click)="signIn(signInForm.value)">
          <i [hidden]="!isButtonPushed" class="fas fa-spinner fa-pulse fa-fw"></i>
          <span [hidden]="isButtonPushed">{{'SIGN_IN.SUBMIT' | translate}}</span>
      </button>
      <div class="form__regulations"
           [ngClass]="buttonHideForm()">{{'SIGN_IN.DO_NOT_HAVE_ACCOUNT' | translate}}<a [routerLink]="['/signup']">  {{'SIGN_IN.GET_STARTED' | translate}}</a></div>
  </div>
</section>

