import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ROLES } from 'app/constants';
import { UserService } from 'app/_service/user.service';
import { DataService } from 'app/_service/backend/data.service';
import { CommonService } from 'app/_service/common.service';


@Injectable()
export class WelcomeService implements CanActivate {
  constructor(public userService: UserService, private dtS: DataService, private router: Router, private cs: CommonService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.cs.togleSpiter(true);
    //console.log(`route ${route} stare ${state}`);
    return Observable.create(observable => {
      this.cs.togleSpiter(false);
      const welcomePage = () => {
        this.dtS.get_welcome_data().subscribe((item) => {
          let fullProfile = true;
          if (item && item.roleName) {
            switch (item.roleName) {
              case ROLES[0][0]:
                fullProfile = !!(item.profId && item.specializationId && Array.isArray(item.location) && item.location.length > 0 && item.phone);
                break;
              case ROLES[1][0]:
                fullProfile =  !!(item.name && item.address && item.email && item.administrator && item.phone);
                break;
              case ROLES[2][0]:
                // for type == 'Opera Company' (6) category not required
                fullProfile = !!(item.type && item.name && item.email && (item.type == '6' || item.categories) && item.phone);
                break;
            }
          }
          observable.next(fullProfile);
          if (!fullProfile) {
            this.router.navigateByUrl('/fill-profile');
          }
        }, err => {
          if (isDevMode()) {
            console.log(err);
          }
          this.cs.togleSpiter(false);
          observable.next(false);
          this.router.navigateByUrl('/');
        });
      };
      this.dtS.agreed_acquainte().subscribe((result) => {
        const { agreed } = result;
        if (!agreed) {
          observable.next(false);
          this.router.navigateByUrl('/agree');
        } else {
          welcomePage();
        }
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
        this.cs.togleSpiter(false);
        observable.next(false);
        this.router.navigateByUrl('/');
      });
    });
  }
}
