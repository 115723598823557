import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html'
})
export class ButtonComponent implements OnInit {

  @Input() btnTitle: string = "";
  @Input() spinnerGo: boolean = false;
  @Input() isButtonPushed: boolean = false;
  @Input() disabled: boolean = false;
  @Output() buttonComponentEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

  }

  onBtnClick(event) {
    event.stopPropagation();
    this.buttonComponentEvent.emit();
  }
}
