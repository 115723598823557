import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';

import {UserService} from 'app/_service/user.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public userService: UserService,  private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return Observable.create((observable) => {
      this.userService.isAuth().subscribe(() => {
        observable.next(true);
      }, () => {
        observable.next(false);
        this.router.navigateByUrl(`signin?url=${state.url}`);
      })
    });
  }
}
