import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from '../home/home.component';
import {HowItWorksComponent} from '../home/how-it-works/how-it-works.component';
import {ContactsComponent} from '../home/contacts/contacts.component';
import {SignupComponent} from '../user/signup/signup.component';
import {SigninComponent} from '../user/signin/signin.component';
import {ProfileComponent} from '../user/profile/profile.component';
import {NewPasswordComponent} from '../user/forgot-password/new-password/new-password.component';
import {ListNotificationsComponent} from 'app/user/list-notifications/list-notifications.component';
import {FillProfileComponent} from 'app/user/fill-profile/fill-profile.component';
import { AgreeService } from 'app/app-routing/agreeService';
import { DeletedComponent } from 'app/user/shared/deleted/deleted.component';
import {ProfileGuard} from './profile-guard';
import {GuardSave} from './guard-save';
import {GuardService} from './guard.service';
import {AuthGuardService} from './auth-guard.service';
import {WelcomeService} from './welcomeService';
import {ForgotPasswordComponent} from 'app/user/forgot-password/forgot-password.component';
import {TermsAcquaintedComponent} from 'app/user/terms-acquainted/terms-acquainted.component';
import { AdminGuard } from 'app/admin/admin-guard';


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  },
  {path: 'how-it-works', component: HowItWorksComponent},
  {path: 'contacts', component: ContactsComponent},
  {path: 'agree', component: TermsAcquaintedComponent},
  {path: 'account-deleted', component: DeletedComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'signin', component: SigninComponent},
  {
    path: 'offer',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/user/offer/offer.module').then(m => m.OfferModule)
  },
  {
    path: 'my-musicians',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/user/list-musicians/my-musicians.module').then(m => m.MyMusiciansModule)
  },
  {
    path: 'list-notification',
    canActivate: [AuthGuardService],
    component: ListNotificationsComponent
  },
  {
    path: 'tour-calendar',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/user/tour-calendar/tour-calendar.module').then(m => m.TourCalendarModule)
  },
  {
    path: 'invitations/contract/:artRole/:inviteId/:offerId',
    canActivate: [AuthGuardService],
    loadChildren: () => import('app/user/contract/contract.module').then(m => m.ContractModule)
  },
  {
    path: 'invitations/org',
    canActivate: [GuardService, AuthGuardService],
    loadChildren: () => import('app/user/invitations/organizer/org-invitations.module').then(m => m.OrgInvitationsModule)
  },
  {
    path: 'invitations/mus',
    canActivate: [AuthGuardService, GuardService],
    loadChildren: () => import('app/user/invitations/musicians/mus-invitations.module').then(m => m.MusInvitationsModule)
  },
  {
    path: 'invitations/coll',
    canActivate: [AuthGuardService, GuardService],
    loadChildren: () => import('app/user/invitations/collective/coll-invitations.module').then(m => m.CollInvitationsModule)
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [WelcomeService],
    children: [
      {
        path: 'musician',
        canActivate: [GuardService, ProfileGuard],
        loadChildren: () => import('app/user/profile/musician/musician.module').then(m => m.MusicianModule)
      },
      {
        path: 'organizer',
        canActivate: [GuardService, ProfileGuard],
        loadChildren: () => import('app/user/profile/organizer/organizer.module').then(m => m.OrganizerModule)
      },
      {
        path: 'agency',
        // canActivate: [GuardService, ProfileGuard],
        loadChildren: () => import('app/user/profile/agency/agency.module').then(m => m.AgencyModule)
      },
      {
        path: 'service',
        canActivate: [GuardService, ProfileGuard],
        loadChildren: () => import('app/user/profile/service/service.module').then(m => m.ServiceModule)
      },
      {
        path: 'collective',
        canActivate: [GuardService, ProfileGuard],
        loadChildren: () => import('app/user/profile/collective/collective.module').then(m => m.CollectiveModule)
      },
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'fill-profile',
    component: FillProfileComponent,
    canActivate: [AgreeService],
    canDeactivate: [GuardSave]
  },
  {
    path: '_admin/dashboard',
    loadChildren: () => import('app/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AdminGuard]
  },
  {path: 'reset', component: NewPasswordComponent},
  {path: 'forgot', component: ForgotPasswordComponent},
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}
