import { Component, OnInit, ViewChild, isDevMode } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { passwordValidator, nameValidator } from 'app/_service/validators';
import { AlertComponent } from 'app/user/shared/shared.module';
import { MusicianProfileService } from 'app/_service/musician-profile/musician-profile.service';
import { UserService } from 'app/_service/user.service';
import { ROLES } from 'app/constants';

import { environment } from 'environments/environment';
import { CommonService } from 'app/_service/common.service';
import { Subscription } from 'rxjs';
import { ReCaptchaV3Service} from 'ng-recaptcha';
import { EMAIL_PATTERN } from 'app/constants';

@Component({
  moduleId: module.id,
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  @ViewChild('alert', { static: true }) private alert: AlertComponent;
  public _apiUrl = environment.apiUrl;
  submitForm = true;
  shouldCheck = false;
  ROLES = ROLES;
  passwordMessage = '';
  confirmPassword: FormControl = this.fb.control('', [Validators.required]);
  password: FormControl = this.fb.control('', [Validators.required, passwordValidator]);
  /* tslint:disable */
  lastName: FormControl = this.fb.control('', [Validators.required, nameValidator]);
  firstName: FormControl = this.fb.control('', [Validators.required, nameValidator]);
  /* tslint:enable */
  role: FormControl = this.fb.control('', Validators.required);
  email: FormControl = this.fb.control('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]);
  // recaptchaReactive: FormControl = this.fb.control(null, Validators.required);
  recaptchaReactive;
  regForm: FormGroup = this.fb.group({
    role: this.role,
    lastName: this.lastName,
    firstName: this.firstName,
    email: this.email,
    password: this.password,
    confirmPassword: this.confirmPassword,
    // recaptchaReactive: this.recaptchaReactive
  }, {
    validator: this.equalPassword
  });
  userRole = false;
  registrationSucceeded: boolean = false;
  
  private singleExecutionSubscription: Subscription;
  private captchaSubscription: Subscription;

  constructor(public fb: FormBuilder,
    private router: Router,
    public cs: CommonService,
    public musicianProfileService: MusicianProfileService,
    public ts: TranslateService,
    public userService: UserService,
    private recaptchaV3Service: ReCaptchaV3Service) {

  }

  ngOnInit(): void {
    this.confirmPassword.valueChanges.subscribe((value) => {
      if (value !== this.password.value) {
        this.passwordMessage = 'danger';
      } else {
        this.passwordMessage = 'success';
      }
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });

    // this.role.setValue('Musician'); ;
    // this.role.disable();
  }

  ngOnDestroy(){
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
      this.singleExecutionSubscription = null;
    }
  }

  buttonHide() {
    let hideClass = '';
    if (this.cs.isIEorOpera()) {
      hideClass = 'element_hidden';
    }
    return hideClass;
  }
  public equalPassword({ value }: FormGroup): { [key: string]: any } {
    const no = value.newPassword !== value.confirmNewPassword;
    return no ? { 'passwordNoEqual': { name } } : null;
  }
  social(providerName) {
    // if (this.role.invalid) {
    //   this.userRole = true;
    //   return;
    // }
    this.userRole = false;

    // if user click social button, clear form and alert
    this.regForm.reset();
    this.shouldCheck = false;
    this.alert.resetMessage();
    this.userService.socialLogin(providerName).subscribe(() => {
      let path = this.userService.getProfilePath();
      if (path.trim().match(/^profile\/$/)) {
        this.router.navigateByUrl('fill-profile');
        return;
      }
      this.router.navigateByUrl(path);
    }, err => {
      if (err.status === 0) {
        this.alert.showMessage(err.message, 'danger', true);
      }
    });
  }
  save(value): void {
    if (this.regForm.invalid) {
      this.shouldCheck = true;
      return;
    }
    this.singleExecutionSubscription =  this.recaptchaV3Service.execute("signUpAction")
      .subscribe((token) => {
        value = { ...value, "recaptchaReactive": token}
        console.log(value);
        this.submitForm = false;
        this.userService
          .signUp(value)
          .subscribe(
            () => {
              this.registrationSucceeded = true;
              this.router.navigate(['signin'], { queryParams: { user: value.email } });
            },
            err => {
              this.submitForm = true;
              console.log(err);
              if (err.status === 400 && err.error === 'duplicated') {
                this.alert.showMessage('SIGN_IN.DUPLICATED_EMAIL', 'danger');
                return;
              }
              if (err.status === 400 && err.error === 'captcha') {
                console.log(err.error);
                this.alert.showMessage('SIGN_IN.CAPTCHA_FAILED', 'danger');
                return;
              }
              this.regForm.reset(this.regForm.value);
              this.alert.showMessage(err.error.message, 'danger', true);
            },
            () => {
              this.submitForm = true;
            }
          );
      }
      );
  }
}
