import { Component, isDevMode, OnInit, ViewChild, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'app/shared/shared-components.module';

import { CommonService } from 'app/_service/common.service';
import { UserService } from 'app/_service/user.service';
import { MusicianProfileService } from 'app/_service/musician-profile/musician-profile.service';
import { concat, Observable, Subject } from 'rxjs';
import { AGE_INTERVAL, CURRENCY_TYPE, ROLES, TITLE_DICT, MAX_SIZE } from 'app/constants';
import { FileService } from 'app/_service/file.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {
  maxSize = MAX_SIZE;
  // TODO: move to @Input
  filter = 'application/pdf, image/png, image/jpeg, image/gif, image/tiff';
  maxFileError = false;
  files = [];
  

  @Input() parentPropertyName: string;
  @Input() dataName: string;
  @Input() inputLabels: any;
  // @Input() 'skills': any = false;
  // @Input() 'reviews': any = false;
  @Input() parentPropertyValue: string;
  @Input() readonly: boolean = false;
  @ViewChild('file', { static: true }) private fileElement: any;
  constructor(
    private cs: CommonService,
    //private userService: UserService,
    private fileService: FileService,
    private musicianProfileService: MusicianProfileService,
    private modalService: NgbModal
  ) 
  { }

  ngOnInit() {
    console.log(this.dataName);
    this.checkRequiredAttributes(this.parentPropertyName);
    let data: Observable<any>;
    data = this.dataName.indexOf('offers_files') === -1 ? this.musicianProfileService.getData(this.dataName) : this.musicianProfileService.getData(this.dataName, this.parentPropertyValue);
     data.subscribe(result => {
        this.files = result.slice();
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      });
  }

  checkRequiredAttributes(input) {
    if(input === null) {
       throw new Error(`Attribute '${input}' is required`);
    }
 }

  /*
  clickFile(event) {
    if (!this.id) {
      event.stopPropagation();
      const modalRef = this.modalService.open(ModalComponent);
      modalRef.componentInstance.type = 'message';
      modalRef.componentInstance.message = "ORGANIZER.OFFER.SAVE_CHANGES";      
      return false;;
    }

    return true;
  }
*/  

clickAdd() {
  let inputEl: HTMLInputElement = this.fileElement.nativeElement;
  inputEl.value = null;
  inputEl.click();
}

addFile(event) {
  event.stopPropagation();

  let fileList: FileList = event.target.files;
  let fileCount: number = fileList.length;

  if (fileCount < 1) { // a file was selected
    return;
  }
  let file = fileList[0];

  if (!file.type || this.filter.indexOf(file.type) === -1) {
    const modalRef = this.modalService.open(ModalComponent, { backdrop: 'static'});
    modalRef.componentInstance.type = 'message';
    modalRef.componentInstance.message = 'COMMON.FILEFORMAT_NOTSUPPORTED';
    return;
  }

  if (file.size > this.maxSize) {
    const modalRef = this.modalService.open(ModalComponent, { backdrop: 'static'});
    modalRef.componentInstance.type = 'maxSize';
    modalRef.componentInstance.size = `${this.maxSize / 1000000}MB`;
    return;
  }

  this.files.push(file);

  if (this.parentPropertyValue)
    this.uploadFiles().subscribe();
}

uploadFiles() {
  return Observable.create( (observer) => {
    if (!this.files.length)
      observer.next();

    for (let index = 0; index < this.files.length; index++) {
      const file = this.files[index];
     
      if (!(file instanceof File))
        continue;
      
        let fileObj: any = {};
        this.cs.togleSpiter(true);
        this.maxFileError = false;
        fileObj.name = file.name;
        let myReader: FileReader = new FileReader();
        myReader.onloadend = () => {
          //this.fileElement.nativeElement.value = '';
          //this.userService.uploadFile(file.name, myReader.result).subscribe(objectResult => {
            this.fileService.uploadFile(file.name, myReader.result).subscribe(objectResult => {
              fileObj.href = objectResult.name;
              fileObj[this.parentPropertyName] = this.parentPropertyValue
              fileObj.type = file.type;
            let newData: Observable<any>;
            this.dataName.indexOf('offers_files') === -1  ? newData = this.musicianProfileService.createData(this.dataName, fileObj) :  newData = this.musicianProfileService.createData(this.dataName, fileObj, fileObj.offer);
            newData.subscribe(
                res => {
                  this.cs.togleSpiter(false);
                  this.files[index] = { ...res };

                  observer.next();
                }, (error) => {
                  this.cs.togleSpiter(false);
                  console.log(`file ${fileObj.name} was not saved. ${error}`);
                  observer.error(error);
                }
              );
            }, (error) => {
              this.cs.togleSpiter(false);
              console.log(`file ${fileObj.name} was not saved. ${error}`);
              observer.error(error);
            });
        };
        myReader.readAsDataURL(file);
    }  
    });
}
removeFile(file, index): void {
  // if the file did not uploaded yet    
  if (this.isFileObject(file)) {
    this.files.splice(index, 1)
    return;
  }

  if (!file.id) {
    return;
  }

  this.cs.togleSpiter(true);
  // this.userService
  this.fileService
    .removeFile(file.href).subscribe(() => {
      let removeData: Observable<any>;
      this.dataName.indexOf('offers_files') === -1  ? removeData = this.musicianProfileService.deleteData(this.dataName, file.id) : removeData = this.musicianProfileService.deleteData(this.dataName, file.id, file.offer);
      removeData.subscribe(
        () => {
          this.files.splice(index, 1);
          this.cs.togleSpiter(false);
        }, (error) => {
          console.error(`The file ${file.name} was not deleted. ${error}`);
          this.cs.togleSpiter(false);
        }
      );
    }, (error) => {
      console.error(`The file ${file.name} was not deleted. ${error}`);
      this.cs.togleSpiter(false);
    });
}

getFileUrl(path: string, name: string): string {
  //return this.userService.getFileUrl(path, name);
  return this.fileService.getFileUrl(path, name);
}

isFileObject(file): boolean {
  return file instanceof File;
}

}
