<div class="aside__list-header d-sm-none">
  <p>{{'PROFILE.TITLE'| translate | uppercase}}</p>
  <button class="btn-points d-sm-none" (click)="listShowActive = !listShowActive">
      <i class="fas " [ngClass]="{'fa-bars' : !listShowActive, 'fa-times': listShowActive}" aria-hidden="true"></i>
  </button>
</div>
<ul class="aside__list d-sm-block" [@smoothCollapse]="!listShowActive?'initial':'final'" appShowMenu>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="agency/personal-data"
       (click)="listShowActive = false">
      {{'PROFILE.PERSONAL.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
      <a class="aside__link" routerLinkActive="active" routerLink="agency/profile"
         (click)="listShowActive = false">
        {{'AGENCY.TITLE' | translate | uppercase}}
      </a>
    </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="agency/account"
       (click)="listShowActive = false">
      {{'PROFILE.ACCOUNT.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="agency/remove"
       (click)="listShowActive = false">
      {{'PROFILE.ACCOUNT.DELETE_PROFILE' | translate | uppercase}}
    </a>
  </li>
</ul>

<ul class="aside__list d-none  d-sm-block">
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="agency/personal-data">
      {{'PROFILE.PERSONAL.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
      <a class="aside__link" routerLinkActive="active" routerLink="agency/profile">
        {{'AGENCY.TITLE' | translate | uppercase}}
      </a>
    </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="agency/account">
      {{'PROFILE.ACCOUNT.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="agency/remove">
      {{'PROFILE.ACCOUNT.DELETE_PROFILE' | translate | uppercase}}
    </a>
  </li>
</ul>
