import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {UserService} from 'app/_service/user.service';
import {MusicianProfileService} from 'app/_service/musician-profile/musician-profile.service';
import {take} from 'rxjs/operators';
@Injectable()
export class GuardService implements CanActivate {
  constructor(public userService: UserService,
              private ms: MusicianProfileService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.ms.resetCache();
    return new Promise((resolve) => {
      this.userService.getUser$.pipe(take(1)).subscribe((user: User) => {
        const role = this.userService.getRole();
        const pathArray = route.url.find(element => {
          let path = element.path;
          path = path.replace(/^mus$/, 'musician');
          path = path.replace(/^org$/, 'organizer' );
          path = path.replace(/^coll$/, 'collective' );
          return path === role;
        });
        if (!pathArray) {
          this.router.navigateByUrl('/');
          resolve(false);
        }
        resolve(true);
      });
    });
  }
}
