import {Component, isDevMode, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService, FakeMissingTranslationHandler} from '@ngx-translate/core';
import {UserService} from 'app/_service/user.service';
import {DataService} from 'app/_service/backend/data.service';
import {NotificationService} from 'app/_service/notification.service';
import {Subject, Unsubscribable} from 'rxjs';
import { AdminGuard } from 'app/admin/admin-guard';
import { AlertComponent } from 'app/user/shared/shared.module';
import { ROLES } from 'app/constants';
import { trigger, state, style, transition, animate } from '@angular/animations';


@Component({
  selector: 'app-menu-app',
  templateUrl: './menu-app.component.html',
  styleUrls: ['./menu-app.component.scss'],
  animations: [
    trigger('smoothCollapse', [
      state('initial', style({
        height:'0',
        width: '0',
        overflow:'hidden',
        opacity:'0'
      })),
      state('final', style({
        overflow:'hidden',
        opacity:'1'
      })),
      transition('initial=>final', animate('.1s')),
      transition('final=>initial', animate('.1s'))
    ]),
  ]
})
export class MenuAppComponent implements OnInit, OnDestroy {
  @ViewChild('alert', { static: true }) private alert: AlertComponent;
  
  public firstName = '';
  public profilePath = '';
  public auth = false;
  public isCollapsed = true;
  public role = '';
  public newCnt = 0;
  blocked = false;
  isAdmin: boolean = false;
  messages: Subject<any>;
  private subscriptions: Unsubscribable[] = [];
  active = false;

  constructor(public userService: UserService,
              public router: Router,
              private ds: DataService,
              private translate: TranslateService,
              private ns: NotificationService,
              private adminGuard: AdminGuard
              ) {  }

  ngOnInit() {
    let sub = this.userService.getUser$.subscribe(
      (user: User) => {
        if (user === null) {
          this.auth = false;
          this.firstName = '';
          this.profilePath = '';
          this.alert.resetMessage();
          this.blocked = false;
          return;
        }
        this.auth = true;
        this.firstName = user.firstName;
        this.profilePath = this.userService.getProfilePath();
        this.role = this.userService.getRole();
        this.isAdmin = user.systemRole > 1;
        if (user.status === 2) {
          if (user.userRole === ROLES[1][0]) {
            this.alert.showMessage('COMMON.BLOCKED_ORG_ALERT', 'danger', true);
          } else {
            this.alert.showMessage('COMMON.BLOCKED_MUS_ALERT', 'danger', true);
          }
          this.blocked = true;
        }

        // Update notifications counter
        // this.ns.updateNewCount();
      }, err => {
        if (isDevMode()) {
          console.log(err);
          this.auth = false;
          this.firstName = '';
          this.profilePath = '';
        }
      });
      this.subscriptions.push(sub);

      sub = this.ns.newCount$.subscribe( (count: number) => {
        this.newCnt = count;
      })
      this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  getToProfile() {
    let path = this.userService.getProfilePath();
    if (path.trim().match(/^profile\/$/)) {
      this.router.navigateByUrl('fill-profile');
      return;
    }
    this.router.navigateByUrl(path);
  }
  getToOffer(status) {
    this.router.navigate(['/offer'], { queryParams: { status }});
  }
  public changeLanguage(lang: string): void {
    this.translate.use(lang);
    let htmlTag = document.querySelector('html');
    htmlTag.setAttribute('lang', lang);
  }

  navBarButtonAction(event){
    event.preventDefault();
    this.isCollapsed = !this.isCollapsed; 
    this.active = !this.active;
  }

}
