import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  isDevMode,
  OnChanges,
  Output,
  QueryList,
  OnInit,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder} from '@angular/forms';
import {sampleTime} from 'rxjs/operators';

import {TranslateService} from '@ngx-translate/core';
import {DictionaryService} from 'app/_service/dictionary.service';

@Component({
  selector: 'app-suggest',
  templateUrl: './suggest.component.html',
  styleUrls: ['./suggest.component.scss'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SuggestComponent), multi: true}
  ]
})
export class SuggestComponent implements OnInit, ControlValueAccessor, AfterViewInit, OnChanges {
  @ViewChildren('list') list: QueryList<ElementRef>;
  @Output() idElement = new EventEmitter();
  @Output() keyEvent = new EventEmitter<Array<string>>();
  @Input() dirty: boolean;
  @Input() disabledCustom: boolean = false;
  @Input() showSpinner: boolean;
  @Input() findAutoCompleteList: Array<any>;
  @ViewChild('elem', { static: true }) el: ElementRef;
  private _onChange: Function;
  private _onTouched: Function;
  public showList = false;
  public bypass = false;
  public lang = this.ts.defaultLang;
  public lists: any = [];
  public itemList: any;
  self = false;
  firstOpen = false;
  searchDict = this.fb.control({value: ''});
  public activeItemIndex = 0;
  @HostListener('document:click', ['$event.target'])
  onClick() {
    if (this.self) {
      this.self = false;
      return;
    }
    this.showList = false;
  };

  // @HostListener('focus', ['$event'])
  // onFocus() {
  //   this.showList = true;
  // }

  constructor(
    public ds: DictionaryService,
    private ts: TranslateService,
    private fb: FormBuilder) {

  }
  ngOnInit() {
    let initValue = 0;
    this.searchDict.valueChanges.pipe(sampleTime(500)).subscribe(() => {
      if (this.bypass) {
        this.bypass = false;
        return;
      }
      const value = this.searchDict.value;
      if (value && value.length > 2 && initValue > 0) {
        this.keyEvent.emit(value);
      }
      initValue += 1;
    });
  }
  ngOnChanges(changes: any): void {
    if (changes.findAutoCompleteList && changes.findAutoCompleteList.currentValue) {
      this.lists = changes.findAutoCompleteList.currentValue;
      this.showList = true;
    }
    const disabledCustom = changes.disabledCustom;
    if ( disabledCustom ) {
      disabledCustom.currentValue ? this.searchDict.disable() : this.searchDict.enable();
    }
  }
  focus() {
    this.firstOpen = true;
  }
  blur() {
    this.firstOpen = false;
  }
  clearInput() {
    this.searchDict.reset();
  }
  ngAfterViewInit() {
    this.list.changes.subscribe(newList => {
        this.itemList = newList.first;
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      }
    )
  }



  setValue( event, item?) {
    let list = item !== undefined && this.lists.length > 0 ? item : this.lists[this.activeItemIndex];
    if (!list) {
      return;
    }
    this.idElement.emit(list.id);
    this.showList = false;
    this.bypass = true;
    this.activeItemIndex = 0;
    this.writeValue(list[this.lang]);
    this._onChange(list[this.lang]);
    if (event) {
    this.onEvent(event);
    }
  }

  writeValue(value) {
    this.searchDict.patchValue(value);
    this.searchDict.markAsDirty();
  }
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
    this.searchDict.valueChanges.subscribe(() => {
      this._onChange(this.searchDict.value);
    });
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  listFocus() {
    const item = this.itemList.nativeElement.querySelector('.active');
    if (!item) {
      return;
    }
    let  resultUp = item.getBoundingClientRect().top - this.itemList.nativeElement.getBoundingClientRect().top - 100;
    let  resultDown = this.itemList.nativeElement.getBoundingClientRect().bottom - item.getBoundingClientRect().bottom - 100;
    if (resultUp < 0) {
      this.itemList.nativeElement.scrollTop -= Math.abs(resultUp);
    }
    if (resultDown < 0) {
      this.itemList.nativeElement.scrollTop += Math.abs(resultDown);
    }
  }

  incrementLink() {
    let len = this.lists.length - 1;
    if (this.activeItemIndex < len) {
      this.activeItemIndex += 1;
    } else {
      this.activeItemIndex = 0;
    }
    setTimeout(() => { this.listFocus(); }, 5);
  }

  decrementLink() {
    let len = this.lists.length - 1;
    if (this.activeItemIndex > 0) {
      this.activeItemIndex -= 1;
    } else {
      this.activeItemIndex = len;
    }
    setTimeout(() => { this.listFocus(); }, 5);
  }
  onEvent(event) {
    event.stopPropagation();
  }

}

