import {Component, isDevMode, OnInit} from '@angular/core';
import {MusicianProfileService} from 'app/_service/musician-profile/musician-profile.service';
import {UserService} from 'app/_service/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from 'app/_service/backend/api.service';
import {CommonService} from 'app/_service/common.service';
import {concat, Observable} from 'rxjs';

import {DictionaryService} from 'app/_service/dictionary.service';
import {map} from 'rxjs/operators';
import * as moment from 'moment';
@Component({
  selector: 'app-my-offer',
  templateUrl: './my-offer.component.html',
  styleUrls: ['./my-offer.component.scss']
})
export class MyOfferComponent implements OnInit {
  offers = [];
  offersMap = [];
  professions = {};
  specializations = {};
  collectiveType = {};
  status = 'current';

  constructor(private musicianProfileService: MusicianProfileService,
              private us: UserService,
              public ds: DictionaryService,
              public cs: CommonService,
              private api: ApiService,
              private ts: TranslateService,
              //private ws: WebsocketService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.status = params['status'];
      this.offers.length = 0;
      this.musicianProfileService.getAllOffers(this.status).subscribe(
        (offersResp) => {
          this.cs.togleSpiter(false);
          const musician = Array.isArray(offersResp.musician) ? offersResp.musician : [];
          const collective = Array.isArray(offersResp.collective) ? offersResp.collective : [];
          this.offers.push(...musician.map(item => {
            item.profile = 'Musician';
            return item
          }));
          this.offers.push(...collective.map(item => {
            item.profile = 'Collective';
            return item
          }));
          this.offers.sort( (aOffer, bOffer) => {
            if (moment(aOffer.updatedAt).isBefore(moment(bOffer.updatedAt))) {
              return 1;
            }
            if (moment(aOffer.updatedAt).isAfter(moment(bOffer.updatedAt))) {
              return -1;
            }
            // a должно быть равным b
            return 0;
          })
        }, err => {
          this.cs.togleSpiter(false);
          if (isDevMode()) {
            console.log(err);
          }
        }
      );
    });
    /*
    this.ws.isNew$.subscribe(() => {
      this.getNewNotification();
    });
    */
    this.initDictionaries().subscribe(() => {
    }, () => {
    });
    this.cs.togleSpiter(true);
    this.ts.onLangChange.subscribe(() => {
      this.initDictionaries().subscribe(
        () => {
        },
        err => {
          if (isDevMode()) {
            console.log(err);
          }
        }, () => {
        });
    });
  }

  isNew(id) {
    // console.log('id', id);
    // console.log('offersMap', this.offersMap);
    return this.offersMap.indexOf(id) > -1;
  }

  openOffers(item) {
    this.cs.togleSpiter(true);
    switch (item.profile) {
      case 'Musician':
        this.router.navigate(['offer/my-offer'], {queryParams: {'id': item.id}});
        break;
      case 'Collective':
        this.router.navigate(['offer/collective-offer'], {queryParams: {'id': item.id}});
        break;
    }
  }

  openInvitation($event, item) {
    this.cs.togleSpiter(true);
    $event.stopPropagation();
    switch (item.profile) {
      case 'Musician':
        this.router.navigate(['/invitations/org/list', item.id]);
        break;
      case 'Collective':
        this.router.navigate(['/invitations/org/coll/list', item.id]);
        break;
    }
  }
/*
  getNewNotification() {
    this.api.get('api/notification/new-notifications').subscribe(result => {
      if (!Array.isArray(result)) {
        return;
      }
      this.offersMap = result.map(item => {
        if (item.path.indexOf('invitations/org/list/') > -1) {
          return +item.path.replace('invitations/org/list/', '');
        }
      });
    })
  }
*/
  private initDictionaries(): Observable<any> {
    return concat(
      this.ds.getDictionaryNames('dic_professions')
        .pipe(
          map(names => this.professions = names)
        ),
      this.ds.getDictionaryNames('dic_collective_types')
        .pipe(
          map(dic => {
            this.collectiveType = dic;
          })
        ),
      this.ds
        .getDictionaryNames(
          'dic_specializations')
        .pipe(
          map(
            dic => {
              this.specializations = dic;
            })
        )
    );
  }
}
