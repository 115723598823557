<app-alert class="blocked-alert" #alert></app-alert>
<header [ngClass]="{'blocked-header': blocked}">
  <div class="container">
    <nav class="navbar navbar-expand-xl navbar-light header__nav navbar-main-sos">
      <a class="navbar-brand header__logo mr-4 py-0" [routerLink]="['/']">
        <div class="img-brand"></div>
      </a>
      <button class="navbar-toggler " [ngClass]="{'active' : active}" 
        (click)="navBarButtonAction($event)">
        <!-- <span class="navbar-toggler-icon"></span> -->
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <div class="circle nav-circle" *ngIf="this.newCnt > 0 && !active">{{this.newCnt}}</div>
      </button>
      <div class="d-xl-none navbar-collapse header__navbar" [@smoothCollapse]="isCollapsed ? 'initial' : 'final'" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item  header__navbar-item" (click)="navBarButtonAction($event)">
            <a class="nav-link" [routerLink]="['home']">
              <span>{{ 'MENU.HOME' | translate }}</span>
            </a>
          </li>
          <!-- For ORGANIZER -->
          <li class="nav-item link drobdown_li" ngbDropdown placement="bottom-right"
            *ngIf="userService.getRole() == 'organizer'">
            <a class="nav-link" ngbDropdownToggle><span
                class="text-nowrap">{{'ORGANIZER.OFFER.MY_OFFER' | translate}}</span></a>
            <div (click)="navBarButtonAction($event)" ngbDropdownMenu>
              <a class="dropdown-item" [routerLink]="['/offer/current']"><span
                  class="text-nowrap">{{ 'ORGANIZER.OFFER.ACTIVE' | translate }}</span></a>
              <a class="dropdown-item" [routerLink]="['/offer/archive']"><span
                  class="text-nowrap">{{ 'ORGANIZER.OFFER.CLOSED' | translate }}</span></a>
            </div>
          </li>
          <li class="nav-item header__navbar-item" (click)="navBarButtonAction($event)"
            *ngIf="userService.getRole() == 'organizer'">
            <a class="nav-link" [routerLink]="['tour-calendar']">
              <span class="text-nowrap">{{ 'ORGANIZER.TOUR_CALENDAR' | translate }}</span></a>
          </li>
          <!-- For AGENCY -->
          <li class="nav-item header__navbar-item" (click)="navBarButtonAction($event)"
            *ngIf="userService.getRole() == 'agency'">
            <a class="nav-link" [routerLink]="['/my-musicians']"><span
                class="text-nowrap">{{ 'MENU.MY_ARTISTS' | translate }}</span></a>
            <!-- <a class="nav-link" ngbDropdownToggle><span
                class="text-nowrap">{{'MENU.MY_ARTISTS' | translate}}</span></a>
            <div (click)="navBarButtonAction($event)" ngbDropdownMenu>
              <a class="dropdown-item" [routerLink]="['/my-musicians']"><span
                  class="text-nowrap">{{ 'MENU.MY_MUSICIANS' | translate }}</span></a>
              <a class="dropdown-item" [routerLink]="['/my-collectives']"><span
                  class="text-nowrap">{{ 'MENU.MY_COLLECTIVES' | translate }}</span></a>
            </div> -->
          </li>
          <!-- For MUSICIAN -->
          <li class="nav-item header__navbar-item" (click)="navBarButtonAction($event)"
            *ngIf="userService.getRole() == 'musician'">
            <a class="nav-link" [routerLink]="['/invitations/mus/list']"><span
                class="text-nowrap">{{ 'COMMON.GO_INVITATION' | translate }}</span></a>
          </li>
          <!-- For COLLECTIVE-->
          <li class="nav-item header__navbar-item" (click)="navBarButtonAction($event)"
            *ngIf="userService.getRole() == 'collective'">
            <a class="nav-link" [routerLink]="['/invitations/coll/list']"><span
                class="text-nowrap">{{ 'COMMON.GO_INVITATION' | translate }}</span></a>
          </li>
          <li class="nav-item  header__navbar-item" (click)="navBarButtonAction($event)">
            <a class="nav-link" [routerLink]="['/how-it-works']"><span
                class="text-nowrap">{{ 'MENU.HOW-IT-WORKS' | translate }}</span></a></li>
          <li class="nav-item  header__navbar-item" (click)="navBarButtonAction($event)">
            <a class="nav-link" [routerLink]="['/contacts']"><span>{{'MENU.CONTACTS' | translate}}</span></a>
          </li>
          <li class="nav-item  header__navbar-item" (click)="navBarButtonAction($event)" [hidden]="auth">
            <a class="nav-link" [routerLink]="['signin']"><span>{{'MENU.LOGIN' | translate}}</span></a>
          </li>
          <li class="nav-item header__navbar-item" (click)="navBarButtonAction($event)" [hidden]="auth">
            <a class="nav-link" [routerLink]="['signup']"><span>{{'MENU.SIGNUP' | translate}}</span></a>
          </li>
          <li class="nav-item drobdown_li" ngbDropdown placement="bottom-right" [hidden]="!auth">
            <a class="nav-link link" ngbDropdownToggle>
              <span>{{firstName}}</span></a>
            <div (click)="navBarButtonAction($event)" ngbDropdownMenu>
              <a class="dropdown-item" (click)="getToProfile()"><span>{{'MENU.PROFILE' | translate}}</span></a>
              <a class="dropdown-item" *ngIf="isAdmin" [routerLink]="['_admin/dashboard']"><span>ADMIN
                  DASHBOARD</span></a>
              <a class="dropdown-item" (click)="userService.logout()"><span>{{'MENU.SIGNOUT' | translate}}</span></a>
            </div>
            <!---->
          </li>
          <li class="nav-item link drobdown_li" ngbDropdown placement="bottom-right">
            <a class="nav-link" ngbDropdownToggle><span>{{'MENU.LANG.TITLE' | translate}}</span></a>
            <div (click)="navBarButtonAction($event)" ngbDropdownMenu>
              <a class="dropdown-item" (click)="changeLanguage('it')"><span>{{'MENU.LANG.IT' | translate}}</span></a>
              <a class="dropdown-item" (click)="changeLanguage('en')"><span>{{'MENU.LANG.EN' | translate}}</span></a>
              <a class="dropdown-item" (click)="changeLanguage('ru')"><span>{{'MENU.LANG.RUS' | translate}}</span></a>
            </div>
          </li>
          <li class="nav-item header__navbar-item" style="padding-bottom: 2px;" (click)="navBarButtonAction($event)"
            [hidden]="!auth">
            <a class="nav-link" [routerLink]="['list-notification']">
              <span>
                <!--
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" style="width: 18px; height: 18px; color: #58595b;" data-prefix="far" data-icon="envelope" class="svg-inline--fa fa-envelope fa-1x" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"/></svg>
                -->
                <img style="width: 18px; height: 18px; color: #58595b;" title="{{'COMMON.NOTIFICATIONS' | translate}}"
                  src="assets/icon/png/bell.png">
                <div class="circle" *ngIf="this.newCnt > 0">{{this.newCnt}}</div>
              </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="d-xl-block header__navbar d-none">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item  header__navbar-item">
            <a class="nav-link" [routerLink]="['home']">
              <span>{{ 'MENU.HOME' | translate }}</span>
            </a>
          </li>
          <!-- For ORGANIZER -->
          <li class="nav-item link drobdown_li" ngbDropdown placement="bottom-right"
            *ngIf="userService.getRole() == 'organizer'">
            <a class="nav-link" ngbDropdownToggle><span
                class="text-nowrap">{{'ORGANIZER.OFFER.MY_OFFER' | translate}}</span></a>
            <div ngbDropdownMenu>
              <a class="dropdown-item" [routerLink]="['/offer/current']"><span
                  class="text-nowrap">{{ 'ORGANIZER.OFFER.ACTIVE' | translate }}</span></a>
              <a class="dropdown-item" [routerLink]="['/offer/archive']"><span
                  class="text-nowrap">{{ 'ORGANIZER.OFFER.CLOSED' | translate }}</span></a>
            </div>
          </li>
          <li class="nav-item header__navbar-item"
            *ngIf="userService.getRole() == 'organizer'">
            <a class="nav-link" [routerLink]="['tour-calendar']">
              <span class="text-nowrap">{{ 'ORGANIZER.TOUR_CALENDAR' | translate }}</span></a>
          </li>
          <!-- For AGENCY -->
          <li class="nav-item header__navbar-item"
            *ngIf="userService.getRole() == 'agency'">
            <a class="nav-link" [routerLink]="['/my-musicians']"><span
                class="text-nowrap">{{ 'MENU.MY_ARTISTS' | translate }}</span></a>
            <!-- <a class="nav-link" ngbDropdownToggle><span
                class="text-nowrap">{{'MENU.MY_ARTISTS' | translate}}</span></a>
            <div (click)="navBarButtonAction($event)" ngbDropdownMenu>
              <a class="dropdown-item" [routerLink]="['/my-musicians']"><span
                  class="text-nowrap">{{ 'MENU.MY_MUSICIANS' | translate }}</span></a>
              <a class="dropdown-item" [routerLink]="['/my-collectives']"><span
                  class="text-nowrap">{{ 'MENU.MY_COLLECTIVES' | translate }}</span></a>
            </div> -->
          </li>
          <!-- For MUSICIAN -->
          <li class="nav-item header__navbar-item"
            *ngIf="userService.getRole() == 'musician'">
            <a class="nav-link" [routerLink]="['/invitations/mus/list']"><span
                class="text-nowrap">{{ 'COMMON.GO_INVITATION' | translate }}</span></a>
          </li>
          <!-- For COLLECTIVE-->
          <li class="nav-item header__navbar-item"
            *ngIf="userService.getRole() == 'collective'">
            <a class="nav-link" [routerLink]="['/invitations/coll/list']"><span
                class="text-nowrap">{{ 'COMMON.GO_INVITATION' | translate }}</span></a>
          </li>
          <li class="nav-item  header__navbar-item">
            <a class="nav-link" [routerLink]="['/how-it-works']"><span
                class="text-nowrap">{{ 'MENU.HOW-IT-WORKS' | translate }}</span></a></li>
          <li class="nav-item  header__navbar-item">
            <a class="nav-link" [routerLink]="['/contacts']"><span>{{'MENU.CONTACTS' | translate}}</span></a>
          </li>
          <li class="nav-item  header__navbar-item" [hidden]="auth">
            <a class="nav-link" [routerLink]="['signin']"><span>{{'MENU.LOGIN' | translate}}</span></a>
          </li>
          <li class="nav-item header__navbar-item" [hidden]="auth">
            <a class="nav-link" [routerLink]="['signup']"><span>{{'MENU.SIGNUP' | translate}}</span></a>
          </li>
          <li class="nav-item drobdown_li" ngbDropdown placement="bottom-right" [hidden]="!auth">
            <a class="nav-link link" ngbDropdownToggle>
              <span>{{firstName}}</span></a>
            <div  ngbDropdownMenu>
              <a class="dropdown-item" (click)="getToProfile()"><span>{{'MENU.PROFILE' | translate}}</span></a>
              <a class="dropdown-item" *ngIf="isAdmin" [routerLink]="['_admin/dashboard']"><span>ADMIN
                  DASHBOARD</span></a>
              <a class="dropdown-item" (click)="userService.logout()"><span>{{'MENU.SIGNOUT' | translate}}</span></a>
            </div>
            <!---->
          </li>
          <li class="nav-item link drobdown_li" ngbDropdown placement="bottom-right">
            <a class="nav-link" ngbDropdownToggle><span>{{'MENU.LANG.TITLE' | translate}}</span></a>
            <div  ngbDropdownMenu>
              <a class="dropdown-item" (click)="changeLanguage('it')"><span>{{'MENU.LANG.IT' | translate}}</span></a>
              <a class="dropdown-item" (click)="changeLanguage('en')"><span>{{'MENU.LANG.EN' | translate}}</span></a>
              <a class="dropdown-item" (click)="changeLanguage('ru')"><span>{{'MENU.LANG.RUS' | translate}}</span></a>
            </div>
          </li>
          <li class="nav-item header__navbar-item" style="padding-bottom: 2px;" 
            [hidden]="!auth">
            <a class="nav-link" [routerLink]="['list-notification']">
              <span>
                <!--
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" style="width: 18px; height: 18px; color: #58595b;" data-prefix="far" data-icon="envelope" class="svg-inline--fa fa-envelope fa-1x" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"/></svg>
                -->
                <img style="width: 18px; height: 18px; color: #58595b;" title="{{'COMMON.NOTIFICATIONS' | translate}}"
                  src="assets/icon/png/bell.png">
                <div class="circle" *ngIf="this.newCnt > 0">{{this.newCnt}}</div>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>