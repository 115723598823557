<section class="completion-resume">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 (click)="goToSearch($event)" [hidden]="offerId === '0' && role != 'info'"><<<
          <span  class="text_click">{{'COMMON.BACK' | translate}}</span>
        </h2>
      </div>
    </div>
  </div>
</section>
<div class="container main-container">
  <div class="row">
    <div class="col-12 col-md-4 col-lg-3">
      <aside class="border-sm-none">
        <div class="aside__photo" [ngClass]="{'gray_background': !avatarUrl}">
          <article class="avatar d-flex justify-content-center align-items-center"  style="min-height: 177px">
            <img [src]="avatarUrl" [hidden]="!avatarUrl" class="aside__avatar" alt="avatar" >
            <img src="assets/icon/png/icon user.png" style="width: 35%; height: 35%;"
                class="aside__avatar center-block" alt="avatar" [hidden]="avatarUrl">
          </article>
        </div>
        <div class="text-center" [hidden]="!initialized || offerId === '0' || offerId === '00'">
          <button class="btn rounded-0 form-user-data__btn btn-outline-black-invert"
                  [disabled]="submitButton"
                  style="width: 80%"
                  (click)="inviteMusician($event)"
                  [hidden]="invitation" >
            <span [hidden]="submitButton">{{'COMMON.INVITE' | translate}}</span>
            <i [hidden]='!submitButton' class="fas fa-spinner fa-pulse fa-fw"></i>
          </button>
          <div class="text-center color_title mt-3 font-weight-bold" [hidden]="!invitation">
            <span>{{'COMMON.INVITED' | translate}}</span>
          </div>
        </div>
      </aside>
    </div>
    <div class="col-12 col-md-8 col-lg-9">
      <!--
      <ngb-alert [type]="'success'" [hidden]="!invitation">{{'INVITATIONS.SENT'| translate}}</ngb-alert>
      -->  
      <article class="profile-content border-sm-none container p-3 m-0 mb-2 row">
        <h1 class="text-left col-12 mb-3 resume-user-name">{{profile?.firstName}}  {{profile?.lastName}}</h1>
        <div class="col-12 col-md-5 mb-3">
          <span>{{'PROFILE.SKILLS.TITLE-DICT' | translate}}: </span>
          <span> {{profile?.title | translate}}</span>
        </div>
        <div class="col-12 col-md-7 mb-3">
          <span>{{'PROFILE.SKILLS.EXPERIENCE' | translate}}: </span>
          <span> {{profile?.experience}}</span>
        </div>
        <div class="col-12 col-md-5">
          <span>{{'PROFILE.PERSONAL.YEAR_OF_BIRTH' | translate}}: </span>
          <span> {{profile?.yearOfBirth}}</span>
        </div>
        <div class="col-12 col-md-7">
          <span>{{'PROFILE.PERSONAL.NATIONALITY' | translate}}: </span>
          <span> {{countriesDic[profile?.nationality]}}</span>
        </div>
        <div class="col-12 col-md-5">
          <!--<span>Email:</span>-->
          <!--<span> {{profile?.email}}</span>-->
        </div>
        <div class="col-12 col-md-7">
          <span>{{'PROFILE.PERSONAL.LANGUAGES' | translate}}: </span>
          <span>
            <div  class="ml-1 mr-1" *ngFor="let lang of languages; let last = last" style="display: inline-block">
              <span>{{languagesNames[lang]}}</span>
            <span [hidden]="last">,</span>
            </div>
          </span>
        </div>
      </article>

      <article class="profile-content border-sm-none container p-3 m-0 mb-2" *ngIf="toursArray">
        <p class="color_title mb-4">{{'PROFILE.TOURS.TITLE' | translate |uppercase}}</p>
        <div class="form-user-data__item">
          <div class="row align-items-center mb-4">
            <div class="col-2 col-md-1 repertoire__drop up-down align-items-center" (click)="isCollapsedTours = !isCollapsedTours">
              <i class="fas fa-chevron-right" aria-hidden="true" [hidden]="!isCollapsedTours"></i>
              <i class="fas fa-chevron-down" aria-hidden="true" [hidden]="isCollapsedTours"></i>
            </div>
            <div class="col-8 pl-0 col-md-10 repertoire__line">
              <hr>
            </div>
          </div>
          <div class="row education-edit__item" *ngFor="let item of toursArray let i=index" [hidden]="isCollapsedTours">
            <div class="col-10 point_row col-lg-11 row">
              <div class="col-12 col-sm-4 education-edit__name">
                <p>{{item.venue}}</p>
              </div>
              <div class="col-12 col-sm-6 text-sm-right education-edit__place">
                <p> {{item.dateFrom}} - {{item.dateTo}}</p>
              </div>
            </div>
            <div class="col-12 education-edit__hr">
              <hr>
            </div>
          </div>
        </div>
      </article>

      <article class="profile-content border-sm-none container p-3 m-0 mb-2">
        <p class="color_title mb-4">{{'PROFILE.PROFESSIONAL.PROFESSION' | translate |uppercase}}</p>
        <div class="profession-data"  *ngFor="let profession of profile?.professions;">
          <div class="profession-edit">
            <div class="row profession-edit__content justify-content-between">
              <div class="col-12 col-lg-6 profession-edit__prof-name">
                <h3>{{professionsNames[profession.profId]}}</h3>
                <p class="text-lowercase">{{specializationsNames[profession.specializationId]}}</p>
              </div>
              <div class="col-12 col-lg-4 profession-edit__music-style">
                <p  *ngFor="let styleId of profession?.styles; let l = last">
                   {{ stylesNames[styleId]}}
                  <span [hidden]="l">, </span>
                </p>
              </div>
            </div>
          </div>
          <div class="repertoire">
            <div class="row repertoire__title" (click)="isCollapsedProf[profession.id] = !isCollapsedProf[profession.id]">
              <div class="col-12 pl-0">
                <p class="resume-sec-title">{{'PROFILE.PROFESSIONAL.REPERTOIRE' | translate | uppercase}}</p>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-2 col-lg-1 repertoire__drop up-down align-items-center"
                   (click)="isCollapsedProf[profession.id] = !isCollapsedProf[profession.id]">
                <i class="fas fa-chevron-right" aria-hidden="true" *ngIf="isCollapsedProf[profession.id]" ></i>
                <i class="fas fa-chevron-down" aria-hidden="true" *ngIf="!isCollapsedProf[profession.id]"></i>
              </div>
              <div class="col-10 col-lg-11 repertoire__line align-items-center">
                <hr>
              </div>
            </div>
            <div class="row repertoire__content" *ngFor="let repertoire of profession?.repertoire; let isLast = last"  [hidden]="isCollapsedProf[profession.id]">
              <div class="col-12 col-lg-2 repertoire__genre">
                <p> {{genresDic[repertoire.genreId]}}</p>
                <div class="row d-lg-none">
                  <div class="col-10 p-0">
                    <hr>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-10 row repertoire__item">
                <div class="col-12 col-lg-3 repertoire__name">
                  <p>{{repertoire.composition}}</p>
                </div>
                <div class="col-12 col-lg-3 repertoire__role">
                  <p>{{repertoire.composer}}</p>
                </div>
                <div class="col-12 col-lg-2 repertoire__role">
                  <p>{{repertoire.role}}</p>
                </div>
                <div class="col-12 col-lg-3 repertoire__place">
                  <p>{{repertoire.venue}}</p>
                </div>
                <div class="col-12 col-lg-1 repertoire__date">
                  <p>{{repertoire.year}}</p>
                </div>
              </div>
              <div [hidden]="isLast" class="col-12 p-0" [hidden]="isLast">
                <hr>
              </div>
          </div>
          </div>
        </div>
      </article>
      <article class="profile-content border-sm-none container p-3 m-0 mb-2">
        <p class="color_title mb-4">{{'PROFILE.SKILLS.TITLE' | translate | uppercase}}</p>
        
        <div>
          <p class="resume-sec-title">{{'PROFILE.SKILLS.EDUCATION' | translate | uppercase}}</p>
          <div class="row">
            <div class="col-12">
              <hr>
            </div>
          </div>
          <div class="row education-edit__item" *ngFor="let item of profile?.educations let isLast = last"  [hidden]="isCollapsed">
            <div class="col-12">
              <div class="row">
                <div class="col-12 col-sm-4 education-edit__name">
                  <p>{{item.school_name}}</p>
                </div>
                <div class="col-12 col-sm-5 education-edit__role">
                  <p>{{item.course}}</p>
                </div>
                <div class="col-12 col-sm-3 text-sm-right education-edit__place">
                  <p> {{item.yearFrom}} - {{item.yearTo}}</p>
                </div>
              </div>
            </div>
            <div class="col-12 education-edit__hr" [hidden]="isLast">
              <hr>
            </div>
          </div>
        </div>
        <div class="referenze">
          <p  class="resume-sec-title">{{'PROFILE.SKILLS.CV' | translate | uppercase}}</p>
          <div class="row referenze__head">
            <div class="col-12 referenze__line">
              <hr>
            </div>
          </div>
          <ul class="list-group-sos" [style.marginBottom.px]="10">
            <li class="" *ngFor="let c of cv; let i = index">
              <a [href]="getFileUrl(c.cv_href, c.cv_name)" target="_blank">
                <label class="list-group-item-heading text-field-sos">{{c.cv_name}}</label>
              </a>
            </li>
          </ul>
        </div>

      </article>
      <article class="profile-content border-sm-none container p-3 m-0 mb-2">
        <p class="color_title mb-4">{{'PROFILE.ACHIEVEMENTS.TITLE' | translate}}</p>
        <div class="row awards-edit__item" *ngFor="let item of profile?.achievements; let isLast = last"  [hidden]="isCollapsed">
          <div class="col-12">
            <div class="row">
              <div class="col-12 col-sm-8 awards-edit__name">
                <p>{{item.achievements}}</p>
              </div>
              <div class="col-12 col-sm-1 text-sm-center awards-edit__role">
                <p>{{item.year}}</p>
              </div>
              <div class="col-12 col-sm-2 text-sm-right awards-edit__place">
                <p>{{item.city}}</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 awards-edit__hr" [hidden]="isLast" >
            <hr>
          </div>
        </div>
        <div class="referenze">
          <p  class="resume-sec-title">{{'PROFILE.ACHIEVEMENTS.CERTIFICATES' | translate | uppercase}}</p>
          <div class="row referenze__head">
            <div class="col-12 referenze__line">
              <hr>
            </div>
          </div>
          <ul class="list-group-sos" [style.marginBottom.px]="10">
            <li class="" *ngFor="let cer of certificates; let i = index">
              <a [href]="getFileUrl(cer.cer_href, cer.cer_name)" target="_blank">
                <label class="list-group-item-heading text-field-sos">{{cer.cer_name}}</label>
              </a>
            </li>
          </ul>
        </div>

      </article>
      <article class="profile-content border-sm-none container p-3 m-0">
        <p class="color_title mb-4">{{'PROFILE.REVIEWS.TITLE' | translate |uppercase}}</p>
        <div class="video-view">
          <p class="resume-sec-title">{{'PROFILE.REVIEWS.VIDEO' | translate | uppercase}}</p>
          <div class="row">
            <div class="col-12 video-view__line">
              <hr>
            </div>
          </div>
          <div class="row justify-content-start m-0 p-0">
            <app-video  class="col-11 col-lg-5 ml-lg-2"  [onlyRead]="true" [videoId]="video.videoId" *ngFor="let video of profile?.video"></app-video>
          </div>
        </div>
        <div class="video-view">
          <p class="resume-sec-title">{{'PROFILE.REVIEWS.AUDIO' | translate | uppercase}}</p>
          <div class="row">
            <div class="col-12 video-view__line">
              <hr>
            </div>
          </div>
          <div class="row justify-content-start m-0 p-0">
            <app-soundcloud class="col-11 col-lg-5 ml-lg-2"  *ngFor="let soundTrack of  profile?.audio"  [onlyRead]="true" [link]="soundTrack"></app-soundcloud>
          </div>
        </div>
        <div class="referenze">
          <p  class="resume-sec-title">{{'PROFILE.REVIEWS.REFERENCE' | translate | uppercase}}</p>
          <div class="row referenze__head">
            <div class="col-12 referenze__line">
              <hr>
            </div>
          </div>
          <ul class="list-group-sos" [style.marginBottom.px]="10">
            <li class="" *ngFor="let reference of  references; let i = index">
              <a [href]="getFileUrl(reference.ref_href, reference.ref_name)" target="_blank">
                <label class="list-group-item-heading text-field-sos">{{reference.ref_name}}</label>
              </a>
            </li>
          </ul>
        </div>
        <div class="agencies">
          <p class="resume-sec-title">{{'PROFILE.REVIEWS.AGENCIES' | translate | uppercase}}</p>
          <div class="row agencies__head">
            <div class="col-12 agencies__line">
              <hr>
            </div>
          </div>
          <div class="row agencies__item" *ngFor="let agency of  profile?.mus_agencies">
            <div class="col-10">
              <div class="row">
                <div class="col-12 col-sm-5 agencies__name">
                  <p>{{agency.agency_name}}</p>
                </div>
                <div class="col-12 col-sm-6 agencies__mail">
                  <p>{{agency.agency_email}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
      <div class="text-center" [hidden]="!initialized || offerId === '0' || offerId === '00'">
        <button class="btn rounded-0 form-user-data__btn btn-outline-black-invert"
                [disabled]="submitButton"
                (click)="goToSearch($event)">
            <span [hidden]="submitButton">{{'COMMON.CLOSE' | translate}}</span>
            <i [hidden]='!submitButton' class="fas fa-spinner fa-pulse fa-fw"></i>
          </button>
      </div>
  </div>
  </div>
</div>
