import {Injectable, isDevMode} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';

import {UserService} from 'app/_service/user.service';
import {DataService} from 'app/_service/backend/data.service';
import {CommonService} from 'app/_service/common.service';


@Injectable()
export class AgreeService implements CanActivate {
  constructor(public userService: UserService, private dtS: DataService, private router: Router, private cs: CommonService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.cs.togleSpiter(true);
    //console.log(`route ${route} stare ${state}`);
    return Observable.create(observable => {
      this.cs.togleSpiter(false);
      this.dtS.agreed_acquainte().subscribe((result) => {
        const {agreed} = result;
        if (!agreed) {
          observable.next(false);
          this.router.navigateByUrl('/agree');
        } else {
          observable.next(true);
        }
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
        this.cs.togleSpiter(false);
        observable.next(false);
        this.router.navigateByUrl('/');
      });
    });
  }
}
