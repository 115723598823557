import { Injectable, isDevMode } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ApiService } from 'app/_service/backend/api.service';
import { UserService } from 'app/_service/user.service';

@Injectable()
export class ToursSearchService {
  private _searchToursResultList = new Subject();
  private _searchToursParams: Object;
  public searchToursResultList$ = this._searchToursResultList.asObservable();

  constructor(
    private api: ApiService,
    private userService: UserService
    ) {
    this.userService.loginLogout$.subscribe(() => {
      this.clear();
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
  }

  searchTours(searchParam?) {
    this._searchToursParams = searchParam ? searchParam : this._searchToursParams;
    let path = 'find_tours';
    this.find_tours(0, path, this._searchToursParams)
      .subscribe(resp => {
        this._searchToursResultList.next(resp);
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      });
  }

  private find_tours(offerId, path, searchParam?): Observable<any> {
    return Observable.create((observer) => {
      const find = searchParam ? this.api.post(`/api/action/${path}`, searchParam) : this.api.post(`/api/action/${path}`, offerId);
      find.subscribe(res => {
        observer.next(res);
        observer.complete();
      }, error => {
        observer.error(error);
      })
    });
  }

  getToursParams() {
    return this._searchToursParams;
  }

  setToursParams(newToursParams) {
    this._searchToursParams = newToursParams;
  }

  clear() {
    this._searchToursParams = null;
  }
}
