<div class="content" [hidden]="!initialized">
  <section class="preview"><img [src]="imgFon" (load)="loaded()">
  <div class="container preview_container_position">
    <div class="row">
      <div class="col-12 col-xl-7 preview__text ml-auto text-right">
        <h1>{{mainTitle}}</h1>
        <p class="margin-bottom-20" *ngFor="let text of mainText">{{text}}</p>
        <div class="preview__text-btns justify-content-center" *ngIf="!this.us.isAuth()">
          <a class="preview__text-registr"[routerLink]="['/signup']">{{'SIGN_IN.GET_STARTED' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="user-groups"  *ngIf="!role">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-xl-6">
        <div class="user-groups__items">
          <div class="user-groups__img"><img src="assets/img/user_groups/organisers.png"/>
          </div>
          <div class="user-groups__data">
              <h1>{{cards1.title}}</h1>
              <p *ngFor="let text of cards1.text; last as isLast" [style.padding-top.px]="isLast ? 15 : ''">{{text}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xl-6">
        <div class="user-groups__items">
          <div class="user-groups__img"><img src="assets/img/user_groups/artist.png"/>
          </div>
          <div class="user-groups__data">
            <h1>{{cards2.title}}</h1>
            <p *ngFor="let text of cards2.text; last as isLast" [style.padding-top.px]="isLast ? 15 : ''">{{text}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xl-6">
        <div class="user-groups__items">
          <div class="user-groups__img"><img src="assets/img/user_groups/music.png"/>
          </div>
          <div class="user-groups__data">
            <h1>{{cards3.title}}</h1>
            <p *ngFor="let text of cards3.text">{{text}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-xl-6">
        <div class="user-groups__items">
          <div class="user-groups__img"><img src="assets/img/user_groups/booking.png"/>
          </div>
          <div class="user-groups__data">
            <h1>{{cards4.title}}</h1>
            <p *ngFor="let text of cards4.text">{{text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="row"  style="width: 100%; margin: 0; background-color: #f1f2f2" *ngIf="role == 'organizer'" >
  <div class="col-12 padding-50">
    <div class="row justify-content-center align-items-center">
      <div class="col-xl-4  padding-20"  *ngFor="let item of textBlock">
        <div class="media">
          <img style="margin-bottom: 20px" [src]="item?.ingLink">
          <div class="media-body pl-4">
            <h4>{{item.label}}</h4>
            <p *ngFor="let text of item.text">{{text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="slider" *ngIf="role">
  <div class="container">
    <div class="slider__head">
      <h2>{{cards.main}}</h2>
      <p>{{cards.sub}}</p>
    </div>
    <div>
      <div class="carousel-inner slider__inner move_slide"[ngSwitch]="howItWorkSlider">
        <div class="carousel-item slider__item active opacity-change right-change" [style.right.px]="position[1]" [style.opacity]="opacity[1]" *ngSwitchCase="1">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-4 col-xl-4 text-center slider__image  d-sm-none__max"><img src="assets/img/slider/1.png"></div>
              <div class="col-sm-12 col-md-6 col-xl-6 slider__data">
                <div class="slider__number">01</div>
                <h2>{{cards1.title}}</h2>
                <p *ngFor="let text of cards1.text">{{text}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-item slider__item active opacity-change right-change" [style.right.px]="position[2]" [style.opacity]="opacity[2]" *ngSwitchCase="2">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-4 col-xl-4 text-center slider__image  d-sm-none__max"><img src="assets/img/slider/2.png"></div>
              <div class="col-sm-12 col-md-6 col-xl-6 slider__data">
                <div class="slider__number">02</div>
                <h2>{{cards2.title}}</h2>
                <p *ngFor="let text of cards2.text">{{text}}</p></div>
            </div>
          </div>
        </div>
        <div class="carousel-item slider__item active opacity-change right-change" [style.right.px]="position[3]" [style.opacity]="opacity[3]" *ngSwitchCase="3">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-4 col-xl-4 text-center slider__image  d-sm-none__max"><img src="assets/img/slider/3.png"></div>
              <div class="col-sm-12 col-md-6 col-xl-6 slider__data">
                <div class="slider__number">03</div>
                <h2>{{cards3.title}}</h2>
                <p *ngFor="let text of cards3.text">{{text}}</p> </div>
            </div>
          </div>
        </div>
        <div class="carousel-item slider__item active opacity-change right-change" [style.right.px]="position[4]" [style.opacity]="opacity[4]" *ngSwitchCase="4">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-12 col-md-4 col-xl-4 text-center slider__image  d-sm-none__max"><img src="assets/img/slider/4.png"></div>
              <div class="col-sm-12 col-md-6 col-xl-6 slider__data">
                <div class="slider__number">04</div>
                <h2>{{cards4.title}}</h2>
                <p *ngFor="let text of cards4.text">{{text}}</p>
              </div>
            </div>
          </div>
        </div>
        <a class="carousel-control-prev slider__prev d-sm-none__max" (click)="prev()" role="button" data-slide="prev"></a>
        <a class="carousel-control-next slider__next d-sm-none__max" (click)="next()" role="button" data-slide="next"></a>
      </div>
        <ol class="carousel-indicators slider__indicators slide__indicators">
          <li [class.active]="howItWorkSlider == 1" (click)="goTo(1)" data-slide-to="0"></li>
          <li [class.active]="howItWorkSlider == 2" (click)="goTo(2)" data-slide-to="1"></li>
          <li [class.active]="howItWorkSlider == 3" (click)="goTo(3)" data-slide-to="2"></li>
          <li [class.active]="howItWorkSlider == 4" (click)="goTo(4)" data-slide-to="3"></li>
        </ol>
    </div>
  </div>
</section>
<app-contacts [isMap]="false"></app-contacts>
</div>

