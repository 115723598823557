<section class="preview-image preview-image__sign-up">
<div class="form form_sign-up">
  <h1>{{'RESET.TITLE' | translate}}</h1>
  <form [formGroup]="regForm">
    <div class="form-group form__inputs">
      <input type="password"
             class="form-control"
             placeholder="{{'PROFILE.ACCOUNT.PASSWORD' | translate}}"
             formControlName="password"
             [class.is-invalid]="password.invalid && shouldCheck">
      <div class="invalid-feedback" style="width: 100%" *ngIf="password.errors && password.errors['required']">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
      <div class="invalid-feedback" style="width: 100%" *ngIf="password.errors && password.errors['passwordError']">{{"COMMON.PASSWORD_PATTERN" | translate}}</div>
    </div>
    <div class="form-group form__inputs">
      <input type="password" class="form-control" id="confirmPassword"
             placeholder="{{'PROFILE.ACCOUNT.CONFIRM_PASSWORD' | translate}}"
             formControlName="confirmPassword"
             [class.is-invalid]="confirmPassword.invalid && shouldCheck || passwordMessage == 'danger'">
      <div class="invalid-feedback" style="width: 100%" *ngIf="confirmPassword.errors && confirmPassword.errors['required']">
        {{"COMMON.REQUIRE_FIELD" | translate}}
      </div>
      <div class="invalid-feedback" style="width: 100%" *ngIf="passwordMessage == 'danger' && shouldCheck">
        {{'PROFILE.ACCOUNT.DIFFERENT_PASSWORD' | translate}}
      </div>
    </div>
  </form>
  <app-alert class="mt-3" #alert></app-alert>
  <button class="form__btn mt-3"  (click)="save()">
    {{'RESET.SUBMIT' | translate}}</button>
</div>
</section>
