import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, ActivatedRoute, Route } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { UserService } from 'app/_service/user.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean>;
}

@Injectable()
export class GuardSave implements CanDeactivate<CanComponentDeactivate> {
  constructor(public userService: UserService,
    private route: ActivatedRoute) {
  }

  canDeactivate(component: CanComponentDeactivate): Observable<boolean> {
    let deactivated = component.canDeactivate();
    deactivated.subscribe(
      flag => {
        console.log(flag);
      }, 
      flag => {
      console.log(flag);
    });
    return deactivated;
  }
};
