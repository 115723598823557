import { Component, OnInit } from '@angular/core';
import {UserService} from 'app/_service/user.service';
@Component({
  selector: 'app-deleted',
  templateUrl: './deleted.component.html',
  styleUrls: ['./deleted.component.css']
})
export class DeletedComponent implements OnInit {

  constructor(private us: UserService) { }

  ngOnInit() {

  }

}
