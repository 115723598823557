import { Routes, RouterModule } from '@angular/router';
import { MyOfferComponent } from './my-offer/my-offer.component';
import { OfferComponent } from './offer-table/offer.component';
import { CollectiveOfferComponent } from './collective-offer/collective-offer.component';
import { SearchComponent } from './search/search.component';
import { SearchDetailsComponent } from '../shared/search-details/search-details.component';
import { SearchDetailsCollectiveComponent } from '../shared/search-details-collective/search-details-collective.component';
import { ModuleWithProviders } from '@angular/core';
import { OfferGuardService } from 'app/app-routing/offer-guard.service';

const routes: Routes = [
  {
    path: 'my-offer',
    canActivate: [OfferGuardService],
    component: OfferComponent
  },
  {
    path: 'collective-offer',
    component: CollectiveOfferComponent
  },
  {
    path: 'search-mus/:id/:role',
    component: SearchComponent
  },
  {
    path: 'search-details/:id/:offerId/:role',
    component: SearchDetailsComponent
  },
  {
    path: 'search-details-collective/:id/:offerId/:role',
    component: SearchDetailsCollectiveComponent
  },
  {
    path: ':status',
    canActivate: [OfferGuardService],
    component: MyOfferComponent,
  },
  {
    path: '',
    redirectTo: 'current',
    pathMatch: 'full'
  }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
