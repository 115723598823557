import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {
  private readonly _apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public get(api: string, options?: any): Observable<{}|ArrayBuffer> {
    // CORS
    options = options || {};
    options.withCredentials = true;
    return this.http.get(this.geFulltUrl(api), options);
  }

  public post(api: string, body: any, options?: any): Observable<Object> {
    // CORS
    options = options || {};
    options.withCredentials = true;
    return this.http.post(this.geFulltUrl(api), body, options);
  }

  public patch(api: string, body: any, options?: any): Observable<Object> {
    // CORS
    options = options || {};
    options.withCredentials = true;
    return this.http.patch(this.geFulltUrl(api), body, options);
  }

  public delete(api: string, options?: any): Observable<ArrayBuffer> {
    // CORS
    options = options || {};
    options.withCredentials = true;
    return this.http.delete(this.geFulltUrl(api), options);
  }

  public geFulltUrl(api: string): string {
    let slash = '/';
    if (api.length > 0 && api[0] === '/') {
      api = api.substr(1);
    }
    if (this._apiUrl.length > 0 && this._apiUrl[this._apiUrl.length - 1] === '/') {
      slash = '';
    }
    return `${this._apiUrl}${slash}${api}`;
  }
}
