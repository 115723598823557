import {Injectable, NgZone, isDevMode} from '@angular/core';
import {Subject, BehaviorSubject} from 'rxjs';

declare let YT: any;

@Injectable()
export class VideoService {
  static get _window() {
    return window;
  }

  _rS = new BehaviorSubject(false);
  constructor(private zone: NgZone) {
  }

  public conectYTapi() {
    this._rS.subscribe(bool => {
      if (bool) {
        return;
      }
      let win = VideoService._window;
      const doc = win.document;
      let playerApiScript = win.document.createElement('script');
      playerApiScript.type = 'text/javascript';
      playerApiScript.src = `${this._getProtocol()}://www.youtube.com/iframe_api`;
      doc.body.appendChild(playerApiScript);
      win['onYouTubeIframeAPIReady'] = () => {
        this._rS.next(true);
      }
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }})

  }
  private  _getProtocol() {
    const hasWindow = window && window.location;
    const protocol = hasWindow
      ? window.location.protocol.replace(':', '')
      : 'http';
    return protocol;
  }

  public createPlayer(playerId, videoId, height?, width?) {
    const sub = new Subject();
    this._rS.subscribe(bool => {
      if (!bool) {
        return;
      }
      const player = new YT.Player(playerId, {
        height: height || '200',
        width: width || '300',
        playerVars: {
          showinfo: 1,
          controls: 1
        },
        videoId: videoId,
        events: {
          onReady: () => {
          },
          onStateChange: () => {
          }
        },
      });
      sub.next(player);
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }});
    return sub;
  }
}
