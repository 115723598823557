import {Component, isDevMode, OnInit} from '@angular/core';

import {concat, Observable} from 'rxjs';

import {ActivatedRoute, Router} from '@angular/router';

import {DictionaryService} from 'app/_service/dictionary.service';
import {CommonService} from 'app/_service/common.service';
import {DataService} from 'app/_service/backend/data.service';
import {UserService} from 'app/_service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {ROLES} from 'app/constants';
import {ApiService} from 'app/_service/backend/api.service';
import {map} from 'rxjs/operators';
import { FileService } from 'app/_service/file.service';

@Component({
  selector: 'app-search-details',
  templateUrl: './search-details.component.html',
  styleUrls: ['./search-details.component.css']
})
export class SearchDetailsComponent implements OnInit {
  ROLES = ROLES;
  id: Number;
  offerId: string;
  role: string;
  profile: any;
  user: User | any;
  submitButton: boolean = true;
  references: any[] = [];
  cv: any[] = [];
  certificates: any[] = [];
  professions: any[] = [];
  invitation: boolean = false;
  initialized: boolean = false;
  avatarUrl: string = '';
  gender: string;
  languages: any[] = [];
  isCollapsed = false;
  isCollapsedTours = false;
  isCollapsedProf = [];
  professionsNames: {} = {};
  specializationsNames: {} = {};
  languagesNames = {};
  countriesDic = {};
  stylesNames: {} = {};
  genresDic: any[] = [];
  currentLang = this.ts.currentLang;
  toursArray = [];

  constructor(
    private dataS: DataService,
    private ds: DictionaryService,
    private cs: CommonService,
    private us: UserService,
    private fs: FileService,
    private ts: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService
  ) {
  }

  ngOnInit() {
    this.cs.togleSpiter(false);
    this.route.params.subscribe(params => {
      const tmpId = params['id'];
      this.id = isNaN(tmpId) ? 0 : +tmpId;
      this.role = params['role'];
      this.offerId = params['offerId'];
      if (!(this.id && this.role && this.offerId)) {
        this.router.navigate(['/']);
        return;
      }
    });
    this.cs.togleSpiter(true);
    this.initDictionaries().subscribe(
      () => {
      },
      err => console.error(err),
      () => {
        this.dataS.get_mus_card(this.id, this.offerId)
          .subscribe((user: any) => {
            console.log(user);
            this.cs.togleSpiter(false);
            const profile = user.musician;
            this.toursArray = user.musician.mus_tours;
            for (let i in this.toursArray) {
              this.toursArray[i].dateFrom = this.parseDate(this.toursArray[i].from);
              this.toursArray[i].dateTo = this.parseDate(this.toursArray[i].to);
            }
            const professions = Array.isArray(profile.professions) ? profile.professions.map(item => {
              const styles = typeof item.styles === 'string' ? item.styles.split(',').map(Number) : [];
              return {...item, styles}
            }) : [];
            
            this.references = Array.isArray(profile.references)
              ? profile.references.map(item => {
                return {...item, ref_href: `${item.ref_href}/${profile.user}`}
              }) : [];

            this.cv = Array.isArray(profile.cv)
              ? profile.cv.map(item => {
                return {...item, cv_href: `${item.cv_href}/${profile.user}`}
              }) : [];

            this.certificates = Array.isArray(profile.certificates)
              ? profile.certificates.map(item => {
                return {...item, cer_href: `${item.cer_href}/${profile.user}`}
              }) : [];

            this.user = user;
            this.invitation = Array.isArray(profile.invitations) && profile.invitations.length !== 0;
            this.profile = {...profile, professions};
            this.languages = typeof this.profile.languages === 'string' ? this.profile.languages.split(',') : [];
            this.gender = `GENDER.${profile.gender}`;
            const avatar = profile.avatar ? `${this.profile.avatar}/${profile.user}` : '';
            this.avatarUrl = this.fs.getFileUrl(avatar, null, true);
            this.submitButton = false;
            this.initialized = true;
            if (isDevMode()) {
              console.log(this.profile);
            }
          }, () => {
          });
      }
    );
    this.ts.onLangChange.subscribe(langEvent => {
      this.currentLang = langEvent.lang;
      this.initDictionaries().subscribe();
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
  }

  getFileUrl(path: string, name: string): string {
    return this.fs.getFileUrl(path, name);
  }

  inviteMusician(event) {
    event.stopPropagation();
    this.submitButton = true;
    this.api.post('/api/invite/organizer/sent', {offer: this.offerId, user: this.profile.user}, {responseType: 'text'})
      .subscribe(() => {
          this.submitButton = false;
          this.invitation = true;
          // console.log(value);
        },
        () => {
          this.submitButton = false;
          // console.log(err);
        });
  }

  goToSearch(event) {
    event.stopPropagation();
    this.submitButton = true;

    let path;
    if (this.role === 'Invite') {
      path = [`/invitations/org/list/${ this.offerId}`];
    } else if (this.role === 'info') {
      path = [`/tour-calendar`];
    } else {
      path = this.role !== ROLES[3][0] ? ['offer/search-mus', this.offerId, this.role] : ['/my-musicians'];
    }
    this.router.navigate(path);
  }

  private initDictionaries(): Observable<any> {
    return concat(
      this.ds.getDictionaryNames('dic_professions')
        .pipe(
          map(
            dic => {
              this.professionsNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_specializations')
        .pipe(
          map(
            dic => {
              this.specializationsNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_styles')
        .pipe(
          map(
            dic => {
              this.stylesNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_prof_genres')
        .pipe(
          map(
            genres => {
              this.genresDic = genres;
            }
          )
        ),
      this.ds.getDictionary('countries')
        .pipe(
          map(dic => {
            dic.forEach(item => this.countriesDic[item.code] = item.name);
          })
        ),
      this.ds.getDictionaryNames('languages', 'code')
        .pipe(
          map(names => this.languagesNames = names)
        )
    );
  }

  parseDate(dateTime: string) {
    return this.cs.parseDate(dateTime);
  }
}
