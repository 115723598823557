<section class="row justify-content-center mt-3" *ngIf="isMap">
  <div class="map col-12 col-xl-9"></div>
  <!--<app-location [height]="'600px'" style="width: 100%"></app-location>-->
</section>
<section class="contacts  contacts_bg_color">
  <div class="container">
    <h2>{{'MENU.CONTACT_US'| translate}}</h2>
    <div class="contacts-data">
      <div class="row justify-content-lg-between justify-content-start">
        <div class="col-12 col-md-6 col-lg-3 mt-5 row contacts-data__item">
          <div class="col-2 col-lg-2 text-center contacts-data__img"><img src="assets/img/contacts/adress.png"/>
          </div>
          <div class="col-10 col-lg-7 contacts-data__info">
            <h4>{{'MENU.ADDRESS'| translate}}</h4>
            <p>Zentralstrasse 9</p>
            <p>6003 Lucerne</p>
            <p>Switzerland</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mt-5 row contacts-data__item">
          <div class="col-2 col-lg-2 text-center contacts-data__img"><img src="assets/img/contacts/mail.png"/>
          </div>
          <div class="col-10 col-lg-7 contacts-data__info">
            <h4>{{'MENU.EMAIL'| translate}}</h4>
            <p>info@q-artist.com</p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-3 mt-5 row contacts-data__item">
          <div class="col-2 col-lg-2 text-center contacts-data__img"><img src="assets/img/contacts/support.png"/>
          </div>
          <div class="col-10 col-lg-7 contacts-data__info">
            <h4>{{'MENU.SUPPORT'| translate}}</h4>
            <p>support@q-artist.com</p>
          </div>
        </div>
      </div>
    </div>
    <div class="phone-lang">
      <div class="row contacts-data__item justify-content-lg-center justify-content-start">
        <!--
        <div class="col-md-6 col-xl-4 phone-lang__item">
          <p>IT, RU</p>
          <p><img src="assets/img/icons/icons.png"/><span>+39 391 177 43 95</span></p>
        </div>
        -->
        <div class="col-12 phone-lang__item">
          <p class="text-center">IT, DE, EN, RU</p>
          <p class="text-center"><img src="assets/img/icons/icons.png"/><span>+ 41 76 426 35 30</span></p>
        </div>
      </div>
    </div>
  </div>
</section>
