import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class NgbDateRusParserFormatter extends NgbDateParserFormatter {
  constructor(private ts: TranslateService){ super();}

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const dateStr = moment(`${date.year}-${date.month}-${date.day}`, 'YYYY-MM-DD').format('DD/MM/YYYY');
    //console.log('format', dateStr);
    return dateStr;
  }

  parse(value: string): NgbDateStruct {
    if (value && value.length === 10) {
      const dateParts = moment(value, "DD/MM/YYYY");
      //console.log('parse', dateParts.format());
      return new DateConvert(dateParts.year(), dateParts.month() + 1, dateParts.date());
    }
    return null;
  }
  isNumber(value) {
    return !isNaN(this.toInteger(value));
  }
  private padNumber(value) {
    if (this.isNumber(value)) {
      return ('0' + value).slice(-2);
    } else {
      return '';
    }
  }
  private toInteger(value) {
    return parseInt('' + value, 10);
  }
}

class DateConvert {
  year: number;
  month: number;
  day: number;
  constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }
};
