import { AbstractControl, ValidationErrors } from '@angular/forms';

export function passwordValidator(control: AbstractControl): ValidationErrors | null {

  const expr: RegExp = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*!@#$%^&(){}\[\]:;<>,.?/~`_+\-=|\\]).{8,32}$/; // + special symbols

  const value = control.value;
  if (!value || value.length === 0) {
    return null;
  }

  return expr.test(value) ? null : { 'passwordError': { value: control.value } };
}

export function nameValidator(control: AbstractControl): ValidationErrors | null {

  const expr: RegExp = /^[a-zA-Z\s`'-]+$/; // + special symbols

  const value = control.value;
  if (!value || value.length === 0) {
    return null;
  }

  return expr.test(value) ? null : { 'nameError': { value: control.value } };
}
