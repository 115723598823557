import {TranslateService} from '@ngx-translate/core';
import {NgbDateStruct, NgbModal, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';

import {FormArray, FormBuilder, FormControl, Validators} from '@angular/forms';
import {Component, ElementRef, isDevMode, OnInit, ViewChild} from '@angular/core';
import {MusicianProfileService} from 'app/_service/musician-profile/musician-profile.service';

import {concat, Observable, Subject} from 'rxjs';
import {CommonService} from 'app/_service/common.service';
import {UserService} from 'app/_service/user.service';
import {DictionaryService} from 'app/_service/dictionary.service';
import {FREQ_CONCERT, ROLES, TYPE_OF_ACTIVITY} from 'app/constants';
import {AlertComponent} from 'app/user/shared/shared.module';
import {LocationService} from '../../location/location.module';
import {ActivatedRoute, Router} from '@angular/router';
import {SearchService} from '../search.service';
import {map, take} from 'rxjs/operators';
import {isFuture} from '../offer-table/offer.component';
import * as moment from 'moment';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { FilesComponent } from 'app/user/shared/files/files.component';

@Component({
  selector: 'app-collective-offer',
  templateUrl: './collective-offer.component.html',
  styleUrls: ['./collective-offer.component.css']
})
export class CollectiveOfferComponent implements OnInit {
  @ViewChild('alert', { static: true }) private alert: AlertComponent;
  @ViewChild('searchPlaces', { static: true }) public searchElementRef: ElementRef;
  @ViewChild('category', { static: true }) public category: ElementRef;
  @ViewChild('addFiles', { static: true }) addFiles: FilesComponent;
  playgroundError = false;
  showSpinnerComposer = false;
  showSpinnerComposition = false;
  readonly = false;
  changeType = false;
  offerClosed = false;
  organizerBlocked = false;
  firstClicked = false;
  currentLang = this.ts.currentLang;
  TYPE_OF_ACTIVITY = TYPE_OF_ACTIVITY;
  freqConcert = FREQ_CONCERT;
  time: FormControl = this.fb.control('');
  dateEx: FormControl = this.fb.control('', [Validators.required, isFuture]);
  dateExDisabled = false;
  maxDateEx = null;
  date: FormControl = this.fb.control('', Validators.required);
  type = this.fb.control(null, Validators.required);
  categories = this.fb.control({value:"",disabled: true});
  playground: FormControl = this.fb.control('', Validators.required);
  latitude: FormControl = this.fb.control(0);
  longitude: FormControl = this.fb.control(0);
  location: FormArray = this.fb.array([this.latitude, this.longitude]);
  distance = this.fb.control(200);
  composerId = this.fb.control(null);
  composer = this.fb.control(null);
  offerCategory = null;
  findAutoCompleteList: Array<any>;
  compositionList: Array<any>;
  composition = this.fb.control('');
  collectiveForm = this.fb.group({
    id: null,
    venue: this.playground,
    location: this.location,
    dates: this.fb.control(null),
    paymentDates: this.fb.control(null),
    role: null,
    typeId: this.type,
    name: null,
    email: null,
    website: null,
    addressActual: null,
    categoryId: this.categories,
    styleId: null,
    languages: null,
    specializationId: null,
    typeOfTheActivities: null,
    distance: this.distance,
    composerId: this.composerId,
    composer: this.composer,
    composition: this.composition,
    group_musical_instruments: null,
    group_voices: null
  });
  // dictionary
  groupVoicesDic: any[] = [];
  groupMusicalInstrumentsDic: any[] = [];
  collectiveType: [any];
  languagesDic: [any];
  categoryDic: [any];
  styleDic: [any];
  specializationsDict: [any];
  languagesNames: {};
  datesArray = [];
  languagesArray = [];
  collTypeNames = {};
  // flags
  dateHide = false;
  // id offer
  id: number;
  invite: number;
  appFileLabels = {
    title: 'ORGANIZER.OFFER.FILES',
    type: 'ORGANIZER.OFFER.FILES_DESC'
  };
  dataName = 'coll_offers_files';

  userSubscription;
  browserLang = navigator.language;

  constructor(
    public musicianProfileService: MusicianProfileService,
    public fb: FormBuilder,
    public locationService: LocationService,
    public cs: CommonService,
    private ds: DictionaryService,
    private modalService: NgbModal,
    private ts: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private ss: SearchService,
    private userService: UserService
  ) {
    this.time.setValue(this.fromModelTime());
  }

  ngOnInit() {
    const contrList =
    [this.playground, this.composition, this.composer, this.collectiveForm];
    this.cs.togleSpiter(false);
    this.locationService.getPlaces(this.searchElementRef.nativeElement, false);
    this.initDictionaries().subscribe(() => {
    }, () => {
    });
    
    this.userSubscription = this.userService.getUser$.subscribe((user: User) => {
      if(user && user.status === 2){
        this.organizerBlocked = true;
      }
    });
    this.route
      .queryParamMap.subscribe(
        params => {
          const id = params.get('id');
          const invite = params.get('invite');
          if (!id) {
            this.id = null;
            return;
          }

        this.cs.togleSpiter(true);
          this.id = +id;
          this.invite = +invite;
        this.musicianProfileService.getCollOffer(this.id).subscribe(
        offer => {
          this.cs.togleSpiter(false);
          this.readonly = !!offer.onlyRead;
          this.languagesArray = offer.languages ? offer.languages.trim().split(',') : [];
          this.datesArray = offer.dates ? offer.dates.trim().split(',') : [];
          this.datesArray = this.cs.sortDatesArray(this.datesArray);
          this.checkDateEx();
          this.collectiveForm.patchValue(offer);
          this.offerCategory = offer.categoryId;
          this.locationService.getGeoCoding(this.playground.value);
          this.fromModel(offer.expiration);
          if (offer.status === 1) {
            this.offerClosed = true;
            this.readonly = true;
          }
          if (this.type.value) {
            this.changeCategories(this.type.value);
            this.categories.enable();
          }
          contrList.forEach(
            controller => {
              this.readonly ? controller.disable() : controller.enable();
            }
          );

          this.ds
            .getFilteredDictionary(
              'dic_collective_specializations',
              i => Number(i.typeId) === Number(offer.typeId)).subscribe(
            dic => {
              this.specializationsDict = dic;
            });
        },
        err => {
          console.log(err);
          this.cs.togleSpiter(false);
          this.router.navigateByUrl('/offer/current');
        });
    });
    this.ts.onLangChange.subscribe(langEvent => {
      this.currentLang = langEvent.lang;
      this.initDictionaries().subscribe(
        () => {
          this.offerCategory = this.categories.value;
          this.changeCategories(this.type.value);
          this.collectiveForm.patchValue({"categoryId": this.offerCategory});
        },
        err => {
          if (isDevMode()) {
            console.log(err);
          }
        }, () => {
        });
    });
    this.date.valueChanges.subscribe(
      (value) => {
        if (value) {
          let newDate = this.cs.convertDatePickerToString(value);
          if(this.datesArray.indexOf(newDate) == -1) {
            this.datesArray.push(newDate);
            this.datesArray = this.cs.sortDatesArray(this.datesArray);
            this.checkDateEx();
          }
          this.dateHide = false;
        }
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      }
    );

    this.categories.valueChanges.subscribe(
      (value) => {
        if (value) {
          this.changeType = false;
        } else {
          this.changeType = true;
        }
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      }
    );
    this.locationService.coordinates$.subscribe((value: any) => {
      this.location.patchValue([value.lat, value.lng]);
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
    this.locationService.place$.subscribe((value: any) => {
      if (value === this.playground.value) {
        return;
      }
      this.playground.markAsDirty();
      this.playground.patchValue(value);
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });

    this.musicianProfileService.profile$.subscribe(
      (profile: any) => {
        if (typeof(profile.languages) === 'string') {
          this.languagesArray = profile.languages ? profile.languages.trim().split(',') : [];
        }
      }, () => {
      });

    this.type.valueChanges.subscribe(
      value => {
        if (this.type.value) {
          this.categories.enable();
        } else {
          this.categories.disable();
        }
        this.categories.reset({});
        this.offerCategory = null;
        this.changeCategories(value);
        this.changeType = true;
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      });
  }

  goToOffer() {
    const status = this.collectiveForm.value.status === 1 ? 'archive' : 'current';
    let path  = ['/offer', status];
    this.router.navigate(path);
  }
  changeCategories(value) {
    const collective = this.collectiveType.find( collType => {
      return +collType.id === +value;
    });
    if (!collective) {
      return;
    }
    const type = +collective.type;
    this.ds
      .getFilteredDictionary(
        'dic_collective_categories',
        i => {

          return Number(i.typeId) === type;
        })
      .subscribe(
        dic => {
          this.categoryDic = dic;
          this.collectiveForm.patchValue({"categoryId": this.offerCategory});
        });
  }
  calendarClick(event) {
    if (this.readonly) {
      return;
    }
    let target = event.target;
    if (target.classList.contains('dates_cont') || event.currentTarget.classList.contains('dates_button')) {
      this.dateHide = !this.dateHide;
      event.stopPropagation();
      return;
    }
    let count = 4;
    while (count > 0) {
      count -= 1;
      if (target.tagName === 'NGB-DATEPICKER-NAVIGATION') {
        this.dateHide = true;
        return;
      }
      target = target.parentElement;
      if (!target) {
        break;
      }
    }
    this.dateHide = false;
  }

  
  isDisabled = (date: NgbDateStruct, current: {month: number, year:number, day: number}) => {
    let newDate = this.cs.convertDatePickerToString(date);
    return this.datesArray.find(x => x == newDate) ? true: false;
  }

  dateDelete(index): void {
    if (this.readonly) {
      return;
    }
    this.datesArray.splice(index, 1);
    this.checkDateEx();
    // this.formOffer.markAsDirty();
  }

  checkDateEx(){
    if(this.cs.getDateFromString(this.datesArray[this.datesArray.length - 1]) < moment()) {
      this.dateExDisabled = true;
      this.dateEx.setValue(null);
    } else {
      this.dateExDisabled = false;
      this.maxDateEx = this.datesArray[this.datesArray.length - 1];
    }
    const date = this.dateEx.value;
    if(date &&  moment([date.year, date.month - 1, date.day]) > this.cs.getDateFromString(this.datesArray[this.datesArray.length - 1])) {
      this.dateEx.setValue(null);
    }
  }

  clearInput() {
    this.playground.reset();
    this.latitude.reset();
    this.longitude.reset();
  }

  up() {
    let dis = +this.distance.value;
    this.distance.setValue(dis + 50);
  }

  down() {
    let dis = +this.distance.value;
    this.distance.setValue(dis - 50);
  }

  deleteFromList(index, list): void {
    if (Array.isArray(list)) {
      list.splice(index, 1);
      this.collectiveForm.markAsDirty();
    }
  }

  setList(value, list): void {
    if (Array.isArray(list)) {
      list.push(value);
      this.collectiveForm.markAsDirty();
    }
  }

  setSuggestComposition(value) {
    this.showSpinnerComposition = true;
    this.ds.findCompositions2(this.composer.value, value).subscribe(compositions => {
      this.showSpinnerComposition = false;
      this.compositionList = compositions;
    }, err => {
      this.showSpinnerComposition = false;
      if (isDevMode()) {
        console.log(err);
      }
    });
  }

  setId(id) {
    this.composerId.setValue(id);
  }

  setSuggest(value) {
    this.showSpinnerComposer = true;
    this.ds.findComposers(value).subscribe(composers => {
      this.showSpinnerComposer = false;
      this.findAutoCompleteList = composers;
    }, err => {
      this.showSpinnerComposer = false;
      if (isDevMode()) {
        console.log(err);
      }
    });
  }
  fromModelStr(time: NgbTimeStruct, date: NgbDateStruct) {
    if (!time || !date) {
      return null;
    }
    return   moment([date.year, date.month - 1, date.day, time.hour, time.minute, time.second])
      .locale(this.browserLang).format('LLLL');
  }
  fromModel(dateTime: string) {
    try {
      let time = dateTime ? moment(dateTime) : moment();
      this.dateEx.setValue({
        year: time.year(),
        month: time.month() + 1,
        day: time.date()
      });
      this.time.setValue(
        {
          hour: time.hour(),
          minute: time.minute(),
          second: 0
        }
      );
    } catch (e) {
      if (isDevMode()) {
        console.log(e)
      }
    }
  }


  fromModelTime(): NgbTimeStruct {
    return {
      hour: 23,
      minute: 59,
      second: 0
    };
  }
  isListAdded(code, list) {
    return (Array.isArray(list)) ? list.indexOf(code) > -1 : false;
  }

  listClick(event, i, object) {
    event.stopPropagation();
    object[i] = !object[i];
  }

  private initDictionaries(): Observable<any> {
    return concat(
      this.ds.getDictionary('dic_collective_types')
        .pipe(
          map(dic => {
            this.collectiveType = dic;
          })
        ),
      this.ds.getDictionary('languages')
        .pipe(
          map(dic => this.languagesDic = dic)
        ),
      this.ds.getDictionary('dic_styles')
        .pipe(
          map(dic => {
              this.styleDic = dic
            }
          )
        ),
      this.ds.getDictionary('dic_specializations')
        .pipe(
          map(dic => {
            this.groupMusicalInstrumentsDic = [];
            this.groupVoicesDic = [];
            dic.forEach( item => {
              if (item.profId === 2) {
                this.groupMusicalInstrumentsDic.push(item);
              } else if (item.profId === 3) {
                this.groupVoicesDic.push(item);
              }
            });
          })
        ),
      this.ds.getDictionary('dic_collective_specializations')
        .pipe(
          map(dic => {
            this.specializationsDict = dic;
          })
        ),
      
        // this.ds.getDictionary('dic_collective_categories')
        // .pipe(
        //   map(dic => {
        //     this.categories = dic;
        //   })
        // ),
      this.ds.getDictionaryNames('languages', 'code')
        .pipe(
          map(names => this.languagesNames = names)
        ),
      this.ds.getDictionaryNames('dic_collective_types', 'id')
        .pipe(
          map(names => {
            this.collTypeNames = names;
          })
        ),
      // this.ds.getDictionaryNames('dic_prof_genres').map(
      //   genres => { this.genresDic = genres; }
      // )
    );
  }

  remove() {
    const collId = this.collectiveForm.get('id').value;
    if (collId === null || collId === undefined) {
      this.router.navigateByUrl('/offer/current');
      return;
    }
    const profModal = this.modalService.open(ModalComponent, { size: 'lg', centered: true, windowClass: 'modal-show', backdrop: 'static' });
    profModal.componentInstance.message = 'MODAL.DELETE_CONFIRM.CONTENT';
    profModal.componentInstance.save = 'MODAL.DELETE_CONFIRM.DELETE';
    profModal.componentInstance.cancel = 'MODAL.DELETE_CONFIRM.CANCEL';
    profModal.result.then(flag => {
      if(flag){
        this.musicianProfileService.deleteData('coll_offers', collId).subscribe(
          () => {
            this.router.navigateByUrl('/offer/current');
            this.alert.showMessage('COMMON.SAVED', 'success');
          },
          () => {
            this.alert.showMessage('COMMON.SAVE-ERROR', 'danger');
          }
        );
      }
    }, () => {
    });
  }

  goToSearch() {
    this.ss.setOfferInfo(this.id, {
        venue: this.collectiveForm.value.venue,
        dates: Array.isArray(this.datesArray) ? this.datesArray.join(',') : ''
      },
      this.collTypeNames[this.type.value]
    );
    this.router.navigate(['offer/search-mus', this.id, ROLES[2][0]]);
  }
  toModel(time: NgbTimeStruct, date: NgbDateStruct): string {
    if (!time || !date) {
      return null;
    }
    return   moment.parseZone([date.year, date.month - 1, date.day, time.hour, time.minute, time.second]).utc().format();
  }
  searchMusician(event) {
    this.cs.togleSpiter(true);
    event.stopPropagation();
    if (this.collectiveForm.dirty || this.date.dirty || !this.firstClicked) {
      this.save().subscribe(() => {
        this.cs.togleSpiter(false);
        this.goToSearch();
      }, err => {
        if (isDevMode()) {
          this.cs.togleSpiter(false);
          console.log(err);
        }
      });
      return;
    }
    this.goToSearch();
  }
  changeLocation() {
    this.location.reset();
  }
  checkLocation(flag) {
    this.playgroundError = flag;
  }
  save() {
    const subj = new Subject();
    this.firstClicked = true;
    const locationRaw = this.location.value;
    if (this.collectiveForm.invalid || this.dateEx.invalid || this.datesArray.length < 1) {
      this.alert.showMessage('COMMON.REQUIRE_FIELDS', 'danger');
      setTimeout(() => {
        subj.error({})
      }, 5);
    } else if (!locationRaw || !locationRaw[0]) {
      this.playground.reset();
      this.alert.showMessage('COMMON.REQUIRE_PLACE_FIELD', 'danger');
      setTimeout(() => {
        subj.error({})
      }, 5);
    } else {
      this.userService.getUser$.pipe(
        take(1)
      ).subscribe((user: User) => {
        if (user === null) {
          return;
        }
        const dates = Array.isArray(this.datesArray) ? this.datesArray.join(',') : '';
        const languages = Array.isArray(this.languagesArray) ? this.languagesArray.join(',') : '';
        const expiration = this.toModel(this.time.value, this.dateEx.value);
        const sendObject = {
          'user': String(user.id),
          ...this.collectiveForm.value,
          languages,
          expiration,
          dates
        };
        if (this.id === undefined || this.id === null) {
          this.musicianProfileService.createData('coll_offers', sendObject).subscribe(
            offer => {
              this.id = offer.id;
              this.collectiveForm.reset({...this.collectiveForm.value, id: offer.id});
              this.addFiles.uploadFiles().subscribe(() => {
                this.alert.showMessage('COMMON.SAVED', 'success');
                subj.next();
              },
              err => {
                this.cs.togleSpiter(false);
                this.alert.showMessage(err.message, 'danger', true);
                subj.error(err);
              }); 
            },
            err => {
              this.alert.showMessage('COMMON.SAVE-ERROR', 'danger');
              subj.error(err);
            }
          );
        } else {
          this.musicianProfileService.updateData('coll_offers', sendObject).subscribe(
            () => {
              this.collectiveForm.reset(this.collectiveForm.value);
              this.alert.showMessage('COMMON.SAVED', 'success');
              subj.next();
            },
            err => {
              this.alert.showMessage('COMMON.SAVE-ERROR', 'danger');
              subj.error(err);
            }
          );
        }
      }, err => {
        subj.error(err);
        if (isDevMode()) {
          console.log(err);
        }
      });
    }

    return subj;
  }
}
