import { Routes, RouterModule } from '@angular/router';
import { TourCalendarComponent } from './tour-calendar.component';
import { GuardSave } from 'app/app-routing/guard-save';
import { ModuleWithProviders } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    component: TourCalendarComponent
  }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);