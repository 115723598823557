<div class="add-file form-user-data__item">
  <!-- <label>{{'ORGANIZER.OFFER.FILES' | translate | uppercase}}</label> -->
  <div class="row add-file__title col-12">
    <label>{{inputLabels.title | translate | uppercase}}</label>
    <!-- <h2 *ngIf = "reviews">{{inputLabels.title | translate | uppercase}}</h2> -->
  </div>
  <div class="row justify-content-start m-0 p-0 repertoire__title">
      <!-- <p class="font-italic">{{'ORGANIZER.OFFER.FILES_DESC' | translate}}</p> -->
      <p class="font-italic">{{inputLabels.type | translate}}</p>
    </div>
  <div class="row add-file__edit">
    <div class="col-10 col-md-11 add-file__line">
      <hr>
    </div>
    <!-- <div class="col-3 col-md-2 justify-content-end text-right"> -->
      <div class="col-2 col-md-1 add-file__add add-btn" 
          *ngIf="!readonly"
          (click)="clickAdd()">
      </div>
    <!-- </div> -->
</div>
  
  <ul class="list-group-sos">
    <li class="" *ngFor="let file of files; let i=index">
      <a  [href]="getFileUrl(file.href, file.name)" target="_blank">
        <label class="list-group-item-heading text-field-sos">{{file.name}}</label>
      </a>
      <div class="cancel-png" (click)="removeFile(file, i)"></div>
    </li>
  </ul>
</div>
<input type="file" #file (change)="addFile($event)" accept="{{filter}}" [style.display]="'none'">
