<div class="aside__list-header d-sm-none">
  <p>{{'PROFILE.TITLE'| translate | uppercase}}</p>
  <button class="btn-points d-sm-none" (click)="listShowActive = !listShowActive">
      <i class="fas " [ngClass]="{'fa-bars' : !listShowActive, 'fa-times': listShowActive}" aria-hidden="true"></i>
  </button>
</div>
<ul class="aside__list d-sm-block" [@smoothCollapse]="!listShowActive?'initial':'final'" appShowMenu>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/personal-data"
       (click)="listShowActive = false">
      {{'PROFILE.PERSONAL.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/profile"
       (click)="listShowActive = false">
      {{'COLLECTIVE.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/location"
       (click)="listShowActive = false">
      {{'PROFILE.LOCATION.WHERE_ARE_YOU' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
      <a class="aside__link" routerLinkActive="active" routerLink="collective/tours"
         (click)="listShowActive = false">
        {{'PROFILE.TOURS.TITLE' | translate | uppercase}}
      </a>
    </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/repertoire"
       (click)="listShowActive = false">
      {{'PROFILE.PROFESSIONAL.REPERTOIRE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/reviews"
       (click)="listShowActive =false">
      {{'PROFILE.REVIEWS.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/account"
       (click)="listShowActive = false">
      {{'PROFILE.ACCOUNT.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/remove"
       (click)="listShowActive = false">
      {{'PROFILE.ACCOUNT.DELETE_PROFILE' | translate | uppercase}}
    </a>
  </li>
</ul>

<ul class="aside__list d-none d-sm-block">
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/personal-data">
      {{'PROFILE.PERSONAL.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/profile">
      {{'COLLECTIVE.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/location">
      {{'PROFILE.LOCATION.WHERE_ARE_YOU' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
      <a class="aside__link" routerLinkActive="active" routerLink="collective/tours">
        {{'PROFILE.TOURS.TITLE' | translate | uppercase}}
      </a>
    </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/repertoire">
      {{'PROFILE.PROFESSIONAL.REPERTOIRE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/reviews">
      {{'PROFILE.REVIEWS.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/account">
      {{'PROFILE.ACCOUNT.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="collective/remove">
      {{'PROFILE.ACCOUNT.DELETE_PROFILE' | translate | uppercase}}
    </a>
  </li>
</ul>



