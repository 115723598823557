import { NgModule } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { CommonModule } from '@angular/common';

import {ReactiveFormsModule, FormsModule} from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AccountComponent } from './account/account.component';
import { AlertComponent } from './alert/alert.component';
import { SuggestComponent } from './suggest/suggest.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { MultiSelectPipe } from './multi-select/multi-select.pipe';
import { VideoComponent } from './video/video.component';
import { VideoService } from './video/video.service';
import { SoundcloudComponent } from './soundcloud/soundcloud.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { AgreePolicyComponent } from './agree-policy/agree-policy.component';
import { DeletedComponent } from './deleted/deleted.component';
import { BellComponent } from './bell/bell.component';
import { InvitationAlertComponent } from './invitation-alert/invitation-alert.component';
import { FilesComponent } from './files/files.component';
import { SharedComponentsModule } from 'app/shared/shared-components.module';
import { SearchDetailsComponent } from './search-details/search-details.component';
import { SearchDetailsCollectiveComponent } from './search-details-collective/search-details-collective.component';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SharedComponentsModule,
    NgbModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    AccountComponent,
    DeleteAccountComponent,
    AgreePolicyComponent,
    AlertComponent,
    SuggestComponent,
    MultiSelectComponent,
    VideoComponent,
    SearchDetailsComponent,
    SearchDetailsCollectiveComponent,
    SoundcloudComponent,
    DeletedComponent,
    BellComponent,
    InvitationAlertComponent,
    FilesComponent
  ],
  providers: [VideoService],
  declarations: [
    AccountComponent,
    AlertComponent,
    SuggestComponent,
    MultiSelectComponent,
    VideoComponent,
    SearchDetailsComponent,
    SearchDetailsCollectiveComponent,
    SoundcloudComponent,
    MultiSelectPipe,
    DeleteAccountComponent,
    AgreePolicyComponent,
    DeletedComponent,
    BellComponent,
    InvitationAlertComponent,
    FilesComponent
  ]
})
export class SharedModule { }
export { AlertComponent }
