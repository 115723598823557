import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { ContractComponent } from './contract.component';

const routes: Routes = [
  {
    path: '',
    component: ContractComponent
  }
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
