import { Injectable } from '@angular/core';

@Injectable()
export class SpecializationService {
  public _objectStyle = {};
  public _objectSpecialization = {};
  public _objectStyleMusic = {};
  private _specialization = [
    { code: '0100', name: 'SPECIALIZATION.CONDUCTOR' },
    { code: '0200', name: 'SPECIALIZATION.INSTRUMENTALIST' },
    { code: '0300', name: 'SPECIALIZATION.SINGER' }
  ];

  constructor() {
    this.getStyleMusic().forEach(value => {
      this._objectStyleMusic[value.code] = value.name;
    });
    this._style().forEach(value => {
      this._objectStyle[value.code] = value.name;
    });
    this.getSpecialization().forEach(value => {
      this._objectSpecialization[value.code] = value.name;
    });
  }

  public getSpecialization(): { code: string, name: string }[] {
    return this._specialization;
  }

  public getStyle(code: string): { code: string, name: string }[] {
    return this._style().filter(item => item.parent === code);
  }

  public getStyleMusic(): any[] {
    return [
      { code: '0010', name: 'STYLE_MUSIC.MEDIEVAL' },
      { code: '0020', name: 'STYLE_MUSIC.RENAISSANCE' },
      { code: '0030', name: 'STYLE_MUSIC.BAROCK' },
      { code: '0040', name: 'STYLE_MUSIC.CLASSICAL_AGE' },
      { code: '0050', name: 'STYLE_MUSIC.ROMANTIC' },
      { code: '0060', name: 'STYLE_MUSIC.CONTEMPORARY' },
      { code: '0070', name: 'STYLE_MUSIC.ELECTRONIC_MUSIC' }
    ];
  }

  private _style(): any[] {
    return [
      { parent: '0100', code: '0010', name: 'TYPE_MUSIC.ORCHESTRA_CONDUCTOR' },
      { parent: '0100', code: '0020', name: 'TYPE_MUSIC.WIND_ORCHESTRA_CONDUCTOR' },
      { parent: '0100', code: '0030', name: 'TYPE_MUSIC.CHOIR_CONDUCTOR' },
      { parent: '0100', code: '0040', name: 'TYPE_MUSIC.ENSEMBLE_CONDUCTOR' },
      { parent: '0100', code: '3000', name: 'TYPE_MUSIC.OPERA' },

      { parent: '0200', code: '0050', name: 'TYPE_MUSIC.HARP' },
      { parent: '0200', code: '0060', name: 'TYPE_MUSIC.GUITAR' },
      { parent: '0200', code: '0070', name: 'TYPE_MUSIC.CLARINET' },
      { parent: '0200', code: '0080', name: 'TYPE_MUSIC.CONTRABASS' },
      { parent: '0200', code: '0090', name: 'TYPE_MUSIC.HARPSICORD' },
      { parent: '0200', code: '0100', name: 'TYPE_MUSIC.HORN' },
      { parent: '0200', code: '0101', name: 'TYPE_MUSIC.ALPEN_HORN' },
      { parent: '0200', code: '0102', name: 'TYPE_MUSIC.ENGLISH_HORN' },
      { parent: '0200', code: '0110', name: 'TYPE_MUSIC.BASSON_CONTRABASSON' },
      { parent: '0200', code: '0120', name: 'TYPE_MUSIC.ARMONICA_BANDONEON' },
      { parent: '0200', code: '0130', name: 'TYPE_MUSIC.FLUTE' },
      { parent: '0200', code: '0140', name: 'TYPE_MUSIC.FLICORN' },
      { parent: '0200', code: '0150', name: 'TYPE_MUSIC.LIUTE' },
      { parent: '0200', code: '0160', name: 'TYPE_MUSIC.MANDOLIST' },
      { parent: '0200', code: '0170', name: 'TYPE_MUSIC.OBOE' },
      { parent: '0200', code: '0180', name: 'TYPE_MUSIC.ORGAN' },
      { parent: '0200', code: '0190', name: 'TYPE_MUSIC.PERCUSSION' },
      { parent: '0200', code: '0200', name: 'TYPE_MUSIC.PIANO' },
      { parent: '0200', code: '0210', name: 'TYPE_MUSIC.FORTEPIANO' },
      { parent: '0200', code: '0220', name: 'TYPE_MUSIC.SAXOPHONE' },
      { parent: '0200', code: '0230', name: 'TYPE_MUSIC.TRUMPET' },
      { parent: '0200', code: '0240', name: 'TYPE_MUSIC.TROMBON' },
      { parent: '0200', code: '0250', name: 'TYPE_MUSIC.TUBA' },
      { parent: '0200', code: '0260', name: 'TYPE_MUSIC.VIOLIN' },
      { parent: '0200', code: '0270', name: 'TYPE_MUSIC.VIOLA' },
      { parent: '0200', code: '0280', name: 'TYPE_MUSIC.CELLO' },
      { parent: '0200', code: '0000', name: 'TYPE_MUSIC.ALTRO' },

      { parent: '0300', code: '1010', name: 'TYPE_MUSIC.SOPRANO' },
      { parent: '0300', code: '1020', name: 'TYPE_MUSIC.MEZZO' },
      { parent: '0300', code: '1030', name: 'TYPE_MUSIC.ALTO' },
      { parent: '0300', code: '1050', name: 'TYPE_MUSIC.CONTRATENOR' },
      { parent: '0300', code: '1060', name: 'TYPE_MUSIC.TENOR' },
      { parent: '0300', code: '1070', name: 'TYPE_MUSIC.BARITONE' },
      { parent: '0300', code: '1080', name: 'TYPE_MUSIC.BASS' }
    ];
  }

}
