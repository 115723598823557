<section class="completion-resume">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h2 class="text-md-left text-center m-3" *ngIf="status === 'current'; else archive">{{'ORGANIZER.OFFER.ACTIVE' | translate | uppercase}}</h2>
        <ng-template  #archive>
          <h2 class="text-md-left text-center m-3">{{'ORGANIZER.OFFER.CLOSED' | translate | uppercase}}</h2>
        </ng-template>
      </div>
      <div class="col-12 col-md-6 m-0 p-o row justify-content-md-end justify-content-center" ngbDropdown>
        <button *ngIf="status === 'current'" class="btn rounded-3 btn-outline-black-invert align-self-end" ngbDropdownToggle>
          <span style="font-size: 1.2em; margin-right: 3px;">&#xFF0B;</span>
          <span>{{'ORGANIZER.OFFER.CREATE_OFFER' | translate | uppercase}}</span>
        </button>

        <div class="dropdown-menu" ngbDropdownMenu>
            <a *ngIf="status === 'current'" class="dropdown-item"  routerLink="/offer/my-offer">
              <!-- <span style="font-size: 1.2em; margin-right: 3px;">&#xFF0B;</span> -->
              <span>{{'ORGANIZER.OFFER.CREATE_OFFER_MUS' | translate | uppercase}}</span>
            </a>
            <a *ngIf="status === 'current'" class="dropdown-item"  routerLink="/offer/collective-offer">
              <!-- <span style="font-size: 1.2em; margin-right: 3px;">&#xFF0B;</span> -->
              <span>{{'ORGANIZER.OFFER.CREATE_OFFER_COL' | translate | uppercase}}</span>
            </a>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="container main-container">
  <article class="offers-table">
    <div class="row card" *ngFor="let item of offers" (click)="openOffers(item)">
      <div class="col-12 col-lg-10 col-md-9">
        <div class="row text-underline">
          <div class=" align-items-center  col-10 mb-3 text-lg-left text-center" *ngIf="item.profile === 'Musician'">
            <b>{{professions[item.profId] | uppercase}}<div class="circle-black"></div>{{specializations[item.specializationId] | uppercase}}</b>
          </div>
          <div class=" align-items-center col-10  mb-3 text-lg-left text-center" *ngIf="item.profile === 'Collective'">
            <b>{{collectiveType[item.typeId] | uppercase}}</b>
          </div>
        </div>
        <div class=" align-items-center   mb-3 text-center text-md-left offers-table__venue"><i>{{item.venue}}</i></div>
        <div class=" align-items-center   mb-3 text-center text-md-left">{{item.dates}}</div>
      </div>
      <div class="col-12 col-lg-2 col-md-3 align-items-center text-center row">
        <button class="btn btn-outline-gray-invert col" (click)="openInvitation($event, item)">
          {{'INVITATIONS.CANDIDATES' | translate}}
          <div class="circle" *ngIf="isNew(item.id) && status === 'current'"></div>
        </button>
      </div>
    </div>
  </article>
</div>
<router-outlet name="offer"></router-outlet>

