import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CollectiveOfferComponent } from './collective-offer/collective-offer.component';
import { SearchComponent } from './search/search.component';
import { MyOfferComponent } from './my-offer/my-offer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SharedComponentsModule} from 'app/shared/shared-components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OfferComponent } from './offer-table/offer.component';
import { NgxMaskModule } from 'ngx-mask';
import { ContractModule } from '../contract/contract.module';
import { SearchService } from './search.service';
import { routing } from './offer.routing';
import localeRu from '@angular/common/locales/ru';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeRu, 'ru');
registerLocaleData(localeIt, 'it');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

//export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    routing,
    FormsModule,
    ContractModule,
    SharedModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers:[
    SearchService
  ],
  declarations: [
    OfferComponent,
    MyOfferComponent,
    SearchComponent,
    CollectiveOfferComponent
  ]
})
export class OfferModule { }
