<div class="aside__list-header d-sm-none flex-row align-items-center">
    <p>{{'PROFILE.TITLE'| translate | uppercase}}</p>
    <button class="btn-points" (click)="listShowActive = !listShowActive">
        <i class="fas " [ngClass]="{'fa-bars' : !listShowActive, 'fa-times': listShowActive}" aria-hidden="true"></i>
    </button>
  <!--<span class="aside__drop-btn" (click)="listShowActive = listShowActive == 'aside__list-active' ? '' : 'aside__list-active'"></span>-->
</div>
<ul class="aside__list d-sm-block" [@smoothCollapse]="!listShowActive?'initial':'final'">
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="organizer/personal-data"
       (click)="listShowActive = false">
      {{'PROFILE.PERSONAL.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="organizer/profile"
       (click)="listShowActive = false">
      {{'ORGANIZER.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="organizer/location"
       (click)="listShowActive = false">
      {{'PROFILE.LOCATION.WHERE_ARE_YOU' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="organizer/account"
       (click)="listShowActive =false">
      {{'PROFILE.ACCOUNT.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="organizer/remove"
       (click)="listShowActive = false">
      {{'PROFILE.ACCOUNT.DELETE_PROFILE' | translate | uppercase}}
    </a>
  </li>
</ul>

<ul class="aside__list d-none d-sm-block">
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="organizer/personal-data">
      {{'PROFILE.PERSONAL.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="organizer/profile">
      {{'ORGANIZER.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="organizer/location">
      {{'PROFILE.LOCATION.WHERE_ARE_YOU' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="organizer/account">
      {{'PROFILE.ACCOUNT.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="organizer/remove">
      {{'PROFILE.ACCOUNT.DELETE_PROFILE' | translate | uppercase}}
    </a>
  </li>
</ul>
