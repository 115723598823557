<section class="completion-resume">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 (click)="goToSearch($event)" [hidden]="offerId === '0' && role != 'info'"><<< <span  class="text_click" >{{'COMMON.BACK' | translate}}</span></h2>
      </div>
    </div>
  </div>
</section>
<div class="container mt-5 mb-5">
  <div class="row">
    <div class="col-12 col-md-4 col-lg-3">
      <aside class="border-sm-none">
        <div class="aside__photo" [ngClass]="{'gray_background': !avatarUrl}">
          <article class="avatar d-flex justify-content-center align-items-center"  style="min-height: 177px">
            <img [src]="avatarUrl" [hidden]="!avatarUrl" class="aside__avatar" alt="avatar" >
            <img src="assets/icon/png/icon user.png" style="width: 35%; height: 35%;"
                class="aside__avatar center-block" alt="avatar" [hidden]="avatarUrl">
          </article>
        </div>
        <div class="text-center" [hidden]="!initialized || offerId === '0' || offerId === '00'">
          <button class="btn rounded-0 form-user-data__btn btn-outline-black-invert mt-3"
                  style="width: 80%" (click)="invite($event)" [hidden]="invitation"  [disabled]="submitButton">
                  <span [hidden]="submitButton">{{'COMMON.INVITE' | translate}}</span>
                  <i [hidden]='!submitButton' class="fas fa-spinner fa-pulse fa-fw"></i>
          </button>
          <div class="text-center color_title mt-3 font-weight-bold" [hidden]="!invitation">
            <span>{{'COMMON.INVITED' | translate}}</span>
          </div>
        </div>
      </aside>
    </div>
    <div class="col-12 col-md-8 col-lg-9">
      <!--<ngb-alert [type]="'success'" [hidden]="!invitation">{{'INVITATIONS.SENT'| translate}}</ngb-alert>-->
      <article class="profile-content border-sm-none container p-3 m-0 mb-2 row">
        <h1 class="text-left col-12 mb-3">{{profile?.name}}</h1>
        <div class="col-12 col-md-5 mb-3">
          <span>{{'COLLECTIVE.TYPE_COLLECTIVE' | translate}}:</span>
          <span> {{collTypeNames[profile?.type]}}</span>
        </div>
        <div class="col-12 col-md-7 mb-3">
          <span>{{'COLLECTIVE.ORGANIZER' | translate}}:</span>
          <span > {{profile?.firstName}}  {{profile?.lastName}}</span>
        </div>
        <div class="col-12 col-md-5">
          <!--<span class="repertoire__item">Email:</span>-->
          <!--<span class="value_collective"> {{profile?.email}}</span>-->
        </div>
        <div class="col-12 col-md-7">
          <span class="repertoire__item">{{'PROFILE.PERSONAL.LANGUAGES' | translate}}:</span>
          <span>
            <span *ngFor="let lang of languages; let last = last">
              <span>{{languagesNames[lang] | translate | uppercase}}</span>
              <span [hidden]="last">,</span>
            </span>
          </span>
        </div>
      </article>

      <article class="profile-content border-sm-none container p-3 m-0 mb-2" *ngIf="toursArray">
        <p class="color_title mb-4">{{'PROFILE.TOURS.TITLE' | translate |uppercase}}</p>
        <div class="form-user-data__item">
          <div class="row align-items-center mb-4">
            <div class="col-2 col-md-1 repertoire__drop up-down align-items-center" (click)="isCollapsed = !isCollapsed">
              <i class="fas fa-chevron-right" aria-hidden="true" [hidden]="!isCollapsed"></i>
              <i class="fas fa-chevron-down" aria-hidden="true" [hidden]="isCollapsed"></i>
            </div>
            <div class="col-8 pl-0 col-md-10 repertoire__line">
              <hr>
            </div>
          </div>
          <div class="row education-edit__item" *ngFor="let item of toursArray let i=index" [hidden]="isCollapsed">
            <div class="col-10 point_row col-lg-11 row">
              <div class="col-12 col-sm-4 education-edit__name">
                <p>{{item.venue}}</p>
              </div>
              <div class="col-12 col-sm-6 text-sm-right education-edit__place">
                <p> {{item.dateFrom}} - {{item.dateTo}}</p>
              </div>
            </div>
            <div class="col-12 education-edit__hr">
              <hr>
            </div>
          </div>
        </div>
      </article>

      <article class="profile-content border-sm-none container p-3 m-0 mb-2">
          <p class="color_title mb-4">{{'PROFILE.PROFESSIONAL.PROFESSION' | translate |uppercase}}</p>
        <p>{{'COLLECTIVE.CATEGORY' | translate |uppercase}}</p>
        <div class="row p-3 profession-edit__music-style mb-4">
          <p *ngFor="let categoryId of categories; let l = last">
            {{categoryNames[categoryId] | translate | uppercase}}
            <span [hidden]="l">,</span>
          </p>
        </div>
        <p>{{'COLLECTIVE.STYLE' | translate |uppercase}}</p>
        <div class="row p-3 profession-edit__music-style mb-4">
          <p  *ngFor="let styleId of styles; let l = last">
            {{stylesNames[styleId] | translate | uppercase}}
            <span [hidden]="l">,</span>
          </p>
        </div>
        <p *ngIf="groupMusicalInstrumentsArray">{{'COLLECTIVE.COMPOSITION_INSTRUMENT' | translate |uppercase}}</p>
        <div class="row p-3 profession-edit__music-style mb-4" *ngIf="groupMusicalInstrumentsArray">
          <p  *ngFor="let instId of groupMusicalInstrumentsArray; let l = last">
            {{groupMusicalInstrumentsNames[instId] | translate | uppercase}}
            <span [hidden]="l">,</span>
          </p>
        </div>
        <p *ngIf="groupVoicesArray">{{'COLLECTIVE.COMPOSITION_VOICE' | translate |uppercase}}</p>
        <div class="row p-3 profession-edit__music-style mb-4" *ngIf="groupVoicesArray">
          <p  *ngFor="let voiceId of groupVoicesArray; let l = last">
            {{groupVoicesNames[voiceId] | translate | uppercase}}
            <span [hidden]="l">,</span>
          </p>
        </div>
        <!--<p class="title">{{'COLLECTIVE.SPECIALIZATION' | translate |uppercase}}</p>-->
        <!--<div class="row p-3 profession-edit__music-style  mb-4 text-lowercase">-->
          <!--<p  *ngFor="let specializationId of specializations; let l = last">-->
            <!--{{specializationsNames[specializationId]}}-->
            <!--<span [hidden]="l">,</span>-->
          <!--</p>-->
        <!--</div>-->
        <p>{{'COLLECTIVE.TYPE_OF_THE_ACTIVITY' | translate |uppercase}}</p>
        <div class="row p-3 profession-edit__music-style mb-4">
          <p  *ngFor="let item of TYPE_OF_ACTIVITY; let l = last">
            {{item | translate | uppercase}}
            <span [hidden]="l">,</span>
          </p>
        </div>
        <p>{{'COLLECTIVE.ADDRESS' | translate |uppercase}}</p>
        <div class="row p-3 profession-edit__music-style mb-4">
          <p>{{profile?.addressActual | translate | uppercase}}</p>
        </div>
      </article>
      <article class="profile-content border-sm-none container p-3 m-0 mb-2">
        <div class="repertoire p-3 mb-4">
          <div class="row repertoire__title" (click)="isCollapsedProf = !isCollapsedProf">
            <div class="col-12 pl-0">
              <p class="color_title">{{'PROFILE.PROFESSIONAL.REPERTOIRE' | translate | uppercase}}</p>
            </div>
          </div>
          <div class="row align-items-center">
            <div class="col-2 col-lg-1 repertoire__drop up-down align-items-center"
                 (click)="isCollapsedProf = !isCollapsedProf">
              <i class="fas fa-chevron-right" aria-hidden="true" *ngIf="isCollapsedProf" ></i>
              <i class="fas fa-chevron-down" aria-hidden="true" *ngIf="!isCollapsedProf"></i>
            </div>
            <div class="col-8 col-lg-10 repertoire__line align-items-center">
              <hr>
            </div>
          </div>
          <div class="row repertoire__content" *ngFor="let repertoire of profile?.coll_repertoire;"  [hidden]="isCollapsedProf">
            <div class="col-12 col-lg-2 repertoire__genre">
              <p> {{genresDic[repertoire.genreId] | translate | uppercase}}</p>
              <div class="row d-lg-none">
                <div class="col-10 p-0">
                  <hr>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-10 row repertoire__item">
              <div class="col-12 col-lg-3 repertoire__name">
                <p>{{repertoire.composition | translate | uppercase}}</p>
              </div>
              <div class="col-12 col-lg-3 repertoire__name">
                <p>{{repertoire.composer | translate | uppercase}}</p>
              </div>
              <div class="col-12 col-lg-2 repertoire__role">
                <p>{{repertoire.role | translate | uppercase}}</p>
              </div>
              <div class="col-12 col-lg-3 repertoire__place">
                <p>{{repertoire.venue | translate | uppercase}}</p>
              </div>
              <div class="col-12 col-lg-1 repertoire__date">
                <p>{{repertoire.year | translate | uppercase}}</p>
              </div>
              <div class="col-10 p-0">
                <hr>
              </div>
              <div class="col-2 d-lg-none repertoire__pencil pencil-btn"
                   data-toggle="modal"
                   data-target="#add_repertoire_modal"></div>
            </div>
          </div>
        </div>
      </article>
      <article class="profile-content border-sm-none container p-3 m-0">
        <p class="color_title mb-4">{{'PROFILE.REVIEWS.TITLE' | translate |uppercase}}</p>
        <div class="video-view">
          <p class="resume-sec-title">{{'PROFILE.REVIEWS.VIDEO' | translate | uppercase}}</p>
          <div class="row">
            <div class="col-12 video-view__line">
              <hr>
            </div>
          </div>
          <div class="row justify-content-start m-0 p-0">
            <app-video  class="col-11 col-lg-5 ml-lg-2"  [onlyRead]="true" [videoId]="video.videoId" *ngFor="let video of profile?.coll_video;"></app-video>
          </div>
        </div>
        <div class="video-view">
          <p class="resume-sec-title">{{'PROFILE.REVIEWS.AUDIO' | translate | uppercase}}</p>
          <div class="row">
            <div class="col-12 video-view__line">
              <hr>
            </div>
          </div>
          <div class="row justify-content-start m-0 p-0">
            <app-soundcloud class="col-11 col-lg-5 ml-lg-2"  *ngFor="let soundTrack of  profile?.coll_audio;"  [onlyRead]="true" [link]="soundTrack"></app-soundcloud>
          </div>
        </div>
        <div class="referenze">
            <p  class="resume-sec-title">{{'PROFILE.REVIEWS.REFERENCE' | translate | uppercase}}</p>
            <div class="row referenze__head">
              <div class="col-12 referenze__line">
                <hr>
              </div>
            </div>
            <ul class="list-group-sos" [style.marginBottom.px]="10">
              <li class="" *ngFor="let reference of  references; let i = index">
                <a [href]="getFileUrl(reference.ref_href, reference.ref_name)" target="_blank">
                  <label class="list-group-item-heading text-field-sos">{{reference.ref_name}}</label>
                </a>
              </li>
            </ul>
          </div>
        <div class="agencies">
            <p class="resume-sec-title">{{'PROFILE.REVIEWS.AGENCIES' | translate | uppercase}}</p>
            <div class="row agencies__head">
              <div class="col-12 agencies__line">
                <hr>
              </div>
            </div>
            <div class="row agencies__item" *ngFor="let agency of  profile?.coll_agencies">
              <div class="col-10">
                <div class="row">
                  <div class="col-12 col-sm-5 agencies__name">
                    <p>{{agency.agency_name}}</p>
                  </div>
                  <div class="col-12 col-sm-6 agencies__mail">
                    <p>{{agency.agency_email}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </article>
      <div class="text-center" [hidden]="!initialized || offerId === '0' || offerId === '00'">
        <button class="btn rounded-0 form-user-data__btn btn-outline-black-invert" (click)="goToSearch($event)"  [disabled]="submitButton">
          <span [hidden]="submitButton">{{'COMMON.CLOSE' | translate}}</span>
          <i [hidden]='!submitButton' class="fas fa-spinner fa-pulse fa-fw"></i>
        </button>
      </div>
    </div>
  </div>
</div>
