import {Injectable, isDevMode} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ApiService} from 'app/_service/backend/api.service';
import {DataService} from './backend/data.service'
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class DictionaryService {
  private _dict = [];

  constructor(
    private api: ApiService,
    private _ds: DataService,
    private _ts: TranslateService) {
  }

  public reset() {
    this._dict = [];
  }

  public getDictionary(dicName: string): Observable<any> {
    return Observable.create((observer) => {
      const lang = this._ts.currentLang;

      const storeName = `${dicName}_${lang}`;

      let dic = this._dict[storeName];
      if (dic) {
        observer.next(dic);
        observer.complete();
        return;
      }

      const fields = this.getDicFields(dicName, lang);
      let json = localStorage.getItem(storeName);
      if (json && json !== 'undefined') {
        try {
          const dicObject = JSON.parse(json);
          this._dict[storeName] = dicObject;
          observer.next(dicObject);
        } catch (err) {
          if (isDevMode()) {
            console.log(err);
          }
        }
        observer.complete();
      } else {
        this._ds.getDictionary(dicName, fields, lang).subscribe(
          dicRes => {
            this._dict[storeName] = dicRes;
            try {
              localStorage.setItem(storeName, JSON.stringify(dicRes));
            } catch (err) {
              if (isDevMode()) {
                console.log(err);
              }
            }

            observer.next(dicRes);
            observer.complete();
          },
          err => {
            observer.error(err);
          }
        );
      }
    });
  }

  public getFilteredDictionary(dicName: string, filterFunc: (any) => boolean) {
    return Observable.create((observer) => {
        this.getDictionary(dicName).subscribe(
          dic => {
            observer.next(dic.filter(filterFunc));
          },
          err => {
            if (isDevMode()) {
              console.log(err);
            }
            observer.error(err);
          }
        );
      }
    );
  }

  public getDictionaryNames(dicName: string, keyName?: string): Observable<any> {
    return Observable.create((observer) => {
      this.getDictionary(dicName).subscribe(
        arr => {
          let obj = {};
          if (Array.isArray(arr)) {
            let key = keyName ? keyName : 'id';
            arr.forEach(el => {
              obj[el[key]] = el.name;
            });
          }
          observer.next(obj);
          observer.complete();
        },
        err => {
          observer.error(err);
        }
      );
    });
  }

  private getDicFields(dicName: string, lang: string): string[] {
    switch (dicName) {
      case 'dic_specializations':
        return ['id', 'profId'];
      case 'dic_prof_genres':
        return ['id', 'profId'];
      case 'dic_collective_categories':
        return ['id', 'typeId'];
      case 'dic_collective_types':
        return ['id', 'type'];
      case 'countries':
      case 'dic_type_of_activity':
      case 'languages':
        return ['code'];
      default:
        return ['id']
    }
  }

  public findComposers(text: string): Observable<any> {
    const self = this;
    const subject = new Subject();
    this.api.post('/api/query/find_composers', {'text': text})
      .subscribe(res => {
        subject.next(res);
        subject.complete();
      }, error => {
        subject.error(error)
      });
    return subject;
  }

  public findCompositions2(composerName: string, text: string): Observable<any> {
    const self = this;
    const subject = new Subject();

    self.api.post('/api/action/get_composition2', {
      'composerName': composerName,
      'searchText': text
    }).subscribe((res: any) => {
      subject.next(res);
      subject.complete();
    }, error => {
      subject.error(error)
    });
    return subject;
  }
}
