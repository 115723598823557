import { Component, isDevMode, OnInit, OnDestroy } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'app/_service/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Unsubscribable } from 'rxjs';
import { CommonService } from 'app/_service/common.service';
import { ApiService } from 'app/_service/backend/api.service';

@Component({
  selector: 'app-gdpr',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css']
})
export class ContractComponent implements OnInit, OnDestroy {
  inviteId: string;
  offerId: string;
  time: string;
  signPath: string;
  getPath: string;
  redirectPath: any[];
  backPath: any[];
  state: string;
  initialization = false;
  role: string;
  backLinkTitle: string;
  isButtonPushed: boolean = false;
  http: string;
  hideOtherLang = this._getClass(this.ts.currentLang);
  private subscriptions: Unsubscribable[] = [];
  artRole = '';

  constructor(
    private cs: CommonService,
    private api: ApiService,
    private router: Router,
    private us: UserService,
    private route: ActivatedRoute,
    private ts: TranslateService
  ) {
    this.role = this.us.getRole();
  }

  ngOnInit() {
    this.cs.togleSpiter(true);
    this.route
      .queryParamMap.subscribe(
        params => {
          this.time = params.get('time');
        });
    this.route.params.subscribe(params => {
      this.inviteId = params['inviteId'];
      this.offerId = params['offerId'];
      this.artRole =  params['artRole'];
      if (!this.inviteId) {
        this.router.navigate(['/']);
        return;
      }
      switch (this.role) {
        case 'musician':
          // this.signPath = `/api/invite/musician/signed`;
          this.getPath = `/api/invite/state/${this.inviteId}`;
          this.backPath = [`/invitations/mus/card/${this.inviteId}`];
          this.backLinkTitle = 'COMMON.MY_INVITATION';
          break;
        case 'organizer':
          // this.signPath = `/api/invite/organizer/signed`;
          this.getPath =  this.artRole == "mus" ? `/api/invite/state/${this.inviteId}` : `/api/invite/coll/state/${this.inviteId}`;
          this.backPath = this.artRole == "mus" ? [`/invitations/org/list/${this.offerId}`] : [`/invitations/org/coll/list/${this.offerId}`];
          this.redirectPath =this.artRole == "mus" ? ['/invitations/org/expiration-time/mus', this.inviteId, this.offerId] : ['/invitations/org/expiration-time/coll', this.inviteId, this.offerId];
          this.backLinkTitle = 'COMMON.BACK';
          break;
        case 'collective':
          // this.signPath = `/api/invite/organizer/signed`;
          this.getPath = `/api/invite/coll/state/${this.inviteId}`;
          this.backPath = [`/invitations/coll/card/${this.inviteId}`];
          this.backLinkTitle = 'COMMON.BACK';
          break;
      }
      this.api.get(this.getPath).subscribe((result: any) => {
        this.state = result.state;
        //this.loading = false;
      });
      if(this.artRole == "mus"){
        this.api.get(`/api/invite/contract/${this.inviteId}`).subscribe((result: any) => {
          this.http = result;
          this.cs.togleSpiter(false);
          this.initialization = true;
        });
      } else if(this.artRole == "coll"){
        this.api.get(`/api/invite/contract/coll/${this.inviteId}`).subscribe((result: any) => {
          this.http = result;
          this.cs.togleSpiter(false);
          this.initialization = true;
        });
      }

      let sub = this.ts.onLangChange.subscribe(langEvent => {
        this.hideOtherLang = this._getClass(langEvent.lang);
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      });
      this.subscriptions.push(sub);
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  openInvitation($event) {
    this.isButtonPushed = true;
    //$event.stopPropagation();
    this.router.navigate(this.backPath);
    this.isButtonPushed = false;
  }
  _getClass(lang) {
    switch (lang) {
      case 'ru':
        return 'visible-ru';
      case 'it':
        return 'visible-it';
      default:
        return 'visible-en';
    }
  }
  hideButton(state) {
    switch (this.role) {
      case 'musician':
        return [4].indexOf(+state) === -1;
      case 'collective':
        return [4].indexOf(+state) === -1;
      case 'organizer':
        return [2, 3].indexOf(+state) === -1;
    }
  }

  signIn() {
    this.isButtonPushed = true;
    if (this.role === 'musician') {
      this.cs.togleSpiter(true);
      this.api.post(
        `/api/invite/musician/signed`,
        { inviteId: this.inviteId, offerId: this.offerId, time: this.time })
        .subscribe((result: any) => {
          this.cs.togleSpiter(false);
          if (result.success) {
            this.router.navigate(this.backPath);
          }
        },
          err => {
            this.cs.togleSpiter(false);
            console.log(err);
          });
      return;
    }
    if (this.role === 'collective') {
      this.cs.togleSpiter(true);
      this.api.post(
        `/api/invite/coll/signed`,
        { inviteId: this.inviteId, offerId: this.offerId, time: this.time })
        .subscribe((result: any) => {
          this.cs.togleSpiter(false);
          if (result.success) {
            this.router.navigate(this.backPath);
          }
        },
          err => {
            this.cs.togleSpiter(false);
            console.log(err);
          });
      return;
    }
    this.isButtonPushed = false;
    this.router.navigate(this.redirectPath);
  }
}
