import {Component, OnInit, ViewChild, isDevMode} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {UserService} from 'app/_service/user.service';
import {AlertComponent} from 'app/user/shared/shared.module';
import {passwordValidator} from 'app/_service/validators';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {
  @ViewChild('alert', { static: true }) private alert: AlertComponent;
  shouldCheck = false;
  passwordMessage = '';
  token: '';
  password: FormControl = this.fb.control('', [
    Validators.required,
    passwordValidator
  ]);
  confirmPassword: FormControl = this.fb.control('', [Validators.required]);
  regForm: FormGroup = this.fb.group({
    password: this.password,
    confirmPassword: this.confirmPassword
  }, {
    validator: this.equalPassword
  });

  constructor(public fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              public userService: UserService) {
  }

  ngOnInit() {
    this.confirmPassword.valueChanges.subscribe((value) => {
      if (value !== this.password.value) {
        this.passwordMessage = 'danger';
      } else {
        this.passwordMessage = 'success';
      }
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
    this.route
      .queryParamMap
      .subscribe(
        (value: any) => {
          if (value.params['token']) {
            this.token = value.params['token'];
          }
        }, err => {
          if (isDevMode()) {
            console.log(err);
        }
        }
      );
  }

  public equalPassword({value}: FormGroup): { [key: string]: any } {
    const no = value.password !== value.confirmPassword;
    return no ? {'passwordNoEqual': {name}} : null;
  }

  save() {
    if (this.regForm.invalid || !this.token) {
      this.shouldCheck = true;
      return;
    }
    this.userService.resetPassword(this.password.value, this.token).subscribe(() => {
        this.router.navigate(['signin'], { queryParams: { passportResetSuccess: true }});
      },
      error => {
        this.alert.showMessage(error.data, 'danger', true);
      });
  }
}
