import {Component, isDevMode, OnInit} from '@angular/core';

import {concat, Observable} from 'rxjs';


import {ActivatedRoute, Router} from '@angular/router';

import {DictionaryService} from 'app/_service/dictionary.service';
import {CommonService} from 'app/_service/common.service';
import {DataService} from 'app/_service/backend/data.service';
import {UserService} from 'app/_service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {TYPE_OF_ACTIVITY} from 'app/constants';
import {last, map} from 'rxjs/operators';
import { FileService } from 'app/_service/file.service';
import { ApiService } from 'app/_service/backend/api.service';
import { ROLES } from 'app/constants';

@Component({
  selector: 'app-search-details-collective',
  templateUrl: './search-details-collective.component.html',
  styleUrls: ['./search-details-collective.component.css']
})
export class SearchDetailsCollectiveComponent implements OnInit {
  id: Number;
  offerId: string;
  role: string;
  profile: any;
  professions = [];
  avatarUrl: string = '';
  gender: string;
  categories = [];
  languages = [];
  references: any[] = [];
  isCollapsed = false;
  isCollapsedProf = false;
  invitation: boolean = false;
  submitButton: boolean = true;
  initialized: boolean = false;
  professionsNames = {};
  languagesNames = {};
  groupMusicalInstrumentsNames = {};
  groupVoicesNames = {};
  countriesDic = {};
  stylesNames: {} = {};
  genresDic: any[] = [];
  styles = [];
  toursArray = [];
  collTypeNames = {};
  categoryNames = {};
  currentLang = this.ts.currentLang;
  TYPE_OF_ACTIVITY = TYPE_OF_ACTIVITY;
  groupMusicalInstrumentsArray: any[];
  groupVoicesArray: any[];

  constructor(private dataS: DataService,
              private ds: DictionaryService,
              private cs: CommonService,
              private us: UserService,
              private fs: FileService,
              private ts: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private api: ApiService
              ) {
  }

  ngOnInit() {
    this.cs.togleSpiter(false);
    this.route.params.subscribe(params => {
      const tmpId = params['id'];
      this.id = isNaN(tmpId) ? 0 : +tmpId;
      this.role = params['role'];
      this.offerId = params['offerId'];
      console.log(this.offerId);
      if (!(this.id && this.role && this.offerId)) {
        this.router.navigate(['/']);
        return;
      }
    });
    this.cs.togleSpiter(true);
    this.initDictionaries().subscribe(
      (item) => {
      },
      err => console.error(err),
      () => {
        this.dataS.get_coll_card(this.id, this.offerId)
          .pipe(
            last()
          )
          .subscribe((collective: any) => {
            this.cs.togleSpiter(false);
            this.profile = collective;
            console.log(collective);
            this.toursArray = collective.coll_tours;
            for (let i in this.toursArray) {
              this.toursArray[i].dateFrom = this.parseDate(this.toursArray[i].from);
              this.toursArray[i].dateTo = this.parseDate(this.toursArray[i].to);
            }
            if (typeof (this.profile.categories) === 'string') {
              this.categories = this.profile.categories.length > 0 ? this.profile.categories.split(',') : [];
            }
            if (typeof (this.profile.styles) === 'string') {
              this.styles = this.profile.styles.split(',');
            }
            if (typeof this.profile.languages === 'string') {
              this.languages = this.profile.languages.length > 0 ? this.profile.languages.split(',') : [];
            }
            if (typeof( this.profile.group_musical_instruments) === 'string') {
              this.groupMusicalInstrumentsArray =  this.profile.group_musical_instruments.length > 0 ?
                this.profile.group_musical_instruments.split(',') : [];
            }
            if (typeof( this.profile.group_voices) === 'string') {
              this.groupVoicesArray =  this.profile.group_voices.length > 0 ?  this.profile.group_voices.split(',') : [];
            }

            this.references = Array.isArray(this.profile.coll_references)
              ? this.profile.coll_references.map(item => {
                return {...item, ref_href: `${item.ref_href}/${this.profile.user}`}
              }) : [];

            this.invitation = Array.isArray(this.profile.coll_invitations) && this.profile.coll_invitations.length !== 0;
            console.log(this.profile);
            const avatar = this.profile.avatar ? `${this.profile.avatar}/${this.profile.user}` : '';
            this.avatarUrl = this.fs.getFileUrl(avatar, null, true);
            this.submitButton = false;
            this.initialized = true;
          }, () => {
          });
      }
    );
    this.ts.onLangChange.subscribe(langEvent => {
      this.currentLang = langEvent.lang;
      this.initDictionaries().subscribe();
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
  }

  getFileUrl(path: string, name: string): string {
    return this.fs.getFileUrl(path, name);
  }

  invite($event) {
    $event.stopPropagation();
    this.submitButton = true;
    this.api.post('/api/invite/organizer/coll/sent', {offer: this.offerId, user: this.profile.user}, {responseType: 'text'})
    .subscribe(() => {
        this.submitButton = false;
        this.invitation = true;
        // console.log(value);
      },
      () => {
        this.submitButton = false;
        // console.log(err);
      });
  }

  goToSearch(event) {
    event.stopPropagation();
    this.submitButton = true;

    let path;
    if (this.role === 'Invite') {
      path = [`/invitations/org/coll/list/${ this.offerId}`];
    }  else if (this.role === 'info') {
      path = [`/tour-calendar`];
    } else {
      path = this.role !== ROLES[3][0] ? ['offer/search-mus', this.offerId, this.role] : ['/my-musicians'];
    }
    this.router.navigate(path);
  }

  private initDictionaries(): Observable<any> {
    return concat(
      this.ds.getDictionaryNames('dic_professions')
        .pipe(
          map(
            dic => {
              this.professionsNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_styles')
        .pipe(
          map(
            dic => {
              this.stylesNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_prof_genres')
        .pipe(
          map(
            genres => {
              this.genresDic = genres;
            }
          )
        ),
      this.ds.getDictionary('countries')
        .pipe(
          map(dic => {
            dic.forEach(item => this.countriesDic[item.code] = item.name);
          })
        ),
      this.ds.getDictionaryNames('dic_collective_types', 'id')
        .pipe(
          map(names => {
            this.collTypeNames = names;
          })
        ),
      this.ds.getDictionaryNames('dic_collective_categories', 'id')
        .pipe(
          map(names => {
            this.categoryNames = names;
          })
        ),
      this.ds.getDictionary('dic_specializations')
        .pipe(
          map(dic => {
            dic.forEach( item => {
              if (item.profId === 2) {
                this.groupMusicalInstrumentsNames[item.id] =  item.name;
              } else if (item.profId === 3) {
                this.groupVoicesNames[item.id] =  item.name;
              }
            });
          })
        ),
      this.ds.getDictionaryNames('languages', 'code')
        .pipe(
          map(names => this.languagesNames = names)
        )
    );
  }

  parseDate(dateTime: string) {
    return this.cs.parseDate(dateTime);
  }
}
