import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-soundcloud',
  templateUrl: './soundcloud.component.html',
  styleUrls: ['./soundcloud.component.css']
})
export class SoundcloudComponent implements OnInit {
  @Input() link: {trackId: String};
  @Input() onlyRead: boolean = false;
  @Output() close = new EventEmitter();
  soundCloudUrl: SafeResourceUrl;
  dangerousVideoUrl: string;
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  this.dangerousVideoUrl = `https://w.soundcloud.com/player/?url=${this.link.trackId}&amp;{ ADD YOUR PARAMETERS HERE }`;
  this.soundCloudUrl =
    this.sanitizer.bypassSecurityTrustResourceUrl(this.dangerousVideoUrl);
  }
  closeEvent() {
    this.close.emit();
  }

}
