import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { StorageService } from 'app/_service/storage.service';

@Injectable()
export class JsonService {

  constructor(
    private http: HttpClient,
    private storage: StorageService) 
    {
  }

  public getJSON(name): Observable<any> {
    const version = this.storage.read('version');
    return this.http.get(`assets/${name}?version=${version}`);
  }
}
