import {Injectable, isDevMode} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {ApiService} from 'app/_service/backend/api.service';
import {StorageService} from 'app/_service/storage.service';
import {DictionaryService} from 'app/_service/dictionary.service';
import {WindowRef} from './WindowRef';
import {catchError, map} from 'rxjs/operators';
import {NotificationService} from './notification.service'

@Injectable()
export class UserService {
  account: string;
  private error: string;
  private user: User;
  private subjectUser = new ReplaySubject(1);
  private nativeWindow;
  public getUser$ = this.subjectUser.asObservable();
  private loginLogoutSubj = new Subject();
  public loginLogout$ = this.loginLogoutSubj.asObservable();

  public readonly STORAGE_URL = this.api.geFulltUrl('/api/files');

  constructor(
    private ns: NotificationService,
    private router: Router,
    private api: ApiService,
    private storageService: StorageService,
    private winRef: WindowRef,
    private dictionaryService: DictionaryService) {
      this.nativeWindow = winRef.getNativeWindow();
  }


  public reset(): void {
    this.user = null;
    this.subjectUser.next(null);
  }

  public getProfilePath(): string {
    return `profile/${this.getRole()}`;
  }

  public getRole(): string {
    return this.user && typeof this.user.userRole === 'string' ? this.user.userRole.toLocaleLowerCase() : '';
  }

  public getLocalNameRole(): string {
    switch (this.getRole()) {
      case 'musician':
        return 'ROLE.MUSICIAN';
      case 'collective':
        return 'ROLE.COLLECTIVE';
      case 'organizer':
        return 'ROLE.ORGANIZER';
      case 'agency':
        return 'ROLE.AGENCY';
    }
    return this.user && typeof this.user.userRole === 'string' ? this.user.userRole.toLocaleLowerCase() : '';
  }

  public getUserName(): string {
    return this.user && typeof this.user.email === 'string' ? this.user.email.toLocaleLowerCase() : null;
  }

  public getUserId(): any {
    return this.user ? this.user.id : null;
  }

  public isAuth(): Observable<any> {
    return this.api.get('/api/user')
      .pipe(
        map((user: any) => {
          if (user && user.id) {
            this.setUser(user);
            //this.ws.connect();
            this.ns.start();
          }
          return user;
        }),
        catchError(err => {
          if (err.status === 401) {
            this.logout();
          }
          throw err;
        })
      );
  }

  public getUserDetails(): Observable<User> {
    return Observable.create((observer) => {
      this.isAuth().subscribe(
        (user: any) => {
          this.setUser(user);
          observer.next(user);
          observer.complete();
        }, (err: any) => {
          this.subjectUser.next(null);
          observer.error(err);
        }
      )
    });
  }

  public resetPassword(newPassword: any, token: any): Observable<any> {
    let subject = new Subject();
    this.api.post('api/changepassword/remote', {newPassword, token}, {responseType: 'text'})
      .subscribe(
        (data: any) => {
          if (isDevMode()) {
            console.log(data);
          }
          subject.next(data);
          subject.complete();
        },
        (err: any) => {
          if (isDevMode()) {
            console.error(err);
          }
          if (err.status === 401) {
            this.logout();
          }
          subject.error(err);
        });
    return subject;
  }

  public changePassword(oldPassword: any, newPassword: any): Observable<any> {
    let subject = new Subject();
    this.api
      .post(
        'api/changepassword',
        {oldPassword: oldPassword, newPassword: newPassword},
        {responseType: 'text'})
      .subscribe(
        () => {
          subject.next('success');
          subject.complete();
        },
        err => {
          if (err.status === 401) {
            this.logout();
          }
          subject.error(err);
        }
      );
    return subject;
  }

  //
  public resetPasswordEmail(email: string): Observable<any> {
    let subject = new Subject();
    this.api.get(`api/resetpass/${email}`, {responseType: 'json'})
      .subscribe(
        () => {
          subject.next(true);
          subject.complete();
        }, err => {
          if (err.status === 401) {
            this.logout();
          }
          subject.error(err);
        }
      );
    return subject;
  }

  public action(path): Observable<any> {
    return this.api.get(`api/action/${path}`);
  }

  public updateUser(user): Observable<Object> {
    let subject = new Subject();
    this.api.patch('/api/object/users',
      {...user})
      .subscribe(() => {
          Object.assign(this.user, user);
          this.subjectUser.next(this.user);
          subject.next(this.user);
          subject.complete();
        },
        err => {
          if (err.status === 401) {
            this.logout();
          }
          subject.error(err);
        });
    return subject;
  }

  public logout(path?): void {
    const redirect_path = path || '/signin';
    this.api.get('/api/logout', {responseType: 'text'})
      .subscribe(() => {
          //this.ws.disconnect();
          this.ns.stop();
          this.reset();
          this.loginLogoutSubj.next();
          // this.subjectUser.complete();
          this.storageService.clear();
          this.dictionaryService.reset();
          this.router.navigateByUrl(redirect_path);
        },
        (error: any) => {
          this.error = error;
        }
      );
  }

  public signUp(user): Observable<any> {
    let subject = new Subject();
    this.api.post('/api/signup', user, {responseType: 'text'})
      .subscribe(res => {
          if (isDevMode()) {
            console.log(res);
          }
          subject.next(res);
          subject.complete();
        },
        err => {
          subject.error(err);
          if (err.status === 401) {
            this.logout();
          }
        });
    return subject;
  }

  public signIn(username: string, password: string): Observable<any> {
    let subject = new Subject();
    this.api.post('/api/login', {username: username, password: password})
      .subscribe((user: User) => {
          this.setUser(user);
          subject.next(this.user);
          subject.complete();
          //this.ws.connect();
          this.ns.start();
          if (isDevMode()) {
            console.log(user);
          }
        }, err => {
          if (err.status === 401) {
            this.logout();
          }
          subject.error(err);
        }
      );

    return subject;
  }

  public socialLogin(provider): Observable<any> {
    const subject = new Subject();
    const IE11 = window.navigator.userAgent.indexOf('rv:11.0') > 0;
    let path = '/';
    switch (provider) {
      case 'google':
        path = '/api/auth/google';
        break;
      case 'facebook':
        path = '/api/auth/facebook';
        break;
      case 'linkedin':
        path = '/api/auth/linkedin';
    }
    if (IE11) {
      const popup = this.nativeWindow.open('', '', 'specleft=1, top=1, width=500, height=560');
      popup.location = this.api.geFulltUrl(path);
    } else {
      this.nativeWindow.open(this.api.geFulltUrl(path), '_blank'); //, 'specleft=1, top=1, width=500, height=560');
    }
    const callbackAll = (event) => {
      // console.log('event', event);
      this.nativeWindow.localStorage.setItem('access_token', event.data);
      this.nativeWindow.removeEventListener('message', callbackAll);
      this.getUserDetails().subscribe(user => {
        subject.next(user);
        subject.complete();
      }, err => {
        subject.error(err);
        subject.complete();
      });
      //this.ws.connect();
      this.ns.start();
    };
    const callbackIE = (event) => {
      this.nativeWindow.removeEventListener('storage', callbackIE);
      if (event.key === 'social') {
        this.getUserDetails().subscribe(user => {
          subject.next(user);
          subject.complete();
        }, err => {
          subject.error(err);
          subject.complete();
        }, () => {
          localStorage.removeItem('social');
        });
      }
    };

    if (IE11) {
      this.nativeWindow.addEventListener('storage', callbackIE);
    } else {
      this.nativeWindow.addEventListener('message', callbackAll, false);
    }
    return subject;
  }

  //TODO: Move to FileService
  public uploadFile(name, result): Observable<any> {
    const subject = new Subject();
    let url = `/api/files/${name}`;
    let headers = new HttpHeaders();
    headers = headers.set('accept', 'application/octet-stream *');
    const upload = this.api.post(url, result, {headers: headers});
    upload.subscribe((data: any) => {
      subject.next(data);
      subject.complete();
    }, err => {
      if (err.status === 401) {
        this.logout();
      }
      // console.log(err);
    });
    return subject;
  };

  // TODO: Move to FileService
  public removeFile(name): Observable<any> {
    let subject = new Subject();
    this.api.delete(`/api/files/${name}`).subscribe(
      () => {
        subject.next(true);
        subject.complete();
      },
      err => {
        if (err.status === 401) {
          this.logout();
        }
        subject.complete();
      });
    return subject;
  };

  // TODO: Move to FileService
  public getFileUrl(path: string, fileName: string = '', inline: boolean = false): string {
    const token = sessionStorage.getItem('access_token') || localStorage.getItem('access_token');
    if (!path) {
      return '';
    }
    let resultPath = `${this.STORAGE_URL}/${path}?filename=${encodeURIComponent(fileName)}`;
    if (inline) {
      resultPath = `${resultPath}&type=img`
    }
    if (typeof(token) === 'string') {
      resultPath = `${resultPath}&access_token=${token}`
    }
    return resultPath;
  }

  public patchUser(data: User): void {
    this.user = {...this.user, ...data};
    this.subjectUser.next(this.user);
    if (isDevMode()) {
      console.log(this.user);
    }
  }

  private setUser(user: User): void {
    this.user = {...user};
    this.subjectUser.next(this.user);
    if (isDevMode()) {
      console.log(this.user);
    }
  }
}
