import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ApiService} from 'app/_service/backend/api.service';
import {StorageService} from 'app/_service/storage.service';
import * as moment from 'moment';

@Injectable()
export class DataService {

  constructor(
    private api: ApiService,
    private sorage: StorageService
  ) {
  }
  public getDictionary(dicName: string, fields: string[], lang: string): Observable<any> {
    return Observable.create((observer) => {
      this.api.post('/api/query/get_dictionary', {
        'dicName': dicName,
        'fields': fields.join(','),
        'lang': lang
      }).subscribe(res => {
          observer.next(res);
          observer.complete();
        },
        error => {
          observer.error(error);
        });
    });
  };

  public get_mus_profile_stat(): Observable<any> {
    return Observable.create((observer) => {
      this.api.get('/api/action/mus_profile_stat').subscribe(res => {
          observer.next(res);
          observer.complete();
        },
        error => {
          observer.error(error);
        })
    });
  };

  public get_mus_card(id, offerId = null): Observable<any> {
    return Observable.create((observer) => {
      this.api.post('/api/action/get_mus_card', {
        'userId': id,
        'offerId': offerId
      }).subscribe(res => {
        observer.next(res);
        observer.complete();
      }, error => {
        observer.error(error);
      })
    });
  };

  public get_coll_card(id, offerId = null): Observable<any> {
    return Observable.create((observer) => {
      this.api.post('/api/action/get_coll_card', {
        'userId': id,
        'offerId': offerId
      }).subscribe(res => {
        observer.next(res);
        observer.complete();
      }, error => {
        observer.error(error);
      })
    });
  };

  public get_welcome_data(): Observable<any> {
    return Observable.create((observer) => {
      this.api.get('/api/action/get_welcome_data')
        .subscribe(res => {
          const profileLIst = res;
          if (Array.isArray(profileLIst) && profileLIst.length > 0) {
            observer.next(res[0]);
            observer.complete();
          }
          observer.error({'message': 'invalid response'});
        }, error => {
          observer.error(error);
        })
    });
  };

  public agreed_acquainte(): Observable<any> {
    return Observable.create((observer) => {
      this.api.get('/api/action/agreed_acquainted')
        .subscribe(res => {
          observer.next(res);
          observer.complete();
        }, error => {
          observer.error(error);
        })
    });
  };

  public set_welcome_data(dataObject): Observable<any> {
    return Observable.create((observer) => {
      this.api.post('/api/action/set_welcome_data', dataObject)
        .subscribe(user => {
          observer.next(user);
        }, error => {
          observer.error(error);
        });
    });
  };
}
