<section class="preview-image preview-image__sign-up">
  <div class="form form_sign-up">
    <h1>{{'SIGN_UP.TITLE' | translate}}</h1>
    <div class="form__regulations mb-3">{{'SIGN_UP.YOU_AGREE' | translate}}
      <a [href]="_apiUrl+'documents/terms'" target="_blank">{{'SIGN_UP.TERMS_OF_SERVICE' | translate}}</a>
      {{'SIGN_UP.AND' | translate}}
      <a [href]="_apiUrl+'documents/policy'" target="_blank">{{'SIGN_UP.PRIVATE_POLICY' | translate}}</a>
    </div>
    <div class="form__links sos__cursor" [ngClass]="buttonHide()" (click)="social('linkedin')">
      <div class="form__icon"><img src="assets/img/icons/jlid.png" alt="linkedin" />
      </div>
      <div class="form__link"><a class="social-text">{{'SIGN_IN.SIGN_IN_WITH' | translate}} Linkedin</a></div>
    </div>
    <div class="form__links sos__cursor" [ngClass]="buttonHide()" (click)="social('facebook')">
      <div class="form__icon"><img src="assets/img/icons/form_f.png" alt="facebook" />
      </div>
      <div class="form__link"><a class="social-text">{{'SIGN_IN.SIGN_IN_WITH' | translate}} Facebook</a></div>
    </div>
    <div class="form__links sos__cursor" [ngClass]="buttonHide()" (click)="social('google')">
      <div class="form__icon"><img src="assets/img/icons/form_g.png" alt="google" />
      </div>
      <div class="form__link"><a class="social-text">{{'SIGN_IN.SIGN_IN_WITH' | translate}} Google</a></div>
    </div>
    <p>{{'SIGN_UP.SIGN_UP_USING_EMAIL' | translate}}:</p>
    <form [formGroup]="regForm">
      <p class="text-danger" *ngIf="userRole">{{'PROFILE.ACCOUNT.NEED_ROLE' | translate}}</p>
      <div class="form-group form__inputs">
        <select class="form-control input-lg" [class.is-invalid]="role.invalid && shouldCheck" formControlName="role">
          <option value="">{{'SIGN_UP.CHANGE_ROLE' | translate}}</option>
          <option [value]="role[0]" *ngFor="let role of ROLES">{{role[1] | translate}}</option>
          <!--
          <option value="Collective">{{'SIGN_UP.COLLECTIVE' | translate}}</option>
          <option value="Service">{{'SIGN_UP.SERVICE' | translate}}</option>
          <option value="Agency">{{'SIGN_UP.AGENCY' | translate}}</option>
        -->
        </select>
        <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
      </div>
      <div class="form-group form__inputs">
        <input type="text" class="form-control" placeholder="{{'PROFILE.PERSONAL.FIRST_NAME' | translate}}"
          formControlName="firstName"
          [class.is-invalid]="(firstName.invalid && shouldCheck) || firstName.errors && firstName.errors['nameError']">
        <div class="invalid-feedback" *ngIf="firstName.errors && firstName.errors['nameError']">
          {{"COMMON.LATIN_FIELD" | translate}}</div>
        <div class="invalid-feedback" *ngIf="firstName.errors && firstName.errors['required']">
          {{"COMMON.REQUIRE_FIELD" | translate}}</div>
      </div>
      <div class="form-group form__inputs">
        <input type="text" class="form-control" placeholder="{{'PROFILE.PERSONAL.LAST_NAME' | translate}}"
          formControlName="lastName"
          [class.is-invalid]="(lastName.invalid && shouldCheck) || (lastName.errors && lastName.errors['nameError'])">
        <div class="invalid-feedback" *ngIf="lastName.errors && lastName.errors['nameError']">
          {{"COMMON.LATIN_FIELD" | translate}}</div>
        <div class="invalid-feedback" *ngIf="lastName.errors && lastName.errors['required']">
          {{"COMMON.REQUIRE_FIELD" | translate}}</div>
      </div>
      <div class="form-group form__inputs">
        <input type="email" class="form-control" placeholder="Email" formControlName="email"
          [class.is-invalid]="email.invalid && shouldCheck">
        <div class="invalid-feedback d-block" *ngIf="email.errors && email.errors['pattern']">
          {{"COMMON.INVALID_EMAIL" | translate}}</div>
        <div class="invalid-feedback" *ngIf="email.errors && email.errors['required']">
          {{"COMMON.REQUIRE_FIELD" | translate}}</div>
      </div>
      <div class="form-group form__inputs">
        <input type="password" class="form-control" placeholder="{{'PROFILE.ACCOUNT.PASSWORD' | translate}}"
          formControlName="password" [class.is-invalid]="password.invalid && shouldCheck">
        <div class="invalid-feedback" *ngIf="password.errors && password.errors['required']">
          {{"COMMON.REQUIRE_FIELD" | translate}}</div>
        <div class="invalid-feedback" *ngIf="password.errors && password.errors['passwordError']">
          {{"COMMON.PASSWORD_PATTERN" | translate}}</div>
      </div>
      <div class="form-group form__inputs">
        <input type="password" class="form-control" id="confirmPassword"
          placeholder="{{'PROFILE.ACCOUNT.CONFIRM_PASSWORD' | translate}}" formControlName="confirmPassword"
          [class.is-invalid]="confirmPassword.invalid && shouldCheck || passwordMessage == 'danger'">
        <div class="invalid-feedback" *ngIf="confirmPassword.errors && confirmPassword.errors['required']">
          {{"COMMON.REQUIRE_FIELD" | translate}}
        </div>
        <div class="invalid-feedback" *ngIf="passwordMessage == 'danger' && shouldCheck">
          {{'PROFILE.ACCOUNT.DIFFERENT_PASSWORD' | translate}}
        </div>
      </div>
    </form>
    <div class="form__already_acc">{{"SIGN_UP.ALREADY_HAVE_AN_ACCOUNT" | translate}} <a [routerLink]="['/signin']">
        {{"SIGN_UP.LOGIN" | translate}}</a></div>
    <app-alert #alert></app-alert>
    <button class="btn btn-outline-black-invert" [disabled]="regForm.invalid && shouldCheck || !submitForm"
      (click)="save(regForm.value)" (keypress.enter)="save(regForm.value)">
      <i [hidden]='submitForm' class="fas fa-spinner fa-pulse fa-fw"
        data-badge="inline"></i>{{'SIGN_UP.SUBMIT' | translate}}</button>
    <div class="form__regulations mt-3">
      {{"CAPTCHA.MAIN" | translate}}
      <a href="https://policies.google.com/privacy">{{"CAPTCHA.PRIVACY_POLICY" | translate}}</a>
      {{'SIGN_UP.AND' | translate}}
      <a href="https://policies.google.com/terms">{{"CAPTCHA.TERMS_OF_SERVICE" | translate}}</a>{{"CAPTCHA.LAST_PART" | translate}}.
    </div>
  </div>
</section>