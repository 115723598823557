import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-parser-formatter';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {HomeComponent} from './home/home.component';
import {SignupComponent} from './user/signup/signup.component';
import {SigninComponent} from './user/signin/signin.component';
import {ProfileComponent} from './user/profile/profile.component';
import {StatusCollectionPipe} from './user/profile/status-collection.pipe';
import {ResetPasswordComponent} from './user/reset-password/reset-password.component';
import {RequestPasswordResetComponent} from './modal/request-password-reset/request-password-reset.component';
import {MenuAppComponent} from './menu/menu-app/menu-app.component';
import {AppFooterComponent} from './footer/app-footer/app-footer.component';
import {HowItWorksComponent} from './home/how-it-works/how-it-works.component';
import {ContactsComponent} from './home/contacts/contacts.component';
import {DictionaryService} from 'app/_service/dictionary.service';
import {ProfileMenuComponent} from './user/profile/profile-menu/musucian-menu/musucian-menu.component';
import {OrganizerMenuComponent} from './user/profile/profile-menu/organizer-menu/organizer-menu.component';
import {ProfileButtonComponent} from './menu/profile-button/profile-button.component';
import {GuardService} from 'app/app-routing/guard.service';
import {ProfileGuard} from 'app/app-routing/profile-guard';
import {AgreeService} from 'app/app-routing/agreeService';
import {GuardSave} from 'app/app-routing/guard-save';
import {OfferGuardService} from 'app/app-routing/offer-guard.service';
import {WelcomeService} from 'app/app-routing/welcomeService';
import {AuthGuardService} from 'app/app-routing/auth-guard.service';
import {ApiService} from 'app/_service/backend/api.service';
import {SpecializationService} from 'app/_service/specialization.service';
import {MusicianProfileService} from 'app/_service/musician-profile/musician-profile.service';
import {StorageService} from 'app/_service/storage.service';
import {UserService} from 'app/_service/user.service';
import {OrganizerProfileService} from 'app/_service/organizer-profile/organizer-profile.service';
import {ListNotificationsComponent} from 'app/user/list-notifications/list-notifications.component';
import {SearchService} from 'app/user/offer/search.service';
import {LocationModule} from 'app/user/location/location.module';
import {ForgotPasswordComponent} from './user/forgot-password/forgot-password.component';
import {CommonService} from 'app/_service/common.service';
import {ProgressbarModule} from 'app/user/profile/progressbar/progressbar.module';
import {JsonService} from 'app/_service/json/json.service';
import {AuthInterceptor} from './_service/interceptor/auth/auth.service';
import {SharedModule} from 'app/user/shared/shared.module';
import {DataService} from 'app/_service/backend/data.service';
import {NewPasswordComponent} from './user/forgot-password/new-password/new-password.component';
import {DocComponent} from './doc/doc.component';
import {WindowRef} from './_service/WindowRef';
import {FillProfileComponent} from './user/fill-profile/fill-profile.component';
import {CollectiveMenuComponent} from './user/profile/profile-menu/collective-menu/collective-menu.component';
import {AgencyMenuComponent} from './user/profile/profile-menu/agency-menu/agency-menu.component';
import {TermsAcquaintedComponent} from './user/terms-acquainted/terms-acquainted.component';
import {NgxMaskModule} from 'ngx-mask'
import {NotificationService} from './_service/notification.service'

// Register additional locales
import { registerLocaleData, LocationStrategy, PathLocationStrategy } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeIt from '@angular/common/locales/it';
import { SharedComponentsModule, ModalComponent } from './shared/shared-components.module';
import { FileService } from './_service/file.service';
import { AdminGuard } from './admin/admin-guard';
import { TourCalendarModule } from './user/tour-calendar/tour-calendar.module';
import { OfferModule } from './user/offer/offer.module';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY} from 'ng-recaptcha';

import { environment } from 'environments/environment';

registerLocaleData(localeRu, 'ru');
registerLocaleData(localeIt, 'it');

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgbModule,
    SharedComponentsModule,
    AppRoutingModule,
    ProgressbarModule,
    TourCalendarModule,
    OfferModule,
    RecaptchaModule,
    RecaptchaV3Module,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LocationModule
  ],
  declarations: [
    AppComponent,
    SignupComponent,
    SigninComponent,
    HowItWorksComponent,
    ContactsComponent,
    HomeComponent,
    ProfileComponent,
    ResetPasswordComponent,
    RequestPasswordResetComponent,
    MenuAppComponent,
    AppFooterComponent,
    ProfileMenuComponent,
    OrganizerMenuComponent,
    ProfileButtonComponent,
    ForgotPasswordComponent,
    StatusCollectionPipe,
    NewPasswordComponent,
    DocComponent,
    FillProfileComponent,
    CollectiveMenuComponent,
    AgencyMenuComponent,
    TermsAcquaintedComponent,
    ListNotificationsComponent
  ],
  providers: [
    AdminGuard,
    DictionaryService,
    GuardService,
    AgreeService,
    ProfileGuard,
    GuardSave,
    OfferGuardService,
    ApiService,
    //WebsocketService,
    WelcomeService,
    StorageService,
    UserService,
    FileService,
    SpecializationService,
    MusicianProfileService,
    AuthGuardService,
    OrganizerProfileService,
    CommonService,
    JsonService,
    WindowRef,
    DataService,
    NotificationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_V3_SITE_KEY }
  ],
  exports: [RequestPasswordResetComponent],
  bootstrap: [AppComponent],
  entryComponents: [ModalComponent]
})
export class AppModule {
}
