import {Component, ElementRef, isDevMode, OnInit, ViewChild} from '@angular/core';
import {Router, RouterOutlet} from '@angular/router';
import {NgbModal, NgbPopoverConfig} from '@ng-bootstrap/ng-bootstrap';

import {UserService} from 'app/_service/user.service';
import {ICountry} from 'app/bom/country';
import {MusicianProfileService} from 'app/_service/musician-profile/musician-profile.service';
import {CommonService} from 'app/_service/common.service';
import {ModalComponent} from '../../shared/modal/modal.component';
import {ROLES, MAX_SIZE} from 'app/constants';
import {take} from 'rxjs/operators';

import { trigger, transition, style, animate, query, animateChild, group, stagger, sequence, state } from '@angular/animations';
import { FileService } from 'app/_service/file.service';

@Component({
  moduleId: module.id,
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  animations: [
    trigger('routeAnimations', [
      state('in', style({opacity: 1})),
      transition(':leave', animate(600, style({opacity: 0}))),
      transition(':enter', [style({opacity: 0}), animate(600)])
    ])
  ]

})
export class ProfileComponent implements OnInit {
  @ViewChild('file', { static: true }) file: ElementRef;
  @ViewChild('file', { static: true }) private fileForm: any;
  user: User;
  profile: any = {};
  showDownload: boolean;
  showEditLabel = 0;
  avatarUrl = '';
  avatarId = '';
  countries: ICountry[];
  stateSpinner = false;

  constructor(public router: Router,
              public userService: UserService,
              public fileService: FileService,
              public config: NgbPopoverConfig,
              public mp: MusicianProfileService,
              private cs: CommonService,
              private modalService: NgbModal) {
    config.placement = 'bottom';
  }

  avatarClick(event: MouseEvent) {
    if (event)
      event.stopPropagation();

    let inputEl: HTMLInputElement = this.file.nativeElement;
    inputEl.value = null;
    inputEl.click();
  }

  upload(event) {
    let fileList: FileList = event.target.files;
    let fileCount: number = fileList.length;
    if (fileCount < 1) { // a file was selected
      return;
    }
    let file = fileList[0];
    if (file.size > MAX_SIZE) {
      const modalRef = this.modalService.open(ModalComponent, { backdrop: 'static'});
      modalRef.componentInstance.type = 'maxSize';
      modalRef.componentInstance.size = `${MAX_SIZE / 1000000} MB`;
      return;
    }
    switch (file.type) {
      case 'image/jpeg':
        break;
      case 'image/gif':
        break;
      case 'image/png':
        break;
      default:
        const modalRef = this.modalService.open(ModalComponent, { backdrop: 'static'});
        modalRef.componentInstance.type = 'message';
        modalRef.componentInstance.message = 'COMMON.AVATARFORMAT_NOTSUPPORTED';
        return;
    }
    let myReader: FileReader = new FileReader();
    try {
      this.cs.togleSpiter(true);
      myReader.onload = () => {
        this.showDownload = true;
        this.fileForm.nativeElement.value = '';
        if (this.avatarId) {
          this.fileService
            .removeFile(this.avatarId).subscribe(() => {
            this.fileService
              .uploadFile(file.name, myReader.result)
              .subscribe(objectResult => {
                const newName = objectResult.name;
                this.userService.updateUser({avatar: newName});
                this.cs.togleSpiter(false);
                this.showDownload = false;
                this.avatarUrl = this.fileService.getFileUrl(newName, null, true); // response.url.replace(/^http:/, 'https');
                this.avatarId = newName;
              }, () => {
                this.cs.togleSpiter(false);
                this.showDownload = false;
              });
          }, () => {
            this.cs.togleSpiter(false);
            console.log(`file ${this.avatarId} was not removed`);
          });
        } else {
          this.fileService
            .uploadFile(file.name, myReader.result)
            .subscribe(objectResult => {
              const newName = objectResult.name;
              this.userService.updateUser({avatar: newName});
              this.showDownload = false;
              this.avatarUrl = this.fileService.getFileUrl(newName, file.name, true); // response.url.replace(/^http:/, 'https');
              this.avatarId = newName;
              this.cs.togleSpiter(false);
            }, err => {
              this.showDownload = false;
              this.cs.togleSpiter(false);
              if (isDevMode()) {
                console.log(err);
              }
            });
        }
      };
    } catch (err) {
      this.cs.togleSpiter(false);
      console.log('download avatar error');
    }
    myReader.readAsDataURL(file);
  }

  showProgressBar(): boolean {
    switch (this.userService.getRole()) {
      case (ROLES[1][0]).toLowerCase():
        return false;
      case (ROLES[3][0]).toLowerCase():
        return false;
      default:
        return true;
    }
  }

  ngOnInit(): void {
    this.userService.getUser$
      .pipe(
        take(1)
      ).subscribe((user: User) => {
      if (user === null) {
        return;
      }
      this.user = user;
      this.profile.firstName = user.firstName;
      this.profile.lastName = user.lastName;
      if (user.avatar) {
        this.avatarId = user.avatar;
        this.avatarUrl = this.fileService.getFileUrl(user.avatar, null, true);
      }
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }

}
