<section class="completion-resume">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="sos__cursor" (click)="goToOffer()"><<<
          <span>{{'ORGANIZER.OFFER.MY_OFFER' | translate | uppercase}}</span>
        </h2>
      </div>
    </div>
  </div>
</section>
<article (click)="calendarClick($event)">
  <form [formGroup]="collectiveForm">
    <div class="container main-container">
      <div class="offer-content">
        <h1>{{'ORGANIZER.OFFER.TITLE' | translate}} {{'ORGANIZER.OFFER.CREATE_OFFER_COL' | translate}}</h1>
        <img class="pop-up__modal-close" src="assets/img/icons/modal_close.png" (click)="goToOffer()">
        <!--DATE-->
        <div class="form-group">
          <label class="control-label required">{{'TOOLTIP.OFFER.DATES' | translate}}
          </label>
          <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
            <p class="font-italic">{{'TOOLTIP.OFFER.DATES_T' | translate}}</p>
          </div>
          <div class="form-user-data__calendar">
            <div class="row dates_cont" [class.readonly]="readonly || offerClosed"
          [class.invalid]="datesArray.length < 1 && firstClicked">
              <div class="profession-pop-up__genre-item" *ngFor="let date of datesArray; let i=index">
                <p>{{date}}</p>
                <div class="cancel-btn cancel-btn_wh_bg" [hidden]="readonly || offerClosed" (click)="dateDelete(i)"></div>
              </div>
              <div class="input-group-append">
                <button class="btn-outline-secondary button_calendar dates_button" (click)="calendarClick($event)"
                        type="button">
                  <i class="fas fa-calendar-alt" aria-hidden="true"
                     style="width: 1.2rem; height: 1rem; cursor: pointer;"></i>
                </button>
              </div>
            </div>
            <div class="invalid-feedback" 
                [style.display]="datesArray.length < 1 && firstClicked ? 'block' : 'none'">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
          <div class="row" style="margin: 10px">
            <ngb-datepicker
              style="position: absolute;
              background-color: white;"
              id="dates"
              ngbDatepicker
              #d="ngbDatepicker"
              [minDate]="cs.getCurrentDate()"
              [formControl]="date"
              [markDisabled]="isDisabled"
              *ngIf="dateHide">
            </ngb-datepicker>
          </div>
        </div>
        <div class="row align-items-center form-user-data__item form-group mr-0">
          <label class="control-label required col-12">{{'COMMON.DATES_WAITING' | translate}}
          </label>
          <div class="row justify-content-start m-0 pr-0 pb-0 pt-0 mb-3 repertoire__title" style="padding-left: 15px">
            <p class="font-italic">{{'TOOLTIP.OFFER.DATES_WAITING' | translate}}</p>
          </div>
          <div class="input-group col-12 pr-0" *ngIf="offerClosed">
            <input class="form-control"
                   [class.readonly]="true"
                   [disabled]="readonly"
                   [value]="fromModelStr(time.value, dateEx.value)"/>
          </div>
          <div class="col-12 row align-items-center ml-0" *ngIf="!offerClosed" (click)="$event.stopPropagation()">
            <div class="input-group col-12 col-md-3 p-0">
              <input class="form-control datepicker-input" placeholder="dd/mm/yyyy" [class.is-invalid]="dateEx.invalid && firstClicked"
                     [minDate]="cs.getCurrentDate()" [maxDate] = "cs.getDate(maxDateEx)"
                     name="dp" [formControl]="dateEx" ngbDatepicker #d1="ngbDatepicker" readonly>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" (click)="d1.toggle(); $event.stopPropagation();"
                        type="button" [disabled] = "dateExDisabled">
                  <i class="fas fa-calendar-alt" aria-hidden="true"
                     style="width: 1.2rem; height: 1rem; cursor: pointer;"></i>
                </button>
              </div>
            </div>
            <div class="col-md-4 col-12 ml-1">
              <ngb-timepicker [formControl]="time"></ngb-timepicker>
            </div>
            <div class="invalid-feedback"
                 [style.display]="dateEx.errors && dateEx.errors.required && firstClicked ? 'block' : 'none'">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
            <div class="invalid-feedback"
                 [style.display]="dateEx.errors && dateEx.errors.ngbDate && firstClicked ? 'block' : 'none'">{{"COMMON.PAST_DATE" | translate}}</div>
          </div>
        </div>
        <!--MAP-->
        <div class="form-group form-user-data__item">
          <label class="control-label required" for="venue">
            {{'ORGANIZER.OFFER.PLAYGROUND' | translate}}
          </label>
          <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title" *ngIf="!playgroundError; else playgroundErrorTmp">
            <p class="font-italic">{{'TOOLTIP.OFFER.PLAYGROUND' | translate}}</p>
          </div>
          <ng-template #playgroundErrorTmp>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title"  >
              <p class="font-italic" [style.color]="'red'">{{'COMMON.REQUIRE_PLACE_FIELD' | translate}}</p>
            </div>
          </ng-template>
          <div class="form-group input-block">
            <input class="form-control" id="venue"
                   (focus)="checkLocation(true)"
                   (blur)="checkLocation(false)"
                   (change)="changeLocation()"
                   [class.readonly]="readonly"
                   [class.is-invalid]="playground.invalid && firstClicked"
                   [formControl]="playground" [placeholder]="'PROFILE.LOCATION.CHOOSE_CITY' | translate"
                   #searchPlaces/>
            <button class="close close-venue" aria-label="Close" type="button" (click)="clearInput()">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
        </div>
        <!--<div class="offer-content__map">-->
        <!--<app-location [height]="'600px'" style="width: 100%"></app-location>-->
        <!--</div>-->
        <div class="form-group form-user-data__item">
          <label for="distance">{{'ORGANIZER.OFFER.DISTANCE' | translate}}
          </label>
          <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
            <p class="font-italic">{{'TOOLTIP.OFFER.DISTANCE_T' | translate}}</p>
          </div>
          <div class="form-group input-block">
            <input class="form-control" type="text" id="distance" formControlName="distance"/>
            <button class="close" aria-label="Close" (click)="collectiveForm.patchValue({'distance': ''})">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      
        <!--TYPE-->
        <div class="form-group form-user-data__item">
          <label class="control-label required" for="typeId">{{'COLLECTIVE.TYPE_COLLECTIVE' | translate}}</label>
          <div class="select">
            <select class="form-control rounded-0 text-uppercase" id="typeId"
                    [class.readonly]="readonly"
                    [class.is-invalid]="type.invalid && firstClicked"
                    #typeId [class.gray-color]="typeId.firstElementChild.selected"
                    formControlName="typeId">
              <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
              <option [value]="item.id" *ngFor="let item of collectiveType">{{item.name}}</option>
            </select>
            <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
        </div>
        <!--CATEGORY-->
        <div class="form-group form-user-data__item">
          <label for="distance">{{'COLLECTIVE.CATEGORY' | translate}}
          </label>
          <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
            <p class="font-italic">{{'TOOLTIP.COLLECTIVE.CATEGORY' | translate}}</p>
          </div>
          <div class="select">
            <select class="form-control rounded-0 text-uppercase" #category
                    id="category" formControlName="categoryId" [class.gray-color]="changeType">
              <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
              <option [value]="item.id" *ngFor="let item of categoryDic">{{item.name}}</option>
            </select>
          </div>
        </div>
        <!--GROUP_VOICE-->
        <div class="form-group form-user-data__item" *ngIf="type.value == 4 || type.value == 5">
          <label>{{'COLLECTIVE.COMPOSITION_VOICE' | translate}}
          </label>
          <div class="select">
            <select class="form-control rounded-0 text-uppercase" #group_voices
                    id="group_voices" formControlName="group_voices" [class.gray-color]="group_voices.firstElementChild.selected">
              <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
              <option [value]="item.id" *ngFor="let item of groupVoicesDic">{{item.name}}</option>
            </select>
          </div>
        </div>
        <!--GROUP_MUSICIAN-->
        <div class="form-group form-user-data__item" *ngIf="type.value == 3|| type.value == 5">
          <label>{{'COLLECTIVE.COMPOSITION_INSTRUMENT' | translate}}
          </label>
          <div class="select">
            <select class="form-control rounded-0 text-uppercase" #group_musical_instruments
                    id="group_musical_instruments" formControlName="group_musical_instruments" [class.gray-color]="group_musical_instruments.firstElementChild.selected">
              <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
              <option [value]="item.id" *ngFor="let item of groupMusicalInstrumentsDic">{{item.name}}</option>
            </select>
          </div>
        </div>
        <!--STYLE-->
        <div class="form-group form-user-data__item">
          <label for="distance">{{'COLLECTIVE.STYLE' | translate}}
          </label>
          <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
            <p class="font-italic">{{'TOOLTIP.COLLECTIVE.STYLE' | translate}}</p>
          </div>
          <div class="select">
            <select class="form-control rounded-0 text-uppercase"  #styles
                    id="styles" formControlName="styleId" [class.gray-color]="styles.firstElementChild.selected">
              <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
              <option [value]="item.id" *ngFor="let item of styleDic">{{item.name}}</option>
            </select>
          </div>
        </div>
        <!--SPECIALIZATION-->
        <!--<div class="form-group form-user-data__item">-->
        <!--<label ngbPopover="{{'TOOLTIP.COLLECTIVE.SPECIALIZATION' | translate}}"-->
        <!--triggers="mouseenter:mouseleave">{{'COLLECTIVE.SPECIALIZATION' | translate}}-->
        <!--<i class="fas fa-question-circle-o" aria-hidden="true"></i>-->
        <!--</label>-->
        <!--<select class="form-control rounded-0 text-uppercase"-->
        <!--id="specializations" formControlName="specializationId">-->
        <!--<option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>-->
        <!--<option [value]="item.id" *ngFor="let item of specializationsDict">{{item.name}}</option>-->
        <!--</select>-->
        <!--</div>-->
        <!--TYPE-->
        <div class="form-group form-user-data__item">
          <label for="distance">{{'COLLECTIVE.TYPE_OF_THE_ACTIVITY' | translate}}
          </label>
          <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
            <p class="font-italic">{{'TOOLTIP.COLLECTIVE.TYPE_OF_THE_ACTIVITY' | translate}}</p>
          </div>
          <div class="select">
            <select class="form-control rounded-0 text-uppercase" #typeOfActivity
                    id="typeOfActivity" formControlName="typeOfTheActivities" [class.gray-color]="typeOfActivity.firstElementChild.selected">
              <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
              <option [value]="item" *ngFor="let item of TYPE_OF_ACTIVITY">{{item | translate}}</option>
            </select>
          </div>
        </div>
        <!--NUMBER_CONCERTS_PER_YEAR-->

        <!--LANGUAGE-->
        <app-multi-select [list]="languagesArray"
                          [key]="'code'"
                          [disabledCustom]="readonly || offerClosed" 
                          [title]="'COLLECTIVE.LANGUAGE'"
                          [dictArray]="languagesDic"
                          [dictionary]="languagesNames"
                          (setData)="setList($event, languagesArray)"
                          (delete)="deleteFromList($event, languagesArray)">
        </app-multi-select>
        <!--COMPOSER-->
        <div class="form-group form-user-data__item">
          <label for="composer">{{'PROFILE.PROFESSIONAL.COMPOSER' | translate}}</label>
          <app-suggest id="composer"
                       [disabledCustom]="readonly || offerClosed" 
                       (idElement)="setId($event)"
                       [showSpinner]="showSpinnerComposer"
                       (keyEvent)="setSuggest($event)"
                       [findAutoCompleteList]="findAutoCompleteList"
                       formControlName="composer">
          </app-suggest>
        </div>
        <!--COMPOSITION-->
        <div class="form-group form-user-data__item">
          <label for="distance">{{'ORGANIZER.OFFER.SONG_TITLE' | translate}}
          </label>
          <app-suggest id="composition"
                       [disabledCustom]="readonly || offerClosed" 
                       formControlName="composition"
                       [showSpinner]="showSpinnerComposition"
                       (keyEvent)="setSuggestComposition($event)"
                       [findAutoCompleteList]="compositionList">
          </app-suggest>
        </div>
        <!--ROLE-->
        <div class="form-group form-user-data__item">
          <label for="distance">{{'ORGANIZER.OFFER.ROLE' | translate}}
          </label>
          <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
            <p class="font-italic">{{'TOOLTIP.OFFER.ROLE' | translate}}</p>
          </div>
          <div class="form-group input-block">
            <input class="form-control" type="text" id="role" formControlName="role" placeholder="">
            <button class="close" aria-label="Close" (click)="collectiveForm.patchValue({'role': ''})">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <app-files #addFiles [inputLabels]="appFileLabels" [readonly]="readonly || offerClosed" [parentPropertyName] ="'offer'" [parentPropertyValue]="id" [dataName]="dataName"></app-files>
        <app-alert #alert></app-alert>
        <div class="offer-content__btn row mt-5">
          <button
            class="btn rounded-0 form-user-data__btn form-user-data__btn_margin btn-outline-black-invert col-7 mt-4 mt-md-0 mr-md-2  col-md-3"
            (click)="remove()" type="button">
            {{'MODAL.DELETE_CONFIRM.DELETE' | translate}}
          </button>
          <button
            class="btn rounded-0 form-user-data__btn form-user-data__btn_margin btn-outline-black-invert col-7 mt-4 mt-md-0 mr-md-0 col-md-3"
            (click)="save()" type="button">
            {{'COMMON.SAVE' | translate}}
          </button>
          <button
            class="btn rounded-0 form-user-data__btn form-user-data__btn_margin btn-outline-black-invert col-7 mt-4 mt-md-0 ml-md-2  col-md-3"
            type="button"
            (click)="searchMusician($event)" [disabled]="organizerBlocked">
            {{'SEARCH.TITLE' | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</article>
