import {Component, OnInit, isDevMode, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from 'app/_service/user.service';
import {JsonService} from 'app/_service/json/json.service';
import {Unsubscribable} from 'rxjs';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.css']
})
export class HowItWorksComponent implements OnInit, OnDestroy {
  initialized: boolean = false;
  howItWorkSlider = 1;
  timeout = 300;
  sliderCount = 4;
  opacity = {'1': 1, '2': 0, '3': 0, '4': 0};
  position = {'1': 0, '2': 0, '3': 0, '4': 0};
  role: any;
  imgFon: string = '';
  currentLang = this.ts.currentLang;
  sub: string = '';
  cards: any = {text: []};
  cards1: any = {text: []};
  cards2: any = {text: []};
  cards3: any = {text: []};
  cards4: any = {text: []};
  mainText: [any];
  mainTitle = '';
  textBlock: [any];
  
  private subscriptions: Unsubscribable[] = [];

  constructor(public us: UserService,
              private jService: JsonService,
              private ts: TranslateService) {
  }

  ngOnInit() {
    let sub = this.us.getUser$.subscribe(user => {
      this.role = this.us.getRole();
      if (['collective', 'agency'].indexOf(this.role) > -1) {
        this.role = 'musician';
      }
      this.updateData(this.currentLang);
    }, () => {
    });
    this.subscriptions.push(sub);

    sub = this.ts.onLangChange.subscribe(langEvent => {
      this.currentLang = langEvent.lang;
      this.updateData(this.currentLang);
    }, () => {
    });
    this.subscriptions.push(sub);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.subscriptions = [];
  }

  next() {
    const count = this.howItWorkSlider === this.sliderCount ? 1 : this.howItWorkSlider + 1;
    this.changeOpacity(count, true);
  }
  prev() {
    const count =  this.howItWorkSlider === 1 ? this.sliderCount : this.howItWorkSlider - 1;
    this.changeOpacity(count);
  }
  goTo(id) {
    this.changeOpacity(id);
  }
  changeOpacity(id, right?: boolean) {
    const currentId = this.howItWorkSlider;
    const numPosition = right ? -100 : 100;
    this.opacity[currentId] = 0;
    this.position[currentId] = numPosition;
    setTimeout(() => {
      this.howItWorkSlider = id;
      this.position[currentId] = 0;
      this.position[this.howItWorkSlider] = -numPosition;
      setTimeout(() => {
        this.position[this.howItWorkSlider] = 0;
        this.opacity[this.howItWorkSlider] = 1;
      }, 100 );
    }, this.timeout );
  }
  
  updateData(currentLang) {
    this.jService.getJSON(`i18n/how-it-work/${currentLang}.json`)
      .subscribe(
        data => {
          const cardsTmp = data[this.role || 'guest'];
          this.imgFon = `assets/images/${cardsTmp.backgroundImgPath}.jpg`;
          this.mainTitle = cardsTmp.mainTitle;
          this.mainText = cardsTmp.mainText;
          this.sub = cardsTmp.sub;
          this.cards = cardsTmp;
          this.cards1 = cardsTmp.card1;
          this.cards2 = cardsTmp.card2;
          this.cards3 = cardsTmp.card3;
          this.cards4 = cardsTmp.card4;
          if (Array.isArray(cardsTmp.textBlock)) {
            this.textBlock = cardsTmp.textBlock.map(item => {
              return {title: item.titel, text: item.text, ingLink: `assets/icon/png/${item.imgName}.png`}
            });
          }
        },
        err => {
          console.log(err);
        });
  }

  loaded() {
    this.initialized = true;
  }
}
