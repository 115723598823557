import {Component, isDevMode, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {AlertComponent} from 'app/user/shared/alert/alert.component';
import {CommonService} from 'app/_service/common.service';
import {NotificationService} from 'app/_service/notification.service';
import { Observable, Unsubscribable } from 'rxjs';

@Component({
  selector: 'app-list-notifications',
  templateUrl: './list-notifications.component.html',
  styleUrls: ['./list-notifications.component.scss']
})
export class ListNotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('alert') private alert: AlertComponent;
  collectiveType: any[];
  notifications: any[];
  role: string;
  professionsNames: {} = {};
  specializationsNames: {} = {};
  stylesNames: {};
  currentLang = this.ts.currentLang;
  public initialized: boolean = false;
    
  newCount: number = 0;

  private subscription: Unsubscribable = null;

  constructor(
    private router: Router,
    private ts: TranslateService,
    private cs: CommonService,
    private ns: NotificationService
  ) {
  }

  ngOnInit() {
    this.cs.togleSpiter(true);
    
    /*
    this.fetchItems().subscribe(
      () => {
        this.cs.togleSpiter(false);
        this.ns.updateNewCount();
        this.initialized = true;
      },
      () => {
        this.cs.togleSpiter(false);
      }
    );
    */

   this.subscription = this.ns.newCount$.subscribe(
      cnt => { 
        this.fetchItems().subscribe(
          () => {
            this.cs.togleSpiter(false);
            this.initialized = true;            
          }
        ); 
      } 
    );

   // При входе в список уведомлений всегда файрим событие 
   this.ns.updateNewCount(true);

  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  prepareDate(date): string {
    return  moment(date).format('ddd, DD.MM.YYYY H:mm');
  };

  goTo(event, item): void {
    if (event)
      event.stopPropagation();

    if (!item || !item.path || !item.id) {
      return;
    }
    this.ns.readItem(item).subscribe(
      () => {
        return this.router.navigate([item.path]);      
      },
      err => {
        console.error(err);
        return this.router.navigate([item.path]);      
      }
    )
  }

  deleteItem(event, item): void {
    this.cs.togleSpiter(true);
    if (event)
      event.stopPropagation();

      this.ns.deleteItem(item).subscribe(
        () => {
          //TODO: надо бы сделать более эффективно, что бы не слать запрос постоянно
          this.cs.togleSpiter(false);
          this.fetchItems().subscribe();
        },
        err => {
          this.cs.togleSpiter(false);
          console.error(err);
          this.alert.showMessage(err.message, 'danger');
        },
      )
  }

  haveItems(): boolean {
    return this.notifications && this.notifications.length > 0;
  }

  private fetchItems(): Observable<void> {
    return Observable.create(observer => {
      this.ns.getItems().subscribe(
        items => {
          this.notifications = items;   
          observer.next();
          observer.complete();
        },
        err => {
          this.alert.showMessage(err.message, 'danger');
          observer.next();
        }
      );      
    })
  }
}
