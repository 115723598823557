import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

import {AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {LocationComponent} from './location.component';
import {LocationService} from './location.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export {LocationService} from './location.service';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB9yXQf1PQbnHiUd0VUVEpIwbtcgGe5whA',
      libraries: ['places'],
      language: 'en',
      region: 'CH'
    }),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [LocationComponent],
  exports: [LocationComponent],
  providers: [LocationService, GoogleMapsAPIWrapper]
})
export class LocationModule {
}
