import {Component, OnInit, Input} from '@angular/core';
import { environment } from 'app/../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent implements OnInit {
  @Input() version: string;
  public _apiUrl = environment.apiUrl;
  constructor() {}

  ngOnInit() {
  }

}
