import { Injectable, isDevMode } from '@angular/core';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class CommonService {
  private spinnerObser: Subject<boolean> = new Subject();
  private stateSpinner = false;
  public spinner$ = this.spinnerObser.asObservable();

  constructor(
    private ts: TranslateService
  ) {
  }

  togleSpiter(state?: boolean) {
    if (state !== undefined) {
      this.stateSpinner = state;
    } else {
      this.stateSpinner = !this.stateSpinner;
    }
    this.spinnerObser.next(state);
  }

  guid() : string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  getCurrentDate() : { year: number, month: number, day: number } {
    const current = moment();
    return { year: current.year(), month: current.month() + 1, day: current.date() }
  }

  getDate(date: string) : { year: number, month: number, day: number } | null {
    const dateParts = date && date.split("/");
    return dateParts ? { year: Number(dateParts[2]), month: Number(dateParts[1]), day: Number(dateParts[0]) } : null;
  }

  // Преобразует строку дат, разделенных запятыми в массив элементов типа Date
  csDatesToLocal(strDates: string) : string {
    if (!strDates)
      return '';

    try {
      return strDates.trim().split(',').map(s => this.parseDate(s, 'DD/MM/YYYY')).join(', ');
    } catch (error) {
      console.error('Invalid date format')
      return '';
    }
  }

  datesStringToArray(strDates: string) {
    if (!strDates)
      return [];

    try {
      return strDates.trim().split(',').map(s => this.parseDate(s, 'DD/MM/YYYY'));
    } catch (error) {
      console.error('Invalid date format')
      return [];
    }
  }

  dateToNum(date, delimiter: string = '/') {
    const dateNum = date.split(delimiter); 
    return Number(dateNum[2]+dateNum[1]+dateNum[0]);
  }

  sortDatesArray(datesArray) {
    return datesArray.sort((a, b) => this.dateToNum(a.trim()) - this.dateToNum(b.trim()));
  }

  pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  getDateFromString(dateString: string, format: string = 'DD/MM/YYYY'){
    return moment(dateString,  format);
  }

  convertDatePickerToString(datePickerValue) {
    return `${this.pad(datePickerValue.day)}/${this.pad(datePickerValue.month)}/${datePickerValue.year}`
  }

  //TODO: Move to InvitationService
  alertType(state) : string {
    switch (state) {
      case 1:
        return 'sent';
      case 2:
        return 'accepted';
      case 3:
        return 'approved';
      case 4:
        return 'signing';
      case 5:
        return 'signed';
      case 6:
        return 'decline';
      case 7:
        return 'expired';
      case 8:
        return 'expired';
      default:
        return 'secondary';
    }
  }

  //TODO: Move to InvitationService time overdue
  //checkTime(expirationTime, state = 1) {
  isTimeOverdue(expirationTime, state = 1) : boolean {
    if ([5, 6, 7, 8].indexOf(state) > -1) {
      return false;
    }
    if (!expirationTime) {
      return false;
    }
    return moment().isAfter(expirationTime);
  }

  //TODO: Move to InvitationService
  isInvitationExpired(invitation): boolean {

    if (!invitation)
      return false;

    let state = invitation.state ? invitation.state : 1;

    return (state === 1 && invitation.expiration && moment().isAfter(invitation.expiration)) ||
      ([2, 3].indexOf(state) > -1 && invitation.available && moment().isAfter(invitation.available)) ||
      (state === 4 && invitation.orgAvailable && moment().isAfter(invitation.orgAvailable));

  }

  replaceNullUndefined(value) {
    return (value === null || value === undefined) ? '' : value;
  }
  isIEorOpera(): boolean {
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf('rv:11.0') > 0 || userAgent.indexOf('MSIE ') > 0 || userAgent.indexOf('Opera') > 0;
  }

  isIE(): boolean {
    const userAgent = window.navigator.userAgent;
    return userAgent.indexOf('rv:11.0') > 0 || userAgent.indexOf('MSIE ') > 0;
  }

  isNumber(value) {
    return !isNaN(this.toInteger(value));
  }

  parseDate(dateTime: string, format : string = 'DD/MM/YYYY') {
    if (dateTime === null || !dateTime) {
      return '';
    }
    return moment(dateTime).format(format);
  }


  toInteger(value) {
    return parseInt('' + value, 10);
  }
  sortDate(val1: any, val2: any) {
    return new Date(val2.updateNote).getTime() - new Date(val1.updateNote).getTime()
  }
}

export function isObject(x: any): boolean {
  return Object.prototype.toString.call(x) === '[object Object]';
}

export function isString(x: any): boolean {
  return Object.prototype.toString.call(x) === '[object String]';
}


