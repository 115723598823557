import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {UserService} from 'app/_service/user.service';
import {take} from 'rxjs/operators';

@Injectable()
export class OfferGuardService implements CanActivate {
  constructor(public userService: UserService,
              private router: Router) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve) => {
      this.userService.getUser$.pipe(take(1)).subscribe((user: User) => {
        const role = this.userService.getRole();
          if ('organizer' !== role) {
            this.router.navigateByUrl('/');
            resolve(false);
          }
        resolve(true);
      });
    });
  }
}
