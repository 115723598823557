<div *ngIf="+(invitation?.state) > 0">
  <!-- Stoped Alerts: Begin -->
  <ngb-alert [type]="'expired'"
    *ngIf="invitation?.state == 1 && cs.isTimeOverdue(invitation?.expiration, invitation?.state)" [dismissible]="false">
    {{'INVITATIONS.STATE.EXPIRED' | translate}}:&nbsp;{{  cs.parseDate(invitation?.expiration)}}
  </ngb-alert>
  <ngb-alert [type]="'expired'"
    *ngIf="[2, 3].indexOf(invitation?.state) > -1 && cs.isTimeOverdue(invitation?.available, invitation?.state)"
    [dismissible]="false">
    {{'INVITATIONS.STATE.OVERDUE_MUS' | translate}}:&nbsp;{{  cs.parseDate(invitation?.available)}}
  </ngb-alert>
  <ngb-alert [type]="'expired'"
    *ngIf="invitation?.state === 4 && cs.isTimeOverdue(invitation?.orgAvailable, invitation?.state)" [dismissible]="false">
    {{'INVITATIONS.STATE.OVERDUE' | translate}}:&nbsp;{{  cs.parseDate(invitation?.orgAvailable)}}
  </ngb-alert>
  <!-- Stoped Alerts: End -->

  <!-- Information Alerts: Begin -->
  <ngb-alert *ngIf="!cs.isInvitationExpired(invitation)" [type]="cs.alertType(invitation?.state)" [dismissible]="false">
    <div class="row" [ngSwitch]="invitation?.state">
      <div class="col" *ngSwitchCase="1">
        <p *ngIf="userRole == 'Organizer'">{{'INVITATIONS.SENT'| translate}}</p>
        <p *ngIf="userRole == 'Musician' || userRole == 'Collective'">
          <b [hidden]="!invitation?.orgName">{{invitation?.orgName}}</b>&nbsp;
          <span>{{'INVITATIONS.STATE.1.1.1' | translate}}</span>&nbsp;
          <b>{{invitation?.venue}}</b>&nbsp;
          <span>{{'INVITATIONS.STATE.1.1.4' | translate}}</span>&nbsp;
          <b>{{invitation?.dates}}</b>&nbsp;
          <span>{{'INVITATIONS.STATE.1.1.2' | translate}}</span>&nbsp;
          <b>{{cs.parseDate(invitation?.expiration)}}</b>&nbsp;
          <span>{{'INVITATIONS.STATE.1.1.3' | translate}}</span>&nbsp;
        </p>
      </div>
      <span class="col" *ngSwitchDefault>{{'INVITATIONS.STATE.'+invitation?.state | translate}}</span>
      <div class="col-2">
        <app-bell [new_message]="invitation?.isNew" [show_if_false]="false"></app-bell>
      </div>
    </div>
    <div class="row" *ngIf="invitation?.reason_for_reject > 0">
      <span class="col">{{'INVITATIONS.DECLINE.REASON'| translate}}:
        {{'INVITATIONS.DECLINE.'+invitation?.reason_for_reject | translate}}</span>
    </div>
    <div class="row" *ngIf="invitation?.available && [2, 3].indexOf(invitation?.state) > -1">
      <div class="col">
        <span>{{'INVITATIONS.WAITING_TIME_MUS'| translate}}:&nbsp;</span>
        <span class="date_metronome">&nbsp;{{  cs.parseDate(invitation?.available)}}</span>
      </div>
    </div>
    <div class="row" *ngIf="invitation?.orgAvailable && [4].indexOf(invitation?.state) > -1">
      <div class="col">
        <span>{{'INVITATIONS.WAITING_TIME'| translate}}: </span>
        <span class="date_metronome">&nbsp;{{ cs.parseDate(invitation?.orgAvailable)}}</span>
      </div>
    </div>
  </ngb-alert>
  <!-- Information Alerts: End -->
</div>