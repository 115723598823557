import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from 'app/_service/backend/api.service';
import {AlertComponent} from 'app/user/shared/shared.module';
import {UserService} from 'app/_service/user.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-terms-acquainted',
  templateUrl: './terms-acquainted.component.html',
  styleUrls: ['./terms-acquainted.component.css']
})
export class TermsAcquaintedComponent implements OnInit {
  @ViewChild('alert', { static: true }) private alert: AlertComponent;
  public _apiUrl = environment.apiUrl;
  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {}
  save () {
    this.api.patch('/api/action/agreed_acquainted', {agreed: true})
      .subscribe(() => {
        let path = this.userService.getProfilePath();
        if (path.trim().match(/^profile\/$/)) {
          this.router.navigateByUrl('fill-profile');
          return;
        }
        this.router.navigateByUrl(path);
      }, () => {
        this.alert.showMessage('SIGN_IN.ERROR', 'danger');
      })
  }

}
