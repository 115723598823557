export const ACHIEVEMENTS = 'achievements';
export const CERTIFICATES = 'certificates';
export const USERS = 'users';
export const FILES = 'files';
export const NUMBER_OF_TIMES = [{value: 1, key: '1-5'}, {value: 6, key: '6-10'}, {value: 11, key: '11-50'}, {value: 51, key: '51-100'}];
export const TITLE_DICT = ['TITLE_DICT.SENIOR', 'TITLE_DICT.PRIME', 'TITLE_DICT.GRADUATE', 'TITLE_DICT.STUDENT'];
export const AGE_INTERVAL = ['< 25', '26/35', '36/45', '46/55', '>55'];
export const CURRENCY_TYPE = ['CHF', 'EUR'];
export const COMPANY_SIZE = ['0-1', '2-10', '11-50', '51-200', '201-500', '501-1000', '1001-5000'];
export const IDUSTRY = [
  'ORGANIZER.INDUSTRY.ENTERTAINMENT',
  'ORGANIZER.INDUSTRY.EVENTS_SERVICES',
  'ORGANIZER.INDUSTRY.FINE_ART',
  'ORGANIZER.INDUSTRY.GOVERNMENT_ADMINISTRATION',
  'ORGANIZER.INDUSTRY.NONPROFIT_ORGANIZATION',
  'ORGANIZER.INDUSTRY.PERFORMING_ARTS'];
export const COMPANY_TYPE = [
  'ORGANIZER.COMPANY_TYPE.PUBLIC_COMPANY',
  'ORGANIZER.COMPANY_TYPE.EDUCATIONAL_INSTITUTION',
  'ORGANIZER.COMPANY_TYPE.SELF_EMPLOYED',
  'ORGANIZER.COMPANY_TYPE.GOVERNMENT_AGENCY',
  'ORGANIZER.COMPANY_TYPE.NONPROFIT',
  'ORGANIZER.COMPANY_TYPE.INDIVIDUAL',
  'ORGANIZER.COMPANY_TYPE.ASSOCIATION'];
export const COLLECTIVE_TYPE = [
  'COLLECTIVE.CHOIR',
  'COLLECTIVE.ORCHESTRA'];
export const FREQ_CONCERT = ['1-5', '6-10', '11-30', '31-50', '51-100', '101-150', '> 200'];
export const  ROLES = [
  ['Musician', 'SIGN_UP.MUSICIAN'],
  ['Organizer', 'SIGN_UP.ORGANIZER'],
  ['Collective', 'SIGN_UP.COLLECTIVE'],
  ['Agency', 'SIGN_UP.AGENCY']
];
export const TYPE_OF_ACTIVITY = ['CONST.PERMANENT', 'CONST.PROJECT'];
export const MAX_SIZE = 3000000;
export const PHONE_CODES = [
  0,
  1,
  [0,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    7,
    8,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]],
  [0,
    1,
    2,
    3,
    4,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    6,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    9
  ],
  [0,
    1,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    3,
    4,
    5,
    6,
    7,
    8,
    9],
  [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  ],
  [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  ],
  7,
  [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    1,
    2,
    3,
    4,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    6,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    9
  ],
  [
    0,
    1,
    2,
    3,
    4,
    5,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    8,
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]]
];
export const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([а-яА-ЯЁёa-zA-Z0-9\-0-9]+\.)+[а-яА-ЯЁёa-zA-Z0-9]{2,}))$/;
