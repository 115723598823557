import { Component, OnInit, isDevMode } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

type AlertType = 'success' | 'info' | 'warning' | 'danger';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  public type: AlertType;
  public message: string;

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  public showMessage(message: string, type: AlertType, doNotTranslate?: boolean): void {
    if (doNotTranslate) {
      this.setMessage(message, type);
      return;
    }

    this.translate.get(message).subscribe(
      (trans: string) => this.setMessage(trans, type),
        err => {
          if (isDevMode()) {
            console.log(err);
          }
      }
    )
  }

  public resetMessage(): void {
    this.message = null;
  }

  private setMessage(message: string, type: AlertType): void {
    this.message = message;
    this.type = type;
    if (type === 'success') {
      setTimeout(() => {
        this.message = null;
      }, 3000);
    }
  }
}
