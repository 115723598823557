import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { TourCalendarComponent } from './tour-calendar.component';
import { ToursSearchService } from './tours-search.service';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { SharedComponentsModule } from 'app/shared/shared-components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { routing } from './tour-calendar.routing';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    routing,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    NgbModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers:[ToursSearchService],
  declarations: [TourCalendarComponent]
})
export class TourCalendarModule { }
