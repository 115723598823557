<section class="completion-resume">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 (click)="goToOffer()"><<<
          <span class="text_click" [hidden]="this.invite">{{'ORGANIZER.OFFER.MY_OFFER' | translate | uppercase}}</span>
            <span class="text_click" [hidden]="!this.invite">{{'INVITATIONS.CANDIDATES' | translate | uppercase}}</span>
        </h2>
      </div>
    </div>
  </div>
</section>
<article (click)="articleClick($event);">
  <form [formGroup]="formOffer">
    <div class="container main-container">
      <div class="offer-content">
        <h1>{{'ORGANIZER.OFFER.TITLE' | translate}} {{'ORGANIZER.OFFER.CREATE_OFFER_MUS' | translate}}</h1>
        <p class="text-center" [hidden]="!offerClosed"><i><b>"{{'ORGANIZER.OFFER.CLOSE_OFFER_STATE' | translate}}!"</b></i>
        </p>
        <img class="pop-up__modal-close" src="assets/img/icons/modal_close.png" (click)="goToOffer()">
        <fieldset>
          <!-- ВЫНЕСТИ НАХ В ОТДЕЛЬНЫЙ КОНТРОЛ!!! -->
          <div class="form-group">
            <label class="control-label required">{{'TOOLTIP.OFFER.DATES' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.OFFER.DATES_T' | translate}}</p>
            </div>
            <div class="form-user-data__calendar">
              <div class="row dates_cont" 
                [class.readonly]="readonly || offerClosed"
                [class.invalid]="datesArray.length < 1 && firstClicked">
                <div class="profession-pop-up__genre-item" *ngFor="let date of datesArray; let i=index">
                  <p>{{date}}</p>
                  <div class="cancel-btn cancel-btn_wh_bg" [hidden]="readonly || offerClosed" (click)="dateDelete(i)"></div>
                </div>
                <div class="input-group-append">
                  <button class="btn-outline-secondary button_calendar dates_button" (click)="calendarClick($event)"
                    type="button">
                    <i class="fas fa-calendar-alt" aria-hidden="true"
                      style="width: 1.2rem; height: 1rem; cursor: pointer;"></i>
                  </button>
                </div>
              </div>
              <div class="invalid-feedback" 
                [style.display]="datesArray.length < 1 && firstClicked ? 'block' : 'none'">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
            </div>
            <div class="row" style="margin: 10px">
              <ngb-datepicker
                style="position: absolute;
                background-color: white;"
                id="dates"
                ngbDatepicker
                #d="ngbDatepicker"
                [minDate]="cs.getCurrentDate()"
                [formControl]="date"
                [markDisabled]="isDisabled"
                *ngIf="dateHide">
              </ngb-datepicker>
            </div>
          </div>


          <div class="row align-items-center form-user-data__item form-group mr-0">
            <label class="col-12 control-label required" style="margin-bottom: 17px">{{'COMMON.DATES_WAITING' | translate}}
            </label>
            <div class="row justify-content-start m-0 pr-0 pb-0 pt-0 mb-3 repertoire__title" style="padding-left: 15px">
              <p class="font-italic">{{'TOOLTIP.OFFER.DATES_WAITING' | translate}}</p>
            </div>
            <div class="input-group col-12 pr-0" *ngIf="offerClosed">
              <input class="form-control" 
                [class.readonly]="true" 
                [disabled]="readonly"
                [value]="fromModelStr(time.value, dateEx.value)"/>
            </div>
            <div class="col-12 row align-items-center ml-0" *ngIf="!offerClosed" (click)="$event.stopPropagation()">
              <div class="input-group col-12 col-md-3 p-0">
                <input class="form-control datepicker-input" placeholder="dd/mm/yyyy" [class.is-invalid]="dateEx.invalid && firstClicked"
                onCopy="return false" onDrop="return false" onPaste="return false" autocomplete="off"
                  [minDate]="cs.getCurrentDate()" [maxDate] = "cs.getDate(maxDateEx)"
                  name="dp" [formControl]="dateEx" ngbDatepicker #d1="ngbDatepicker" readonly> 
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="d1.toggle(); $event.stopPropagation();"
                    type="button"  [disabled] = "dateExDisabled">
                    <i class="fas fa-calendar-alt" aria-hidden="true"
                      style="width: 1.2rem; height: 1rem; cursor: pointer;"></i>
                  </button>
                </div>
              </div>
              <div class="col-md-4 col-12 ml-1">
                <ngb-timepicker [formControl]="time"></ngb-timepicker>
              </div>
              <div class="invalid-feedback"
                [style.display]="dateEx.errors && dateEx.errors.required && firstClicked ? 'block' : 'none'">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
              <div class="invalid-feedback"
                [style.display]="dateEx.errors && dateEx.errors.ngbDate && firstClicked ? 'block' : 'none'">{{"COMMON.PAST_DATE" | translate}}</div>
            </div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="venue" class="control-label required">
              {{'ORGANIZER.OFFER.PLAYGROUND' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title" *ngIf="!playgroundError; else playgroundErrorTmp">
              <p class="font-italic">{{'TOOLTIP.OFFER.PLAYGROUND' | translate}}</p>
            </div>
            <ng-template #playgroundErrorTmp>
              <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title"  >
                <p class="font-italic" [style.color]="'red'">{{'COMMON.REQUIRE_PLACE_FIELD' | translate}}</p>
              </div>
            </ng-template>
            <div class="form-group input-block">
              <input class="form-control" id="venue"
              (focus)="checkLocation(true)"
              (blur)="checkLocation(false)"
                (change)="changeLocation()"
                [class.readonly]="readonly"
                [class.is-invalid]="playground.invalid && firstClicked"
                [formControl]="playground" [placeholder]="'PROFILE.LOCATION.CHOOSE_CITY' | translate" 
                #searchPlaces/>
              <button class="close close-venue" aria-label="Close" type="button" (click)="clearInput()">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
            </div>
          </div>
          <!--<div class="offer-content__map">-->
          <!--<app-location [height]="'600px'" style="width: 100%"></app-location>-->
          <!--</div>-->
          <div class="form-group form-user-data__item">
            <label for="distance">{{'ORGANIZER.OFFER.DISTANCE' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.OFFER.DISTANCE_T' | translate}}</p>
            </div>
            <div class="form-group input-block">
              <input class="form-control" type="text" id="distance" formControlName="distance"/>
              <button class="close" aria-label="Close" (click)="formOffer.patchValue({'distance': ''})">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="profId" class="control-label required"> {{'PROFILE.PROFESSIONAL.PROFESSION' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.OFFER.PROFESSION_T' | translate}}</p>
            </div>
            <div class="select">
              <select class="form-control rounded-0 text-uppercase" id="profId" 
                      [class.readonly]="readonly"
                      [class.is-invalid]="profession.invalid && firstClicked" 
                      #profId [class.gray-color]="profId.firstElementChild.selected" 
                      formControlName="profId">
                <option [ngValue]="null" class="gray-color">
                  {{'PROFILE.PROFESSIONAL.ADD-PROFESSION' | translate}}
                </option>
                <option *ngFor="let item of professions" value="{{item.id}}">
                  {{item.name}}
                </option>
              </select>
              <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
            </div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="specialization" class="control-label required">{{'PROFILE.PROFESSIONAL.SPECIALIZATION' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.OFFER.SPECIALIZATION' | translate}}</p>
            </div>
            <div class="select">
              <select class="form-control rounded-0 text-uppercase" 
                      [class.readonly]="readonly"
                      [class.is-invalid]="specialization.invalid && firstClicked" 
                      #specializationId [class.gray-color]="specializationId.firstElementChild.selected" 
                      id="specialization" formControlName="specializationId">
                <option [ngValue]="null" class="gray-color">
                  {{'PROFILE.PROFESSIONAL.ADD-SPECIALIZATION' | translate}}
                </option>
                <option *ngFor="let item of specializations" value="{{item.id}}">{{item.name}}</option>
              </select>
              <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
            </div>
          </div>
          <div class="form-group form-user-data__item">
              <label class="control-label required">{{'ORGANIZER.OFFER.PAYMENT_OFFER' | translate}}
              </label>
              <div class="form-group form-user-data__item row m-0 p-0">
                  <input class="form-control col-8 col-md-10"
                  [class.readonly]="readonly"
                  [maxlength]="10"
                  mask="separator.0"
                  (keypress)="setDirty()"
                  [class.is-invalid]="payment.invalid && firstClicked"
                  type="text" name="payment"
                  formControlName="payment">
                <select class="form-control col-4 col-md-2 text-uppercase" id="currencyType"
                  formControlName="currencyType">
                  <option [value]="currency" *ngFor="let currency of currencyTypeList">{{currency}}</option>
                </select>
                <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
              </div>
            </div>
          <div class="form-group form-user-data__item">
            <label for="styleId">{{'PROFILE.PROFESSIONAL.STYLE' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.OFFER.STYLE_T' | translate}}</p>
            </div>
            <div class="select">
              <select class="form-control rounded-0 text-uppercase" 
                      [class.readonly]="readonly"
                      #styleId [class.gray-color]="styleId.firstElementChild.selected" 
                      id="styleId" formControlName="styleId">
                <option [ngValue]="null" class="gray-color">
                  {{'PROFILE.SKILLS.ADD-TITLE' | translate}}
                </option>
                <option *ngFor="let item of styleMusic" value="{{item.id}}">{{item.name}}</option>
              </select>
              <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
            </div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="genre">{{'PROFILE.PROFESSIONAL.GENRE' | translate}}
            </label>
            <div class="select">
              <select class="form-control text-uppercase" id="genre" 
              [class.readonly]="readonly"
                formControlName="genreId" #genreId [class.gray-color]="genreId.firstElementChild.selected">
                <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
                <option *ngFor="let item of genresDic" value="{{item.id}}">{{item.name}}</option>
              </select>
              <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
            </div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="composer">{{'PROFILE.PROFESSIONAL.COMPOSER' | translate}}</label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.OFFER.COMPOSER' | translate}}</p>
            </div>
            <app-suggest id="composer" 
                        [disabledCustom]="readonly || offerClosed" 
                        (idElement)="setId($event)"
                        [showSpinner]="showSpinnerComposer" 
                        (keyEvent)="setSuggest($event)"
                        [findAutoCompleteList]="findAutoCompleteList" 
                        formControlName="composer">
            </app-suggest>
          </div>
          <div class="form-group form-user-data__item">
            <label for="composition">{{'ORGANIZER.OFFER.SONG_TITLE' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.OFFER.SONG_TITLE' | translate}}</p>
            </div>
            <app-suggest id="composition"
                        [disabledCustom]="readonly || offerClosed" 
                        formControlName="composition"
                        [showSpinner]="showSpinnerComposition" 
                        (keyEvent)="setSuggestComposition($event)"
                        [findAutoCompleteList]="compositionList">
            </app-suggest>
          </div>
          <div class="form-group form-user-data__item">
            <label for="numberOfTimes">{{'ORGANIZER.OFFER.MANY_TIME' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.OFFER.MANY_TIME' | translate}}</p>
            </div>
            <div class="select">
              <select class="form-control text-uppercase" id="numberOfTimes" 
                      [class.readonly]="readonly" 
                      #numberOfTimes [class.gray-color]="numberOfTimes.firstElementChild.selected" 
                      formControlName="numberOfTimes">
                <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
                <option *ngFor="let item of listNumberOfTimes" value="{{item}}">
                  {{'COMMON.MORE' | translate | lowercase }} {{item}}</option>
              </select>
            </div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="role">{{'ORGANIZER.OFFER.ROLE' | translate}}
            </label>
            <div class="form-group input-block">
                <input class="form-control"
                [class.readonly]="readonly"
                type="text" id="role" formControlName="role" placeholder="">
              <button class="close" aria-label="Close" (click)="formOffer.patchValue({'role': ''})">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="ageInterval">{{'ORGANIZER.OFFER.AGE_INTERVAL' | translate}}
            </label>
            <div class="select">
                <select class="form-control text-uppercase"
                [class.readonly]="readonly"
                id="ageInterval" formControlName="ageInterval"
                #ageInterval [class.gray-color]="ageInterval.firstElementChild.selected">
                <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
                <option *ngFor="let item of ageIntervel; index as i" value="{{i+1}}">{{item}}</option>
              </select>
              <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
            </div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="title">{{'ORGANIZER.OFFER.EXPERIENCE' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.OFFER.EXPERIENCE_T' | translate}}</p>
            </div>
            <div class="select">
                <select class="form-control text-uppercase" id="title" name="title" formControlName="title"
                [class.readonly]="readonly"
                #title [class.gray-color]="title.firstElementChild.selected">
                <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
                <option *ngFor="let item of titleDict" [value]="item">{{item | translate}}</option>
              </select>
            </div>
          </div>



          <!-- ВЫНЕСТИ НАХ В ОТДЕЛЬНЫЙ КОНТРОЛ!!! -->
          <div class="form-group">
            <label class="control-label">{{'ORGANIZER.OFFER.PAYMENTDATES' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'ORGANIZER.OFFER.PAYMENTDATES_DESC' | translate}}</p>
            </div>
            <div class="form-user-data__calendar">
                <div class="row pay_dates_cont"
                [class.readonly]="readonly || offerClosed">
                <div class="profession-pop-up__genre-item" *ngFor="let pDate of paymentDatesArray; let i=index">
                  <p>{{pDate}}</p>
                  <div class="cancel-btn cancel-btn_wh_bg" (click)="paymentDateDelete(i)"></div>
                </div>
                <div class="input-group-append">
                  <button class="btn-outline-secondary button_calendar dates_button" (click)="pPikerClick($event)"
                    type="button">
                    <i class="fas fa-calendar-alt" aria-hidden="true"
                      style="width: 1.2rem; height: 1rem; cursor: pointer;"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row" style="margin: 10px">
                <ngb-datepicker
                style="position: absolute;
                background-color: white;"
                id="paymentDates"
                ngbDatepicker
                #d2="ngbDatepicker"
                [minDate]="cs.getCurrentDate()"
                [formControl]="paymentDate"
                [markDisabled]="isPDisabled"
                *ngIf="pPickerShow">
              </ngb-datepicker>
            </div>
          </div>


          <div class="row add-file__title col-12">
            <label>
              {{'ORGANIZER.OFFER.EXPENSES' | translate | uppercase}}
            </label>
          </div>
          <div class="row mb-2">
            <div class=" col-xl-3 col-md-6 col-12 ">
              <div class="pop-up__radio">
                <label class="form-check-label">
                  <input class="form-check-input" [class.readonly]="readonly" type="checkbox" name="expFood"
                    formControlName="expFood">
                  {{'ORGANIZER.OFFER.EATING' | translate}}
                </label>
              </div>
            </div>
            <div class=" col-xl-3 col-md-6 col-12">
              <div class="pop-up__radio">
                <label class="form-check-label">
                  <input class="form-check-input" [class.readonly]="readonly" type="checkbox" name="expAccomm"
                    formControlName="expAccomm">
                  {{'ORGANIZER.OFFER.STAY' | translate}}
                </label>
              </div>
            </div>
            <div class=" col-xl-3 col-md-6 col-12">
              <div class="pop-up__radio">
                <label class="form-check-label">
                  <input class="form-check-input" [class.readonly]="readonly" type="checkbox" name="expTravel"
                    formControlName="expTravel">
                  {{'ORGANIZER.OFFER.TRAVEL' | translate}}
                </label>
              </div>
            </div>
            <div class=" col-xl-3 col-md-6 col-12">
              <div class="pop-up__radio">
                <label class="form-check-label">
                  <input class="form-check-input" [class.readonly]="readonly" type="checkbox" name="expTaxes"
                    formControlName="expTaxes">
                  {{'ORGANIZER.OFFER.TAXES' | translate}}
                </label>
              </div>
            </div>
      </div>
      </fieldset>
      <div class="form-group form-user-data__item">
        <label>{{'ORGANIZER.OFFER.OTHER_EXPENSES' | translate}}
        </label>
        <textarea class="form-control" cols="3" id="expOther" formControlName="expOther"></textarea>
      </div>
      <app-multi-select [list]="languagesArrayCharacter" [key]="'code'" [tooltip]="'TOOLTIP.OFFER.LANGUAGES'"
        [disabledCustom]="readonly || offerClosed" [title]="'ORGANIZER.OFFER.LANGUAGES_CHARACTER'"
        [dictArray]="languagesDic" [dictionary]="languagesNames" (setData)="setList($event, languagesArrayCharacter)"
        (delete)="deleteFromList($event, languagesArrayCharacter)">
      </app-multi-select>
      <app-multi-select [list]="languagesArrayArtist" [key]="'code'" [tooltip]="'TOOLTIP.OFFER.LANGUAGES'"
        [disabledCustom]="readonly || offerClosed" [title]="'ORGANIZER.OFFER.LANGUAGES_ARTIST'"
        [dictArray]="languagesDic" [dictionary]="languagesNames" (setData)="setList($event, languagesArrayArtist)"
        (delete)="deleteFromList($event, languagesArrayArtist)">
      </app-multi-select>

      <app-files #addFiles [readonly]="readonly || offerClosed" [inputLabels]="appFileLabels" [parentPropertyName] ="'offer'" [parentPropertyValue]="id" [dataName]="dataName"></app-files>
      <div class="form-group form-user-data__item">
          <label for="other">{{'ORGANIZER.OFFER.OTHER' | translate}}
          </label>
          <!--
          <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
            <p class="font-italic">{{'TOOLTIP.OFFER.OTHER_T' | translate}}</p>
          </div>
          -->
          <textarea class="form-control" cols="3" id="other" formControlName="other">
          </textarea>
        </div>
      <app-alert #alert></app-alert>
      <div class="offer-content__btn row mt-5">
          <button
          class="btn rounded-0 form-user-data__btn form-user-data__btn_margin btn-outline-black-invert col-7 mt-4 mt-md-0 mr-md-2  col-md-3"
          (click)="remove()" [hidden]="readonly" type="button">
          {{'MODAL.DELETE_CONFIRM.DELETE' | translate}}
        </button>
        <button
          class="btn rounded-0 form-user-data__btn form-user-data__btn_margin btn-outline-black-invert col-7 mt-4 mt-md-0 mr-md-0 col-md-3"
          (click)="save()" (keypress.enter)="save()" [hidden]="offerClosed" [disabled]="!isDirty()" type="button">
          {{'COMMON.SAVE' | translate}}
        </button>
        <button
          class="btn rounded-0 form-user-data__btn form-user-data__btn_margin btn-outline-black-invert col-7 mt-4 mt-md-0 ml-md-2  col-md-3"
          type="button" [hidden]="offerClosed" (keypress.enter)="searchMusician($event)"
          (click)="searchMusician($event)" [disabled]="organizerBlocked">
          {{'SEARCH.TITLE' | translate}}
        </button>
      </div>
    </div>
    </div>
  </form>
</article>