<div class="aside__list-header d-sm-none">
  <p>{{'PROFILE.TITLE'| translate | uppercase}}</p>
  <button class="btn-points d-sm-none" (click)="listShowActive = !listShowActive">
    <i class="fas " [ngClass]="{'fa-bars' : !listShowActive, 'fa-times': listShowActive}" aria-hidden="true"></i>
  </button>
</div>
<ul class="aside__list d-sm-block" [@smoothCollapse]="!listShowActive?'initial':'final'" appShowMenu>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/personal-data"
       (click)="listShowActive = false">
      {{'PROFILE.PERSONAL.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/location"
       (click)="listShowActive = false">
      {{'PROFILE.LOCATION.WHERE_ARE_YOU' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/tours"
       (click)="listShowActive = false">
      {{'PROFILE.TOURS.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/professional"
       (click)="listShowActive = false">
      {{'PROFILE.PROFESSIONAL.YOUR_PROF' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/reviews"
       (click)="listShowActive =false">
      {{'PROFILE.REVIEWS.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/skills"
       (click)="listShowActive = false">
      {{'PROFILE.SKILLS.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/achievements"
       (click)="listShowActive = false">
      {{'PROFILE.ACHIEVEMENTS.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/account"
       (click)="listShowActive = false">
      {{'PROFILE.ACCOUNT.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/remove"
       (click)="listShowActive = false">
      {{'PROFILE.ACCOUNT.DELETE_PROFILE' | translate | uppercase}}
    </a>
  </li>
</ul>

<ul class="aside__list d-none d-sm-block">
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/personal-data">
      {{'PROFILE.PERSONAL.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/location">
      {{'PROFILE.LOCATION.WHERE_ARE_YOU' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/tours">
      {{'PROFILE.TOURS.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/professional">
      {{'PROFILE.PROFESSIONAL.YOUR_PROF' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/reviews">
      {{'PROFILE.REVIEWS.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/skills">
      {{'PROFILE.SKILLS.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/achievements">
      {{'PROFILE.ACHIEVEMENTS.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/account">
      {{'PROFILE.ACCOUNT.TITLE' | translate | uppercase}}
    </a>
  </li>
  <li class="aside__item">
    <a class="aside__link" routerLinkActive="active" routerLink="musician/remove">
      {{'PROFILE.ACCOUNT.DELETE_PROFILE' | translate | uppercase}}
    </a>
  </li>
</ul>


