import { Component, ElementRef, isDevMode, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Observable, ReplaySubject, concat } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/_service/user.service';
import { MusicianProfileService } from 'app/_service/musician-profile/musician-profile.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LocationService } from '../location/location.service';
import { DictionaryService } from 'app/_service/dictionary.service';
import { CommonService } from 'app/_service/common.service';
import { DataService } from 'app/_service/backend/data.service';
import { ROLES } from 'app/constants';
import { AlertComponent } from '../shared/alert/alert.component';
import { CanComponentDeactivate } from 'app/app-routing/guard-save';
import { map, take } from 'rxjs/operators';
import { EMAIL_PATTERN } from 'app/constants';
import { nameValidator } from 'app/_service/validators';

@Component({
  selector: 'app-fill-profile',
  templateUrl: './fill-profile.component.html',
  styleUrls: ['./fill-profile.component.css']
})
export class FillProfileComponent implements OnInit, CanComponentDeactivate {
  @ViewChildren('search') public searchElementRef: QueryList<ElementRef>;
  @ViewChild('alert', { static: true }) private alert: AlertComponent;

  mask = '0*';
  playgroundError = false;
  ROLES = ROLES;
  shouldCheck = false;
  show = false;
  roleDis = false;
  musProfile = false;
  collProfile = false;
  orgProfile = false;
  isButtonPushed: boolean = false;
  success = false;
  latitude: number;
  longitude: number;
  welcomeData;
  searchSubscription;
  place = this.fb.control(null, [Validators.required]);
  profession = this.fb.control(null, [Validators.required]);
  specialization = this.fb.control(null, [Validators.required]);
  roleName = this.fb.control(null, [Validators.required]);
  currentLang = this.ts.currentLang;
  phone = this.fb.control(null, [Validators.required]);
  type = this.fb.control(null, Validators.required);
  name = this.fb.control(null, Validators.required);
  email = this.fb.control(null, [Validators.required, Validators.pattern(EMAIL_PATTERN)]);
  categories = this.fb.control(null);
  address = this.fb.control(null, Validators.required);
  administrator = this.fb.control(null, [nameValidator]);
  formProfile = this.fb.group({
    id: null,
    roleName: this.roleName,
    place: this.place,
    profId: this.profession,
    specializationId: this.specialization,
    phone: this.phone,
    type: this.type,
    name: this.name,
    email: this.email,
    categories: this.categories,
    address: this.address,
    administrator: this.administrator,
  });
  professions: [any];
  specializations: [any];
  categoriesArray = [];
  collectiveType: any[];
  categoryNames: {};
  categoryDic: any[];

  constructor(
    public userService: UserService,
    public musicianProfileService: MusicianProfileService,
    public fb: FormBuilder,
    public locationService: LocationService,
    public ds: DictionaryService,
    public cs: CommonService,
    private dtS: DataService,
    private router: Router,
    private ts: TranslateService) {
  }

  canDeactivate(): Observable<boolean> {
    const subject = new ReplaySubject<boolean>(1);
    if (this.success) {
      subject.next(true);
    } else {
      this.userService.logout();
      subject.next(true);
    }
    return subject.pipe(take(1));
  }

  ngOnInit() {
    this.initDictionaries().subscribe(
      () => {
      },
      err => {
        if (isDevMode()) {
          console.log(err);
        }
      }, () => {
        this.dtS.get_welcome_data().subscribe(item => {
          this.welcomeData = item;
          this.switchUserRole(item.roleName);
          this.formProfile.patchValue(item);
          if (Array.isArray(item.location) && item.location.length === 2) {
            this.latitude = item.location[0];
            this.longitude = item.location[1];
            this.locationService.getCity({ lat: this.latitude, lng: this.longitude });
          }
          if (this.roleName.value) {
            this.roleName.disable();
            if (this.collProfile) {
              if (typeof (item.categories) === 'string') {
                this.categoriesArray = item.categories.length > 0 ? item.categories.split(',') : [];
              }
              if (item.type) {
                this.changeCategories(item.type);
              }
            }
          } else {
            this.roleName.valueChanges.subscribe(
              value => {
                this.switchUserRole(value);
              });
          }
        }, err => {
          if (isDevMode()) {
            console.log(err);
          }
        });
        this.ds
          .getFilteredDictionary('dic_specializations',
            i => Number(i.profId) === Number(this.profession.value)).subscribe(
              dic => {
                this.specializations = dic;
              });
      });

    this.type.valueChanges.subscribe(
      value => {
        this.categoriesArray.length = 0;
        this.changeCategories(value);
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      });

    this.ts.onLangChange.subscribe(langEvent => {
      this.currentLang = langEvent.lang;
      this.initDictionaries().subscribe(
        () => {
        },
        err => {
          if (isDevMode()) {
            console.log(err);
          }
        }, () => {
          this.ds
            .getFilteredDictionary('dic_specializations',
              i => Number(i.profId) === Number(this.profession.value)).subscribe(
                dic => {
                  this.specializations = dic;
                });
        });
    });
  }

  switchUserRole(userRole) {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    this.musProfile = false;
    this.collProfile = false;
    this.orgProfile = false;
    switch (userRole) {
      case 'Musician': {
        this.musProfile = true;
        this.initMusFields();
        break;
      }
      case 'Collective': {
        this.collProfile = true;
        this.initCollFields();
        break;
      }
      case 'Organizer': {
        this.orgProfile = true;
        break;
      }
    }
  }

  initMusFields() {
    this.searchSubscription = this.searchElementRef.changes.subscribe(val => {
      this.locationService.getPlaces(this.searchElementRef.toArray()[0].nativeElement, true);
    });
    this.locationService.coordinates$.subscribe((value: any) => {
      this.latitude = value.lat;
      this.longitude = value.lng;
      // this. save();
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
    this.locationService.place$.subscribe((value: any) => {
      this.place.patchValue(value);
      this.searchElementRef.toArray()[0].nativeElement.click();

    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
    this.profession.valueChanges.subscribe(
      value => {
        this.specialization.reset();
        this.ds
          .getFilteredDictionary('dic_specializations', i => Number(i.profId) === Number(value)).subscribe(
            dic => {
              this.specializations = dic;
            });
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      });
  }

  initCollFields() {
    if (typeof (this.welcomeData.categories) === 'string') {
      this.categoriesArray = this.welcomeData.categories.length > 0 ? this.welcomeData.categories.split(',') : [];
    }
    if (this.welcomeData.type) {
      this.changeCategories(this.welcomeData.type);
    }
  }


  changeLocation() {
    this.latitude = null;
    this.longitude = null;
  }

  // Show additional tooltip
  checkLocation(flag) {
    this.playgroundError = flag;
  }

  deleteFromList(index, list): void {
    if (Array.isArray(list)) {
      list.splice(index, 1);
      this.formProfile.markAsDirty();
    }
  }

  setList(value, list): void {
    if (Array.isArray(list)) {
      list.push(value);
      this.formProfile.markAsDirty();
    }
  }

  public hideMusProperties(): boolean {
    return !this.roleName.value || this.roleName.value !== 'Musician';
  }

  private initDictionaries(): Observable<any> {
    return concat(
      this.ds.getDictionary('dic_professions')
        .pipe(
          map(
            dic => {
              this.professions = dic
            }
          )
        ),
      this.ds.getDictionary('dic_collective_types')
        .pipe(
          map(dic => {
            this.collectiveType = dic;
          })
        ),
      this.ds.getDictionaryNames('dic_collective_categories', 'id')
        .pipe(
          map(names => {
            this.categoryNames = names;
          })
        ),
    );
  }

  changeCategories(value) {
    if (this.collectiveType) {
      const collective = this.collectiveType.find(collType => {
        return +collType.id === +value;
      });
      if (!collective) {
        return;
      }
      const type = +collective.type;
      this.ds
        .getFilteredDictionary(
          'dic_collective_categories',
          i => {

            return Number(i.typeId) === type;
          })
        .subscribe(
          dic => {
            this.categoryDic = dic;
          });
    }
  }

  clearInput() {
    this.place.patchValue(null);
  }

  save(): void {
    this.shouldCheck = true;
    this.isButtonPushed = true;
    let location = null;
    if (!this.roleName.invalid && !this.musProfile && !this.collProfile && !this.orgProfile) {
      this.dtS.set_welcome_data({ ...this.formProfile.value, userRole: this.roleName.value }).subscribe(
        user => {
          this.success = true;
          this.userService.patchUser(user);
          this.alert.showMessage('COMMON.SAVED', 'success');
          this.isButtonPushed = false;
          this.formProfile.reset(this.formProfile.value);
          this.router.navigateByUrl(this.userService.getProfilePath()).then(bool => {
            if (!bool) {
              this.router.navigateByUrl('/');
            }
          }).catch(() => {
            this.router.navigateByUrl('/');
          });
        },
        () => {
          this.alert.showMessage('COMMON.SAVE-ERROR', 'danger');
          this.isButtonPushed = false;
        }
      );
      return;
    }
    if (this.musProfile && (!this.latitude || !this.longitude || this.phone.invalid || this.profession.invalid || this.specialization.invalid)) {
      this.place.reset();
      this.playgroundError = true;
      this.alert.showMessage('COMMON.REQUIRE_FIELDS', 'danger');
      this.isButtonPushed = false;
      return;
    }
    if (this.orgProfile && (this.name.invalid || this.email.invalid || this.address.invalid || this.administrator.invalid || this.phone.invalid)) {
      this.alert.showMessage('COMMON.REQUIRE_FIELDS', 'danger');
      this.isButtonPushed = false;
      return;
    }
    // for type == 'Opera Company' (6) category not required
    if (this.collProfile && (this.type.invalid || this.name.invalid || this.email.invalid || (this.type.value != '6' && !this.categoriesArray.length) || this.phone.invalid)) {
      this.alert.showMessage('COMMON.REQUIRE_FIELDS', 'danger');
      this.isButtonPushed = false;
      return;
    }
    if (this.musProfile) {
      location = [this.latitude, this.longitude];
    }
    if (this.collProfile) {
      this.formProfile.patchValue({ categories: this.categoriesArray.join(',') });
    }
    this.dtS
      .set_welcome_data({
        ...this.formProfile.value,
        location: location,
        userRole: this.roleName.value
      })
      .subscribe(
        user => {
          this.userService.patchUser(user);
          this.success = true;
          this.alert.showMessage('COMMON.SAVED', 'success');
          this.isButtonPushed = false;
          this.formProfile.reset(this.formProfile.value);
          this.router.navigateByUrl(this.userService.getProfilePath()).then(bool => {
            if (!bool) {
              this.router.navigateByUrl('/');
            }
          }).catch(() => {
            this.router.navigateByUrl('/');
          });
        },
        () => {
          this.isButtonPushed = false;
          this.alert.showMessage('COMMON.SAVE-ERROR', 'danger');
        }
      );
  }
}
