<section class="completion-resume">
  <div class="container">
  </div>
</section>
<article>
  <form>
    <div class="container">
      <div class="offer-content">
        <!--<h1>{{'SIGN_UP.TITLE' | translate}}</h1>-->
        <p>{{'ACCEPT_CONTINUE.PHRASE_1' | translate}}
          <b>{{'ACCEPT_CONTINUE.BUTTON' | translate}}</b>
          {{'ACCEPT_CONTINUE.PHRASE_2' | translate}}
          <a [href] = "_apiUrl+'/documents/terms'" target="_blank" >{{'ACCEPT_CONTINUE.LINK_1' | translate}}</a>.
          {{ 'ACCEPT_CONTINUE.PHRASE_3' | translate}}
          <a [href]="_apiUrl+'/documents/policy'" target="_blank" >{{'ACCEPT_CONTINUE.LINK_2' | translate}}</a>
          {{'ACCEPT_CONTINUE.AND' | translate}}
          <a [href]="_apiUrl+'/documents/cookie'" target="_blank" >{{'ACCEPT_CONTINUE.LINK_3' | translate}}</a></p>
        <app-alert #alert></app-alert>
        <div class="row justify-content-center">
          <button class="btn rounded-0 form-user-data__btn btn-outline-black-invert"
                  (keyup.enter)="save()"  (click)="save()" >
            {{'ACCEPT_CONTINUE.BUTTON' | translate}}
          </button>
        </div>
      </div>
    </div>
  </form>
</article>
