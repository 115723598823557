import {Component, OnInit, ViewChild, isDevMode} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from 'app/_service/user.service';
import {MusicianProfileService} from 'app/_service/musician-profile/musician-profile.service';
import {AlertComponent} from 'app/user/shared/shared.module';
import {CommonService} from 'app/_service/common.service';
import { EMAIL_PATTERN } from 'app/constants';

@Component({
  moduleId: module.id,
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  shouldCheck = false;
  isButtonPushed = false;
  username: FormControl = this.fb.control('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]);
  password: FormControl = this.fb.control('', [Validators.required]);
  signInForm: FormGroup = this.fb.group({
    username: this.username,
    password: this.password,
  });
  newUser: any = false;
  reset: any = false;
  resetSendEmail = '';
  errorResetLink = '';
  passportResetSuccess: boolean = false;
  nextStep = '';
  confirmSuccess: any = false;

  isSubmitted: boolean = false;

  @ViewChild('alert', { static: true }) private alert: AlertComponent;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              public cs: CommonService,
              public musicianProfileService: MusicianProfileService,
              public userService: UserService) {
  }

  ngOnInit(): void {
    this.route
      .queryParamMap
      .subscribe(
        (value: any) => {
          if (value.params['user']) {
            this.newUser = value.params['user'];
          }
          if (value.params['passportResetSuccess']) {
            this.alert.showMessage('PROFILE.ACCOUNT.SAVED', 'success', false);
            this.passportResetSuccess = value.params['passportResetSuccess']
          }
          if (value.params['reset']) {
            this.reset = value;
          }
          if (value.params['resetSendEmail']) {
            this.resetSendEmail = value.params['resetSendEmail'];
          }
          if (value.params['confirmSuccess']) {
            this.confirmSuccess = value;
          }
          if (value.params['errorResetLink']) {
            this.errorResetLink = value;
          }
          if (value.params['url']) {
            this.nextStep = value.params['url'];
          }
        }, err => {
          if (isDevMode()) {
            console.log(err);
          }}
      );
  }
  social(providerName) {
    // if user click social button, clear form and alert
    this.signInForm.reset();
    this.shouldCheck = false;
    this.alert.resetMessage();
    this.userService.socialLogin(providerName).subscribe(() => {
      if (this.nextStep) {
        return this.router.navigateByUrl(this.nextStep);
      }
      let path = this.userService.getProfilePath();
      if (path.trim().match(/^profile\/$/)) {
        this.router.navigateByUrl('fill-profile');
        return;
      }
      this.router.navigateByUrl(path);
    }, err => {
      if (err.status === 0) {
        this.alert.showMessage(err.message, 'danger', true);
      }
    });
  }
  buttonHide() {
    let hideClass = '';
    if (this.newUser || this.confirmSuccess || this.resetSendEmail || this.passportResetSuccess || this.cs.isIEorOpera()) {
      hideClass = 'element_hidden';
    }
    return hideClass;
  }
  buttonHideForm() {
    let hideClass = '';
    if (this.newUser || this.resetSendEmail) {
      hideClass = 'element_hidden';
    }
    return hideClass;
  }
  signIn(value): void {
    this.alert.resetMessage();

    if (this.signInForm.invalid) {
      this.shouldCheck = true;
      return;
    }
    this.isSubmitted = true;
    this.isButtonPushed = true;
    this.userService.signIn(value.username, value.password).subscribe(
      user => {
        if (typeof user.token !== 'string') {
          throw({message: 'missing token'});
        }
        sessionStorage.setItem('access_token', user.token);
        localStorage.setItem('access_token', user.token);
        if (this.nextStep) {
          return this.router.navigateByUrl(this.nextStep);
        }
        let path = this.userService.getProfilePath();
        if (path.trim().match(/^profile\/$/)) {
          this.router.navigateByUrl('fill-profile');
          return;
        }
        this.router.navigateByUrl(path);
      },
      err => {
        this.isButtonPushed = false;
        this.signInForm.reset(this.signInForm.value);
        
        if (err.error.code == 1 || err.error.code == 2) {
          this.alert.showMessage('SIGN_IN.NOT_REGISTERED', 'danger');  
          return;
        }
        if (err.error.code == 3) {
          this.alert.showMessage('SIGN_IN.INCORRECT_PASSWORD', 'danger');  
          return;
        }
        this.alert.showMessage(err.error.message, 'danger', true);
        //this.alert.showMessage('SIGN_IN.ERROR', 'danger');
      }, () => {
        this.isButtonPushed = false;
        this.isSubmitted = false;
      }
    );
  }
}
