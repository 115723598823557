import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserService } from 'app/_service/user.service';
import { ApiService } from 'app/_service/backend/api.service';

@Injectable()
export class AdminGuard implements CanActivate {
    constructor(
        private api: ApiService,
        private router: Router
    ) { }

    canActivate(): Promise<boolean> {
        return new Promise((resolve) => {
            this.api.get('/admin/dashboard/authorize', { responseType: 'text' }).subscribe(
                res => {
                    if (res !== "Ok") {
                        this.router.navigateByUrl('/');
                        resolve(false);
                    }
                    resolve(true);
                },
                err => {
                    this.router.navigateByUrl('/');
                    resolve(false);
                });
        });
    }
}
