import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs'
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const access_token = sessionStorage.getItem('access_token') || localStorage.getItem('access_token');
    const authHeader =  access_token ? access_token : '';
    const authReq = req.clone({headers: req.headers.set('Authorization', `Bearer ${authHeader}`)});
    return next.handle(authReq);
  }
}
