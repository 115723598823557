import {Component, OnInit, Input} from '@angular/core';
import {LocationService} from 'app/user/location/location.module';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {
@Input('isMap') isMap: boolean = true;
  constructor(private ls: LocationService) {
  }

  ngOnInit() {
    this.ls.getCity({lat:  46.0086306, lng: 8.951388899999984});
  }

}
