<div class="form-group form-user-data__item">
  <div style="position: relative">
    <div class="row add-file__title col-12">
      <label [ngClass] = "{'control-label required' : required}">{{title | translate | uppercase}}</label>
    </div>
    <div *ngIf="tooltip" class="row justify-content-start m-0 p-0 repertoire__title">
        <p class="font-italic" [ngClass] = "{'p-invalid' : invalid}">{{tooltip | translate}}</p>
      </div>
    <div class="row add-file__edit row-select" [ngClass] = "{'invalid' : invalid}">
      <div class="col-10 col-md-11 add-file__line">
        <hr>
      </div>
      <label for="select" #addButton class="col-2 col-md-1 add-file__add add-btn" [hidden]="disabledCustom"></label>
      <select id="select" class="input-lg form-control multi-select" #select [hidden]="disabledCustom"
        (change)="setList($event)" (mouseenter)="addActive()" (mouseleave)="removeActive()">
        <option [disabled]="true" class="gray-color"></option>
        <option [value]="item[keyName]" [disabled]="isListAdded(item[keyName])" *ngFor="let item of dictArray">
          {{getName (item)}}</option>
      </select>
    </div>
  </div>
  <div>
   
    <div class="profession-pop-up__genre-item" *ngFor="let code of list; let i = index">
      <p class="text-uppercase">{{getName(code)}}</p>
      <div class="cancel-btn cancel-btn_wh_bg" [hidden]="disabledCustom" (click)="deleteFromList(i)"></div>
    </div>
  </div>
</div>