import { Injectable } from '@angular/core';
import { ApiService } from 'app/_service/backend/api.service';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private newCount = new ReplaySubject(1);
  public newCount$ = this.newCount.asObservable();

  private isActive: boolean = false;
  private curNewCount: number = 0;
  private timerId: number = 0;
  private readonly timeout: number = 900000; // 15 min

  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
    this.newCount.next(null);
  }

  start(): void {
    if (this.isActive)
      return;

    this.isActive = true;

    this.updateNewCount();
    if (!this.timerId)
      this.timerId = setInterval(() => { this.updateNewCount() }, this.timeout);
  }

  stop(): void {
    this.isActive = false;
    if (this.timerId > 0)
      clearInterval(this.timerId);
    this.timerId = 0;
    this.curNewCount = 0;
    this.newCount.next(null);
  }

  getItems(): Observable<any[]> {
    return Observable.create(observer => {
      if (!this.isActive) {
        observer.next(null);
        observer.complete();
        return;
      }
      this.api.get(`/api/notification/last-notifications`).subscribe(
        items => {
          if (!Array.isArray(items)) {
            items = [];
          }
          observer.next(items);
          observer.complete();
        },
        err => {
          observer.error(err);
        }
      );
    });
  }

  deleteItem(item: any): Observable<void> {
    return Observable.create(observer => {
      if (!this.isActive) {
        observer.next();
        observer.complete();
        return;
      }
      this.api.post("api/notification/delete", { id: item.id }).subscribe(
        res => {
          if (item.isNew > 0)
            this.updateNewCount();

          observer.next();
          observer.complete();
        },
        err => {
          observer.error(err);
        }
      );
    });
  }

  readItem(item: any): Observable<void> {
    return Observable.create(observer => {
      if (!this.isActive || item.isNew < 1) {
        observer.next();
        observer.complete();
        return;
      }
      else {
        this.api.post("api/notification/read", { id: item.id }).subscribe(
          res => {
            item.isNew = 0;
            this.updateNewCount();
            observer.next();
            observer.complete();
          },
          err => {
            observer.error(err);
          }
        );
      }
    });
  }

  public updateNewCount(force: boolean = false): void {
    if (!this.isActive)
      return;

    this.api.get("api/notification/new-cnt").subscribe(
      (result: { count: number }) => {
        if (!force && this.curNewCount == result.count)
          return;

        this.curNewCount = result.count
        this.newCount.next(result.count);
      },
      err => {
        console.error(err.message);
        this.newCount.error(err);
      }
    );
  }

}
