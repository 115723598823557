<section class="completion-resume">
  <div class="container">
  </div>
</section>
<article class="mt-5">
  <form [formGroup]="formProfile">
    <div class="container form-user-data__item">
      <div class="offer-content">
        <h1>{{'COMMON.FIRST_FORM.TITLE' | translate}}</h1>
        <div class="form-group form-user-data__item">
          <label for="specialization" class="control-label required">{{'PROFILE.PROFESSIONAL.ROLE' | translate}}</label>
          <div class="select">
            <select class="form-control rounded-0" [class.is-invalid]="roleName.invalid && shouldCheck" #roleControl
              [class.gray-color]="roleControl.firstElementChild.selected" formControlName="roleName">
              <option [ngValue]="null" class="gray-color">
                {{'SIGN_UP.CHANGE_ROLE' | translate}}
              </option>
              <option [value]="role[0]" *ngFor="let role of ROLES">{{role[1] | translate}}</option>
            </select>
            <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
        </div>
        <section *ngIf = 'musProfile'>
          <div class="row location__enter mb-1">
            <div class="col-12 mt-3 mb-1">
              <label for="place" class="control-label required">{{'PROFILE.LOCATION.CHOOSE_CITY' | translate | uppercase}}</label>
              <p class="font-italic repertoire__title mb-1" *ngIf="!playgroundError; else playgroundErrorTmp">
                {{'TOOLTIP.LOCATION' | translate}}</p>
              <ng-template #playgroundErrorTmp>
                <p class="font-italic  repertoire__title mb-1" [style.color]="'red'">
                  {{'COMMON.REQUIRE_PLACE_FIELD' | translate}}</p>
              </ng-template>
              <div class="form-group input-block">
                <input class="form-control" id="place" [class.is-invalid]="place.invalid && shouldCheck"
                  [formControl]="place" (focus)="checkLocation(true)" (blur)="checkLocation(false)"
                  (change)="changeLocation()" [placeholder]="'PROFILE.LOCATION.CHOOSE_CITY' | translate" #search />
                <button class="close close-venue" aria-label="Close" type="button" (click)="clearInput()">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
              </div>
            </div>
          </div>
          <p class="location__title">{{'PROFILE.LOCATION.AUTO_CHOOSE' | translate}}:</p>
          <div class="location__determine-my-loc" (click)="locationService.searchPlaces()"><img
              src="assets/img/icons/location.png">
            <p>{{'PROFILE.LOCATION.DETERMINE' | translate }}...</p>
          </div>
          <div class="row location__map  mb-5">
            <div class="col-12">
              <app-location></app-location>
              <p>
                <ngb-progressbar type="warning" [value]="100" [striped]="true" [animated]="true" *ngIf="show">
                  {{'PROFILE.LOCATION.LOADING' | translate}}
                </ngb-progressbar>
              </p>
            </div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="profId" class="control-label required"> {{'PROFILE.PROFESSIONAL.PROFESSION' | translate}}</label>
            <div class="select">
              <select class="form-control rounded-0 text-lowercase" id="profId"
                [class.is-invalid]="profession.invalid && shouldCheck" #profId
                [class.gray-color]="profId.firstElementChild.selected" formControlName="profId">
                <option [ngValue]="null" class="gray-color">
                  {{'PROFILE.PROFESSIONAL.ADD-PROFESSION' | translate}}
                </option>
                <option *ngFor="let item of professions" value="{{item.id}}">
                  {{item.name | lowercase}}
                </option>
              </select>
              <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
            </div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="specialization" class="control-label required">{{'PROFILE.PROFESSIONAL.SPECIALIZATION' | translate}}</label>
            <div class="select">
              <select class="form-control rounded-0 text-lowercase"
                [class.is-invalid]="specialization.invalid && shouldCheck" #specializationId
                [class.gray-color]="specializationId.firstElementChild.selected" id="specialization"
                formControlName="specializationId">
                <option [ngValue]="null" class="gray-color">
                  {{'PROFILE.PROFESSIONAL.ADD-SPECIALIZATION' | translate}}
                </option>
                <option *ngFor="let item of specializations" value="{{item.id}}">{{item.name | lowercase}}</option>
              </select>
              <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
            </div>
          </div>
          <div class="form-group form-user-data__item ">
            <label for="phone" class="control-label required"> {{'COMMON.PHONE' | translate}}
            </label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.PHONE' | translate}}</p>
            </div>
            <input class="form-control" [mask]="mask" prefix="+" [formControl]="phone"
              [class.is-invalid]="phone.invalid && shouldCheck" id="phone" formControlName="phone" required />
            <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
        </section>

        <section *ngIf = 'orgProfile'>
          <div class="form-group form-user-data__item">
            <label for="name" class="control-label required">{{'ORGANIZER.NAME' | translate}}</label>
            <input class="form-control" [class.is-invalid]="name.invalid && shouldCheck" id="name" name="name"
              formControlName="name" required />
            <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="email" class="control-label required">Email</label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.ORGANIZER.EMAIL' | translate}}</p>
            </div>
            <input class="form-control" [class.is-invalid]="email.invalid && shouldCheck" type="email" id="email" name="email"
              formControlName="email" required />
            <div class="invalid-feedback d-block" *ngIf="email.errors && email.errors['pattern']">
                {{"COMMON.INVALID_EMAIL" | translate}}</div>
            <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="address" class="control-label required">{{'ORGANIZER.ADDRESS' | translate}}</label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.ORGANIZER.ADDRESS' | translate}}</p>
            </div>
            <textarea class="form-control" [class.is-invalid]="address.invalid && shouldCheck" id="address" placeholder=""
              formControlName="address" required></textarea>
            <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="administrator" class="control-label required">{{'ORGANIZER.ADMINISTRATOR' | translate}}</label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.ORGANIZER.ADMINISTRATOR' | translate}}</p>
            </div>
            <input class="form-control" [class.is-invalid]="(administrator.invalid && shouldCheck) || administrator.errors?.nameError" id="administrator"
              name="administrator" formControlName="administrator" required />
            <div class="invalid-feedback" *ngIf="administrator.errors?.nameError">{{"COMMON.LATIN_FIELD" | translate}}</div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="phone" class="control-label required">{{'COMMON.PHONE' | translate}}</label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.ORGANIZER.PHONE' | translate}}</p>
            </div>
            <input [mask]="mask" prefix="+" [class.is-invalid]="phone.invalid && shouldCheck"
              class="form-control" id="phone" name="phone" formControlName="phone" required />
            <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
        </section>


        <section *ngIf = 'collProfile'>
          <div class="form-group form-user-data__item">
              <label for="gender" class="control-label required">{{'COLLECTIVE.TYPE_COLLECTIVE' | translate}}</label>
              <div class="select">
                <select class="form-control input-lg" [class.is-invalid]="type.invalid && shouldCheckField" id="gender"
                  formControlName="type">
                  <option [ngValue]="null" class="gray-color">{{'PROFILE.SKILLS.ADD-TITLE' | translate}}</option>
                  <option [value]="item.id" *ngFor="let item of collectiveType">{{item.name}}</option>
                </select>
              </div>
              <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="name" class="control-label required">{{'COLLECTIVE.NAME' | translate}}</label>
            <input class="form-control" [class.is-invalid]="name.invalid && shouldCheckField" type="text" id="name"
              name="name" formControlName="name" />
            <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="email" class="control-label required">{{'COMMON.EMAIL' | translate}}</label>
            <input class="form-control" [class.is-invalid]="email.invalid && shouldCheckField" type="email" id="email"
              name="email" formControlName="email" required/>
            <div class="invalid-feedback d-block" *ngIf="email.errors && email.errors['pattern']">
              {{"COMMON.INVALID_EMAIL" | translate}}</div>
          <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
          <div class="form-group form-user-data__item">
            <label for="phone" class="control-label required">{{'COMMON.PHONE' | translate}}</label>
            <div class="row justify-content-start m-0 p-0 mb-3 repertoire__title">
              <p class="font-italic">{{'TOOLTIP.PHONE' | translate}}</p>
            </div>
            <input [mask]="mask" prefix="+" [class.is-invalid]="phone.invalid && shouldCheck"
              class="form-control" id="phone" name="phone" formControlName="phone" required />
            <div class="invalid-feedback">{{"COMMON.REQUIRE_FIELD" | translate}}</div>
          </div>
          <div class="form-group form-user-data__item " *ngIf="type.value != '6'">
            <app-multi-select [list]="categoriesArray" [title]="'COLLECTIVE.CATEGORY'" [dictArray]="categoryDic"
              [dictionary]="categoryNames" [tooltip]="'TOOLTIP.COLLECTIVE.CATEGORY'" required="true"
              [invalid]="!categoriesArray.length && shouldCheckField" (setData)="setList($event, categoriesArray)"
              (delete)="deleteFromList($event, categoriesArray)"></app-multi-select>
          </div>
        </section>


        <app-alert #alert></app-alert>
        <div class="row justify-content-center">
          <app-button class="btn_component" (buttonComponentEvent)="save()" [btnTitle]="'COMMON.SAVE' | translate"
            [isButtonPushed]="isButtonPushed"></app-button>
        </div>
      </div>
    </div>
  </form>
</article>