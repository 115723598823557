import { Component, ElementRef, isDevMode, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { concat, Observable, Subject } from 'rxjs';


import { NgbDateStruct, NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'app/_service/user.service';
import { MusicianProfileService } from 'app/_service/musician-profile/musician-profile.service';
import { LocationService } from '../../location/location.module';
import { AlertComponent } from 'app/user/shared/shared.module';
import { CommonService } from 'app/_service/common.service';
import { DictionaryService } from 'app/_service/dictionary.service';
import { ModalComponent } from 'app/shared/shared-components.module';

import { AGE_INTERVAL, CURRENCY_TYPE, ROLES, TITLE_DICT, MAX_SIZE } from 'app/constants';
import { ApiService } from 'app/_service/backend/api.service';
import { map, take } from 'rxjs/operators';
import * as moment from 'moment';
import { FilesComponent } from 'app/user/shared/files/files.component';

export function isFuture(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const date = control.value;
    if (!moment([date.year, date.month - 1, date.day]).isValid()) {
      return null;
    }
    const forbidden = moment([date.year, date.month - 1, date.day]).isAfter(moment());
    return forbidden ? { 'forbiddenName': { value: control.value } } : null;
  };

}

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})

export class OfferComponent implements OnInit {
  @ViewChild('alert', { static: true }) private alert: AlertComponent;
  playgroundError = false;
  firstClicked = false;
  readonly: boolean;
  offerClosed = false;
  organizerBlocked = false;
  showSpinnerComposer = false;
  showSpinnerComposition = false;
  listNumberOfTimes = [1, 5, 10, 50];
  currencyTypeList = CURRENCY_TYPE;

  //===========>
  maxSize = MAX_SIZE;
  maxFileError = false;
  dateHide = false;
  pPickerShow = false;
  //===========>

  shouldCheck = false;
  professions: [any];
  specializations: [any];
  styleMusic: [any];
  styleMusicNames: {};
  isCollapsed = true;
  findAutoCompleteList: Array<any>;
  compositionList: Array<any>;
  genresDic: [any];
  dateEx: FormControl = this.fb.control('', [Validators.required, isFuture]);
  maxDateEx = null;
  dateExDisabled = false;
  time: FormControl = this.fb.control('');
  composerId = this.fb.control(null);

  //===========>
  date: FormControl = this.fb.control('', Validators.required);
  paymentDate: FormControl = this.fb.control(null);
  //===========>

  playground: FormControl = this.fb.control('', Validators.required);
  latitude: FormControl = this.fb.control(0);
  longitude: FormControl = this.fb.control(0);
  placeId: FormControl = this.fb.control('');
  location: FormArray = this.fb.array([this.latitude, this.longitude]);
  profession = this.fb.control(null, Validators.required);
  specialization = this.fb.control(null, Validators.required);
  styleId = this.fb.control(null);
  genreId = this.fb.control(null);
  distance = this.fb.control(200);

  //===========>
  datesArray = [];
  paymentDatesArray = [];
  files = [];
  //===========>

  ageIntervel = AGE_INTERVAL;
  appFileLabels = {
    title: 'ORGANIZER.OFFER.FILES',
    type: 'ORGANIZER.OFFER.FILES_DESC'
  };
  dataName = 'offers_files';
  languagesDic: [any];
  languagesArrayArtist = [];
  languagesArrayCharacter = [];
  languagesNames: {};
  professionsNames: {};
  // model
  experienceList = [];
  currentLang = this.ts.currentLang;
  titleDict = TITLE_DICT;
  other = this.fb.control(null);
  payment = this.fb.control(null, Validators.required);
  composition = this.fb.control('');
  composer = this.fb.control('');
  formOffer: FormGroup = this.fb.group({
    id: this.fb.control(null),
    venue: this.playground,
    location: this.location,

    dates: this.fb.control(null),
    paymentDates: this.fb.control(null),

    composer: this.composer,
    composerId: this.composerId,
    genreId: this.genreId,
    composition: this.composition,
    title: this.fb.control(null),
    role: this.fb.control(null),
    expFood: this.fb.control(0),
    expAccomm: this.fb.control(0),
    expTravel: this.fb.control(0),
    expTaxes: this.fb.control(0),
    expOther: this.fb.control(null),
    payment: this.payment,
    currencyType: this.fb.control('CHF'),
    numberOfTimes: this.fb.control(null),
    profId: this.profession,
    specializationId: this.specialization,
    styleId: this.styleId,
    ageInterval: this.fb.control(null),
    distance: this.distance,
    langCharacter: this.fb.control(null),
    langArtist: this.fb.control(null),
    other: this.other
  });
  searchDict = this.fb.control(null);
  valueFilter: string;
  id: number;
  invite: number;
  isPaymentDirty = false;
  browserLang = navigator.language;
  @ViewChild('searchPlaces', { static: true }) public searchElementRef: ElementRef;
  @ViewChild('file') private fileForm: any;
  @ViewChild('addFiles', { static: true }) addFiles: FilesComponent;

  constructor(public userService: UserService,
    public musicianProfileService: MusicianProfileService,
    public fb: FormBuilder,
    public locationService: LocationService,
    public ds: DictionaryService,
    public cs: CommonService,
    private api: ApiService,
    private modalService: NgbModal,
    private router: Router,
    private ts: TranslateService,
    private route: ActivatedRoute
  ) {
    this.time.setValue(this.fromModelTime());
  }

  ngOnInit() {
    const contrList =
      [this.playground, this.composition, this.composer, this.formOffer];
    this.cs.togleSpiter(false);
    this.userService.getUser$.subscribe((user: User) => {
          if(user && user.status === 2){
            this.organizerBlocked = true;
          }
    });
    this.route
      .queryParamMap.subscribe(
        params => {
          const id = params.get('id');
          const invite = params.get('invite');
          if (!id) {
            this.id = null;
            return;
          }
          this.cs.togleSpiter(true);
          this.id = +id;
          this.invite = +invite;
          this.musicianProfileService.getOffer(this.id, true).subscribe(
            (offer: any) => {
              this.cs.togleSpiter(false);
              this.readonly = !!offer.onlyRead;
              this.languagesArrayCharacter = offer.langCharacter ? offer.langCharacter.split(',') : [];
              this.languagesArrayArtist = offer.langArtist ? offer.langArtist.split(',') : [];

              this.datesArray = offer.dates ? offer.dates.trim().split(',') : [];
              this.datesArray = this.cs.sortDatesArray(this.datesArray);
              this.checkDateEx();

              this.paymentDatesArray = offer.paymentDates ? offer.paymentDates.trim().split(',') : [];
              this.paymentDatesArray = this.cs.sortDatesArray(this.paymentDatesArray);

              this.formOffer.patchValue(offer);
              this.locationService.getGeoCoding(this.playground.value);
              this.fromModel(offer.expiration);
              if (offer.status === 1) {
                this.offerClosed = true;
                this.readonly = true;
              }

              this.musicianProfileService.getData('offers_files', this.id).subscribe(result => {
                this.files = result.slice();
              }, err => {
                if (isDevMode()) {
                  console.log(err);
                }
              });


              contrList.forEach(
                controller => {
                  this.readonly ? controller.disable() : controller.enable();
                }
              );

              this.ds
                .getFilteredDictionary(
                  'dic_specializations',
                  i => Number(i.profId) === Number(offer.profId)).subscribe(
                    dic => {
                      this.specializations = dic;
                    });
            },
            () => {
              // console.log(err);
              this.cs.togleSpiter(false);
              this.router.navigateByUrl('/offer/current');
            });
        }, err => {
          if (isDevMode()) {
            console.log(err);
          }
          this.cs.togleSpiter(false);
        }
      );
    this.locationService.coordinates$.subscribe((value: any) => {
      this.location.patchValue([value.lat, value.lng]);
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
    this.locationService.place$.subscribe((value: any) => {
      if (value === this.playground.value) {
        return;
      }
      this.playground.markAsDirty();
      this.playground.patchValue(value);
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
    this.locationService.getPlaces(this.searchElementRef.nativeElement, false);

    this.date.valueChanges.subscribe(
      (value: { year: number, month: number, day: number}) => {
        if (value) {
          let newDate = this.cs.convertDatePickerToString(value);
          if(this.datesArray.indexOf(newDate) == -1) {
            this.datesArray.push(newDate);
            this.datesArray = this.cs.sortDatesArray(this.datesArray);
            this.checkDateEx();
          }
          this.dateHide = false;
        }
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      }
    );

    this.paymentDate.valueChanges.subscribe(
      (value) => {
        if (value) {
          let newDate = this.cs.convertDatePickerToString(value);;
          if(this.paymentDatesArray.indexOf(newDate) == -1) {
            this.paymentDatesArray.push(newDate);
            this.paymentDatesArray = this.cs.sortDatesArray(this.paymentDatesArray);
          }
          this.pPickerShow = false;
        }
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      }
    );

    this.profession.valueChanges.subscribe(
      value => {
        this.ds
          .getFilteredDictionary('dic_specializations', i => Number(i.profId) === Number(value)).subscribe(
            dic => {
              this.specializations = dic;
              // this.specializationId.reset();
            });
      }, err => {
        if (isDevMode()) {
          console.log(err);
        }
      });
    this.initDictionaries().subscribe(() => {
    }, () => {
    });
    for (let count = 1; count < 90; count += 1) {
      this.experienceList.push(count);
    }
    this.ts.onLangChange.subscribe(langEvent => {
      this.currentLang = langEvent.lang;
      this.initDictionaries().subscribe(
        () => {
        },
        err => {
          if (isDevMode()) {
            console.log(err);
          }
        }, () => {
          this.ds
            .getFilteredDictionary('dic_specializations',
              i => Number(i.profId) === Number(this.profession.value)).subscribe(
                dic => {
                  this.specializations = dic;
                });
        });
    });
    this.searchDict.valueChanges.subscribe(() => {
      this.valueFilter = this.searchDict.value;
    });
    /*
    this.payment.valueChanges.subscribe(value => {
      if (!isNaN(value)) {
        this.mask = value < 1000 ?
          '0*' :
          value < 10000 ?
            '0 000*' :
            value < 100000 ?
              '00 000*' :
              value < 1000000 ?
                '000 000*' :
                value < 10000000 ?
                  '0 000 000*' : '00 000 000*';
      }
      if (!this.isPaymentDirty) {
        this.payment.markAsPristine();
      }
    })
    */
  }

  isDirty() {
    return this.formOffer.dirty || this.time.dirty || this.dateEx.dirty;
  }
  setDirty() {
    this.payment.markAsDirty();
    this.isPaymentDirty = true;
  }
  clearInput() {
    this.playground.reset();
    this.latitude.reset();
    this.longitude.reset();
  }
  changeLocation() {
    this.location.reset();
  }
  checkLocation(flag) {
    this.playgroundError = flag;
  }
  toModel(time: NgbTimeStruct, date: NgbDateStruct): string {
    if (!time || !date) {
      return null;
    }
    return moment.parseZone([date.year, date.month - 1, date.day, time.hour, time.minute, time.second]).utc().format();
  }
  fromModelStr(time: NgbTimeStruct, date: NgbDateStruct) {
    if (!time || !date) {
      return null;
    }
    return moment([date.year, date.month - 1, date.day, time.hour, time.minute, time.second])
      .local().format('ddd, DD.MM.YYYY, H:mm:ss');
  }
  fromModel(dateTime: string) {
    try {
      let time = dateTime ? moment(dateTime) : moment();
      this.dateEx.setValue({
        year: time.year(),
        month: time.month() + 1,
        day: time.date()
      });
      this.time.setValue(
        {
          hour: time.hour(),
          minute: time.minute(),
          second: 0
        }
      );
    } catch (e) {
      if (isDevMode()) {
        console.log(e)
      }
    }
  }

  fromModelTime(): NgbTimeStruct {
    return {
      hour: 23,
      minute: 59,
      second: 0
    };
  }

  private initDictionaries(): Observable<any> {
    return concat(
      this.ds.getDictionary('dic_professions')
        .pipe(
          map(
            dic => {
              this.professions = dic
            }
          )
        ),
      this.ds.getDictionary('dic_styles')
        .pipe(
          map(
            dic => {
              this.styleMusic = dic
            }
          )
        ),
      this.ds.getDictionaryNames('dic_styles')
        .pipe(
          map(
            dic => {
              this.styleMusicNames = dic
            }
          )
        ),
      this.ds.getDictionary('languages')
        .pipe(map(dic => this.languagesDic = dic)),
      this.ds.getDictionaryNames('languages', 'code')
        .pipe(map(names => this.languagesNames = names)),
      this.ds.getDictionaryNames('dic_professions', 'id')
        .pipe(map(names => this.professionsNames = names)),
      this.ds.getDictionary('dic_prof_genres')
        .pipe(map(dic => {
          this.genresDic = dic
        }))
    );
  }

  deleteFromList(index, list): void {
    if (Array.isArray(list)) {
      list.splice(index, 1);
      this.formOffer.markAsDirty();
    }
  }

  setList(value, list): void {
    if (Array.isArray(list)) {
      list.push(value);
      this.formOffer.markAsDirty();
    }
  }

  up() {
    let dis = +this.distance.value;
    this.distance.setValue(dis + 50);
  }

  down() {
    let dis = +this.distance.value;
    this.distance.setValue(dis - 50);
  }

  setId(id) {
    this.composerId.setValue(id);
  }

  setSuggest(value) {
    this.showSpinnerComposer = true;
    this.ds.findComposers(value).subscribe(composers => {
      this.showSpinnerComposer = false;
      this.findAutoCompleteList = composers;
    }, err => {
      this.showSpinnerComposer = false;
      if (isDevMode()) {
        console.log(err);
      }
    });
  }

  setSuggestComposition(value) {
    this.showSpinnerComposition = true;
    this.ds.findCompositions2(this.composer.value, value).subscribe(compositions => {
      this.showSpinnerComposition = false;
      this.compositionList = compositions;
    }, err => {
      this.showSpinnerComposition = false;
      if (isDevMode()) {
        console.log(err);
      }
    });
  }
  goToOffer() {
    this.cs.togleSpiter(true);
    let path;
    if (this.invite) {
      path = [`/invitations/org/list/${this.id}`];
    } else {
      const status = this.formOffer.value.status === 1 ? 'archive' : 'current';
      path = ['/offer', status];
    }
    this.router.navigate(path);
  }





  calendarClick(event) {
    if (this.readonly) {
      return;
    }
    let target = event.target;
    if (target.classList.contains('dates_cont') || event.currentTarget.classList.contains('dates_button')) {
      this.dateHide = !this.dateHide;

      event.stopPropagation();
      return;
    }
    let count = 4;
    while (count > 0) {
      count -= 1;
      if (target.tagName === 'NGB-DATEPICKER-NAVIGATION') {
        this.dateHide = true;
        return;
      }
      target = target.parentElement;
      if (!target) {
        break;
      }
    }
    this.dateHide = false;
  }

  pPikerClick(event) {
    if (this.readonly) {
      return;
    }
    let target = event.target;
    if (target.classList.contains('pay_dates_cont') || event.currentTarget.classList.contains('dates_button')) {
      this.pPickerShow = !this.pPickerShow;

      event.stopPropagation();
      return;
    }
    let count = 4;
    while (count > 0) {
      count -= 1;
      if (target.tagName === 'NGB-DATEPICKER-NAVIGATION') {
        return;
      }
      target = target.parentElement;
      if (!target) {
        break;
      }
    }
    this.pPickerShow = false;
  }

  articleClick(event) {
    this.calendarClick(event);
    this.pPikerClick(event); 

  }

  remove() {
    const profModal = this.modalService.open(ModalComponent, { size: 'lg', centered: true, windowClass: 'modal-show', backdrop: 'static' });
    profModal.componentInstance.message = 'MODAL.DELETE_CONFIRM.CONTENT';
    profModal.componentInstance.save = 'MODAL.DELETE_CONFIRM.DELETE';
    profModal.componentInstance.cancel = 'MODAL.DELETE_CONFIRM.CANCEL';
    profModal.result.then(flag => {
      if(flag){
        this.musicianProfileService.deleteData('offers', this.formOffer.get('id').value).subscribe(
          () => {
            this.router.navigateByUrl('/offer/current');
            this.alert.showMessage('COMMON.SAVED', 'success');
          },
          () => {
            this.alert.showMessage('COMMON.SAVE-ERROR', 'danger');
          }
        );
      }
    }, () => {
    });
  }

  goToSearch() {
    this.router.navigate(['offer/search-mus', this.id, ROLES[0][0]]);
  }

  isDisabled = (date: NgbDateStruct, current: {month: number, year:number, day: number}) => {
    const newDate = this.cs.convertDatePickerToString(date);
    return this.datesArray.find(x => x == newDate) ? true: false;
  }

  isPDisabled = (date: NgbDateStruct, current: {month: number, year:number, day: number}) => {
    const newDate = this.cs.convertDatePickerToString(date);
    return this.paymentDatesArray.find(x => x == newDate) ? true: false;
  }

  dateDelete(index): void {
    if (this.readonly) {
      return;
    }
    this.datesArray.splice(index, 1);
    this.checkDateEx();
    this.formOffer.markAsDirty();
  }

  parseDate(dateTime: string) {
    return this.cs.parseDate(dateTime);
  }

  checkDateEx(){
    console.log(1)
    if(this.cs.getDateFromString(this.datesArray[this.datesArray.length - 1]) < moment()) {
      this.dateExDisabled = true;
      this.dateEx.setValue(null);
    } else {
      this.dateExDisabled = false;
      this.maxDateEx = this.datesArray[this.datesArray.length - 1];
    }
    const date = this.dateEx.value;
    if(date &&  moment([date.year, date.month - 1, date.day]) > this.cs.getDateFromString(this.datesArray[this.datesArray.length - 1])) {
      this.dateEx.setValue(null);
    }
  }

  paymentDateDelete(index): void {
    if (this.readonly) {
      return;
    }
    this.paymentDatesArray.splice(index, 1);
    this.formOffer.markAsDirty();
  }

  searchMusician(event) {
    this.cs.togleSpiter(true);
    event.stopPropagation();

    if (this.isDirty() || !this.firstClicked) {
      this.save().subscribe(() => {
        this.cs.togleSpiter(false);
        this.goToSearch();
      }, err => {
        this.cs.togleSpiter(false);
        if (isDevMode()) {
          console.log(err);
        }
      });
      return;
    }

    this.goToSearch();
  }

  save() {
    this.cs.togleSpiter(true);
    const subj = new Subject();
    const locationRaw = this.location.value;
    this.firstClicked = true;

    if (this.formOffer.invalid || this.dateEx.invalid || this.datesArray.length < 1) {
      this.alert.showMessage('COMMON.REQUIRE_FIELDS', 'danger');
      setTimeout(() => {
        this.cs.togleSpiter(false);
        subj.error({})
      }, 5);
    } else if (!locationRaw || !locationRaw[0]) {
      this.playground.reset();
      this.playgroundError = true;
      this.alert.showMessage('COMMON.REQUIRE_PLACE_FIELD', 'danger');
      setTimeout(() => {
        this.cs.togleSpiter(false);
        subj.error({})
      }, 5);
    } else {
      this.userService.getUser$.pipe(take(1)).subscribe((user: User) => {
        if (user === null) {
          return;
        }
        const dates = Array.isArray(this.datesArray) ? this.datesArray.join(', ') : '';
        const paymentDates = Array.isArray(this.paymentDatesArray) ? this.paymentDatesArray.join(', ') : '';
        const langCharacter = Array.isArray(this.languagesArrayCharacter) ? this.languagesArrayCharacter.join(',') : '';
        const langArtist = Array.isArray(this.languagesArrayArtist) ? this.languagesArrayArtist.join(',') : '';
        const expiration = this.toModel(this.time.value, this.dateEx.value);
        const sendObject = {
          'user': String(user.id),
          ...this.formOffer.value,
          dates,
          paymentDates,
          langCharacter,
          expiration,
          langArtist
        };
        if (this.id === undefined || this.id === null) {
          this.musicianProfileService.createData('offers', sendObject).subscribe(
            offer => {
              this.cs.togleSpiter(false);
              this.id = offer.id;
              this.formOffer.reset({ ...this.formOffer.value, id: offer.id });
               this.addFiles.uploadFiles().subscribe(() => {
                this.alert.showMessage('COMMON.SAVED', 'success');
                subj.next();
              },
              err => {
                this.cs.togleSpiter(false);
                this.alert.showMessage(err.message, 'danger', true);
                subj.error(err);
              });              
            },
            err => {
              this.cs.togleSpiter(false);
              this.alert.showMessage('COMMON.SAVE-ERROR', 'danger');
              subj.error(err);
            }
          );
        } else {
          let sendEnabledFields;
          if (this.readonly) {
            const rawForm = this.formOffer.getRawValue();
            sendEnabledFields = { ...this.formOffer.value, id: rawForm.id };
          } else {
            sendEnabledFields = sendObject;
          }
          this.musicianProfileService.updateData('offers', sendEnabledFields).subscribe(
            () => {
              this.cs.togleSpiter(false);
              this.formOffer.reset(this.formOffer.value);
              this.alert.showMessage('COMMON.SAVED', 'success');
              subj.next();
            },
            err => {
              this.cs.togleSpiter(false);
              this.alert.showMessage('COMMON.SAVE-ERROR', 'danger');
              subj.error(err);
            }
          );
        }
      }, err => {
        subj.error(err);
        if (isDevMode()) {
          console.log(err);
        }
      });
    }

    return subj;
  }

}
