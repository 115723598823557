import {Injectable} from '@angular/core';

@Injectable()
export class StorageService {
  public countriesKey: string = 'Countries';

  constructor() {
  }

  public write(key: string, value: any): void {
    if (value) {
      value = JSON.stringify(value);
    }
    localStorage.setItem(key, value);
  }

  public read<T>(key: string): T {
    let value: string = localStorage.getItem(key);

    if (value && value !== 'undefined' && value !== 'null') {
      return <T>JSON.parse(value);
    }

    return null;
  }

  public clear(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
  public remove (item: string) {
    localStorage.removeItem(item);
  }

  public sessionWrite(key: string, value: any): void {
    if (value) {
      value = JSON.stringify(value);
    }
    sessionStorage.setItem(key, value);
  }

  public sessionRead<T>(key: string): T {
    let value: string = sessionStorage.getItem(key);

    if (value && value !== 'undefined' && value !== 'null') {
      return <T>JSON.parse(value);
    }

    return null;
  }

  public sessionRemove (item: string) {
    sessionStorage.removeItem(item);
  }
}
