<section class="completion-resume">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 (click)="openOffers()"><<< <span class="text_click">{{'SEARCH.CHANGE_PLACE' | translate}}</span></h2>
      </div>
    </div>
  </div>
</section>
<article>
  <div class="container main-container">
    <div class="offer-content pl-5 pr-5 ">
      <div class="row offers-table__head">
        <div class="col-12 col-md-4  text-center">
          {{'PROFILE.PROFESSIONAL.PROFESSION' | translate | uppercase}} / {{'COLLECTIVE.TYPE_COLLECTIVE' | translate |
          uppercase}}
        </div>
        <div class="col-12 col-md-4  text-center">{{'SEARCH.PARAMS' | translate | uppercase}}</div>
        <div class="col-12 col-md-4 text-center">{{'ORGANIZER.OFFER.DATES' | translate | uppercase}}</div>
      </div>
      <div class="row offers-table__head mb-5">
        <div class="col-12 col-md-4  text-center" *ngIf="role === 'Collective'">{{getType(offerInfo?.typeId)}}</div>
        <div class="col-12 col-md-4  text-center" *ngIf="role !== 'Collective'">{{professionsNames[offerInfo?.profId]}}
          /
          {{specializationsNames[offerInfo?.specializationId]}}
        </div>
        <div class="col-12 col-md-4  text-center">{{offerInfo?.venue}}</div>
        <div class="col-12 col-md-4 text-center">{{offerInfo?.dates}}</div>
      </div>
      <div class="row mb-4">
        <p class="title text-left col-10">{{'SEARCH.LIST' | translate}}</p>
        <div class="col-2 text-right">
          <img class="pop-up__modal-close " src="assets/img/icons/modal_close.png" (click)="openOffers()">
        </div>
      </div>
      <article class="offers-table">
        <div class="offers-table__item" *ngFor="let user of users">
          <div class="media row align-items-stretch m-0 p-0" (click)="openDetails(user.id)">
            <hr/>
            <div
              class="m-1 col-md-3  col-12 section-avatar row justify-content-center align-self-stretch align-items-center gray_background"
            style="min-height: 230px; min-width: 200px;">
              <img [src]="user?.avatar" style="width: 100%; height: auto" [hidden]="!user?.avatar"
                   class="img-fluid center-block align-self-center" alt="avatar">
              <img src="assets/icon/png/icon user.png" style="width: 45%; height: auto;" [hidden]="user?.avatar"
                   class="img-fluid center-block align-self-center" alt="avatar">
            </div>
            <div class="media-body col-md-9 mt-md-0 col-12 mt-4  text-center text-md-left" [ngSwitch]="user.userRole">
              <div *ngSwitchCase="'Collective'">
                <div class="mt-1 mb-2 p-0 col-12">
                
                <app-invitation-alert [invitation]="user" [userRole]="userRole"></app-invitation-alert>  

                </div>
                <h5 class="mt-0">{{getType(user.type) | translate}} {{user.name}}</h5>
                <span *ngFor="let styleId of user.styles; let last = last"
                      class="profession-edit__music-style text-lowercase">
                {{stylesNames[styleId]}}
                  <span [hidden]="last">,</span>
              </span>
                <p class="mt-3 mb-3">{{user.lastName}} {{user.firstName}}</p>
                <p class="title" *ngIf="user.venue">{{'PROFILE.LOCATION.PLACE' | translate}}: {{user?.venue | async}}</p>
                <p class="title" *ngIf="user.venues">{{'PROFILE.TOURS.ON_TOUR' | translate}}:{{user?.venues}}</p>
                <p class="title" *ngIf="user.experience">{{'PROFILE.SKILLS.EXPERIENCE' | translate}}:
                  {{user.experience}}</p>
                <p class="title" *ngIf="user.group_voices">{{'COLLECTIVE.COMPOSITION_VOICE' | translate}}:
                  <span *ngFor="let voiceId of user.groupVoicesArray; let last = last" class="text-lowercase">
                    {{groupVoicesNames[voiceId]}}
                    <span [hidden]="last">,</span>
                  </span>
                </p>
                <p class="title"
                   *ngIf="user.group_musical_instruments">{{'COLLECTIVE.COMPOSITION_INSTRUMENT' | translate}}:
                  <span *ngFor="let instId of user.groupMusicalInstrumentsArray; let last = last"
                        class="text-lowercase">
                    {{groupMusicalInstrumentsNames[instId]}}
                    <span [hidden]="last">,</span>
                  </span>
                </p>
              </div>
              <div *ngSwitchDefault>
                <div class="mt-1 mb-2 p-0 col-12">
                  
                  <app-invitation-alert [invitation]="user" [userRole]="userRole"></app-invitation-alert>  
                  
                </div>
                <h5 class="mt-0">{{professionsNames[user.profId]}}, {{specializationsNames[user.specializationId]}}</h5>
                <span *ngFor="let styleId of user.styles; let last = last"
                      class="profession-edit__music-style text-lowercase">
                {{stylesNames[styleId]}}
                  <span [hidden]="last">,</span>
              </span>
                <p class="mt-3 mb-3">{{user.lastName}} {{user.firstName}}</p>
                <p class="title" *ngIf="user.venues">{{'PROFILE.TOURS.ON_TOUR' | translate}}:{{user?.venues}}</p>
                <p class="title">{{'INVITATIONS.AGE' | translate}}: {{user.age}}</p>
                <p class="title" *ngIf="user.venue">{{'PROFILE.LOCATION.PLACE' | translate}}: {{user?.venue | async}}</p>
                <p class="title">{{'PROFILE.SKILLS.EXPERIENCE' | translate}}: {{user.experience}}</p>
              </div>
            </div>
          </div>
          <div class="row  align-items-center m-0 p-0 mt-3" [hidden]="!initialized">
            <div class="col-12 col-md-4 col-lg-3 m-1 p-0">
              <button
                class="btn btn-outline-black-invert w-100"
                *ngIf="+user.state === 0"  
                [disabled]="submitButton"
                (click)="inviteMusician($event, user)">
                  {{'COMMON.INVITE' | translate}}
                  <i [hidden]='!submitButton' class="fas fa-spinner fa-pulse fa-fw"></i>
              </button>
              <button
                class="btn btn-outline-black-invert align-self-end w-100"
                *ngIf="[2, 3].indexOf(+user.state) > -1 && !cs.isTimeOverdue(user?.available, user?.state)"
                [disabled]="submitButton"
                (click)="nextAction(user)">
                  {{'INVITATIONS.BUTTON_STATE.'+user.state | translate}}
                  <i [hidden]='!submitButton' class="fas fa-spinner fa-pulse fa-fw"></i>
              </button>
              <div class="text-center color_title mt-3 font-weight-bold" *ngIf="[0,2,3].indexOf(+user.state) === -1">
                <span>{{'COMMON.INVITED' | translate}}</span>
              </div>
            </div>
            <div class="pane_navigation_btn col-12 col-md-7 col-lg-8">
              <span class="pane_navigation_btn_text" (click)="openDetails(user.id)">
                {{'COMMON.MORE_DETAILS' | translate}} ...
              </span>
            </div>
          </div>
        </div>
        <app-alert #alert></app-alert>
      </article>
    </div>
  </div>
</article>
