import { Component, isDevMode, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchService } from '../search.service';
import { TranslateService } from '@ngx-translate/core';

import { concat, Observable } from 'rxjs';
import * as moment from 'moment';

import { DictionaryService } from 'app/_service/dictionary.service';
import { UserService } from 'app/_service/user.service';
import { AlertComponent } from 'app/user/shared/shared.module';
import { CommonService } from 'app/_service/common.service';
import { ROLES } from 'app/constants';
import { ApiService } from 'app/_service/backend/api.service'
import { MusicianProfileService } from 'app/_service/musician-profile/musician-profile.service';
import { map } from 'rxjs/operators';
import { LocationService } from 'app/user/location/location.service';
import { FileService } from 'app/_service/file.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @ViewChild('alert', { static: true }) private alert: AlertComponent;
  userRole = "Organizer";
  collectiveTypeNames: {};
  users = [];
  offer: any;
  invitations = [];
  offerInfo: any;
  offerId: string;
  role: string;
  submitButton: boolean = false;
  initialized: boolean = false;
  professionsNames: {};
  specializationsNames: {};
  stylesNames: {};
  currentLang = this.ts.currentLang;
  groupMusicalInstrumentsNames = {};
  groupVoicesNames = {};
  constructor(
    public ls: LocationService,
    public cs: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private ts: TranslateService,
    private us: UserService,
    private fs: FileService,
    private ds: DictionaryService,
    private ss: SearchService,
    private api: ApiService,
    private ms: MusicianProfileService) {
  }

  ngOnInit() {
    this.cs.togleSpiter(true);
    this.initDictionaries().subscribe(() => { }, err => console.error(err));
    this.route.params.subscribe(params => {
      this.offerId = params['id'];
      this.role = params['role'];
      this.ss.searchCandidates(this.offerId, this.role);
    });
    this.ss.searchCandidatesResultList$.subscribe((users: any) => {
      if (!users) {
        return;
      }
      this.cs.togleSpiter(false);
      if (users.data.length === 0 && users.dataTours.length === 0) {
        if (isDevMode()) {
          console.log(users.message);
        }
        this.alert.showMessage('SEARCH.SAVE-ERROR', 'warning');
      } else {
        this.offer = users.offer;
        this.users = [...users.data.map(user => this.userMap(user)), ...users.dataTours.map(user => this.userMap(user))];
        this.initialized = true;
      }

    }, err => {

      this.cs.togleSpiter(false);
      if (isDevMode()) {
        console.log(err);
      }
    });
    switch (this.role) {
      case ROLES[0][0]:
        this.ms.getOffer(this.offerId).subscribe(offer => {
          this.offerInfo = offer;
        });
        break;
      default:
        this.ms.getCollOffer(this.offerId).subscribe(offer => {
          this.offerInfo = offer;
        });
    }

    this.ts.onLangChange.subscribe(langEvent => {
      this.currentLang = langEvent.lang;
      this.initDictionaries().subscribe();
    }, err => {
      if (isDevMode()) {
        console.log(err);
      }
    });
  }

  userMap(user) {
    let avatar = (user.avatar ? `${user.avatar}/${user.id}` : '');
    let styles = user.styles ? user.styles.split(',') : [];
    user.state = isNaN(Number(user.state)) ? 0 : user.state;

    let age = 0;
    if (user.yearOfBirth !== null && !isNaN(user.yearOfBirth)) {
      const x = moment();
      const y = moment(+user.yearOfBirth, 'YYYY');
      age = moment.duration(x.diff(y)).years();
    }
    if (typeof (user.group_musical_instruments) === 'string') {
      user.groupMusicalInstrumentsArray = user.group_musical_instruments.length > 0 ? user.group_musical_instruments.split(',') : [];
    }
    if (typeof (user.group_voices) === 'string') {
      user.groupVoicesArray = user.group_voices.length > 0 ? user.group_voices.split(',') : [];
    }
    let venue;
    if (Array.isArray(user.location) && !isNaN(user.location[0]) && !isNaN(user.location[1])) {
     venue = this.ls.getCity({
        lat: user.location[0],
        lng: user.location[1]
      }, true);
    }
    const state = user.state;
    return { ...user, avatar: this.fs.getFileUrl(avatar, null, true), styles, age, venue, state };
  }

  openOffers() {
    switch (this.role) {
      case ROLES[0][0]:
        this.cs.togleSpiter(true);
        this.router.navigate(['offer/my-offer'], { queryParams: { 'id': this.offerId } });
        break;
      case ROLES[2][0]:
        this.cs.togleSpiter(true);
        this.router.navigate(['offer/collective-offer'], { queryParams: { 'id': this.offerId } });
        break;
    }
  }

  getType(type) {
    return this.collectiveTypeNames[type];
  };

  openDetails(id) {
    switch (this.role) {
      case ROLES[0][0]:
        this.router.navigate(['offer/search-details', id, this.offerId, this.role]);
        break;
      case ROLES[2][0]:
        this.router.navigate(['offer/search-details-collective', id, this.offerId, this.role]);
        break;
    }
  }

  inviteMusician(event, user) {
    this.cs.togleSpiter(true);
    event.stopPropagation();
    let onTour = user.venues ? 1 : 0;
    this.submitButton = true;
    switch (this.role) {
      case ROLES[0][0]:
        this.api.post('/api/invite/organizer/sent', { offer: this.offerId, user: user.id, onTour: onTour}, { responseType: 'text' })
          .subscribe(value => {
            this.cs.togleSpiter(false);
            this.submitButton = false;
            user.state = 1;
          },
            err => {
              this.cs.togleSpiter(false);
              this.submitButton = false;
              console.log(err);
            });
        break;
      case ROLES[2][0]:
        this.api.post('/api/invite/organizer/coll/sent', { offer: this.offerId, user: user.id, onTour: onTour}, { responseType: 'text' })
          .subscribe(value => {
            this.cs.togleSpiter(false);
            this.submitButton = false;
            user.state = 1;
          },
            err => {
              this.cs.togleSpiter(false);
              this.submitButton = false;
              console.log(err);
            });
        break;
    }
  }

  private initDictionaries(): Observable<any> {
    return concat(
      this.ds.getDictionaryNames('dic_professions')
        .pipe(
          map(
            dic => {
              this.professionsNames = dic;
            }
          )
        ),
      this.ds.getDictionary('dic_specializations')
        .pipe(
          map(dic => {
            dic.forEach(item => {
              if (item.profId === 2) {
                this.groupMusicalInstrumentsNames[item.id] = item.name;
              } else if (item.profId === 3) {
                this.groupVoicesNames[item.id] = item.name;
              }
            });
          })
        ),
      this.ds.getDictionaryNames('dic_specializations')
        .pipe(
          map(
            dic => {
              this.specializationsNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_styles')
        .pipe(
          map(
            dic => {
              this.stylesNames = dic;
            }
          )
        ),
      this.ds.getDictionaryNames('dic_collective_types')
        .pipe(
          map(
            dic => {
              this.collectiveTypeNames = dic;
            }
          )
        )
    );
  }

  //TODO: Вынести в OfferService
  nextAction(candidat) {
    const state = candidat.state;
    const inviteId = candidat.inviteId;
    const navigateLink = this.role == "Musician" ? 'invitations/contract/mus' : 'invitations/contract/coll';
    switch (state) {
      case 2:
        this.cs.togleSpiter(true);
        const apiLink = this.role == "Musician" ? 'api/invite/organizer/approved' : 'api/invite/organizer/coll/approved';
        this.api.post(apiLink, { inviteId }).subscribe((result: any) => {
          this.cs.togleSpiter(false);
          if (result.success) {
            candidat.state = 3;
            this.router.navigate([navigateLink, inviteId, this.offerId]);
          }
        },
          () => {
            this.cs.togleSpiter(false);
          });
        break;
      case 3:
        this.cs.togleSpiter(true);
        console.log([navigateLink, inviteId, this.offerId]);
        this.router.navigate([navigateLink, inviteId, this.offerId]);
        break;
      default:
        break;
    }
  }

}


